<template>
  <v-container id="user-profile" fluid tag="section">
    <filter-form :filter-form="filterForm" :departments="departments" />
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('user_create')"
        class="primary mx-0"
        @click="addNewUser"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn>
    </div>
    <v-card>
      <v-tabs v-model="tab" grow @change="getUserList()">
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="(item, key) in tabs" :key="key">
          {{ item }}
        </v-tab>

        <v-tab-item v-for="(item, key) in tabs" :key="key">
          <user-table
            v-if="users.length"
            :filter-form="filterForm"
            :users="users"
            :departments="departments"
          />
          <common-no-result v-if="!loading && totalItem === 0" />
          <v-row class="mt-0">
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="6">
              <v-pagination
                v-if="totalItem > limit"
                v-model="currentPage"
                class="justify-sm-end"
                :length="getTotalItem(totalItem)"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <user-form :departments="departments" />
    <reset-password-form />
  </v-container>
</template>

<script>
import ResetPasswordForm from "./components/ResetPasswordForm";
import UserForm from "./components/UserForm";
import TokenService from "../../helpers/token";
import FilterForm from "./components/FilterForm.vue";
import UserTable from "./components/UserTable";
import RepositoryFactory from "../../repositories/RepositoryFactory";

const UserRepository = RepositoryFactory.get("user");
const DepartmentRepository = RepositoryFactory.get("department");

export default {
  components: {
    UserTable,
    FilterForm,
    UserForm,
    ResetPasswordForm,
  },
  data() {
    return {
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      users: [],
      loading: false,
      departments: [],
      tabs: ["Đang hoạt động", "Ngừng hoạt động"],
      tab: 0,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getUserList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getUserList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  created() {
    this.getUserList();
    Bus.$on("user-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getUserList();
    });

    Bus.$on("user-list-reload", () => {
      this.getUserList();
    });
  },
  beforeDestroy() {
    Bus.$off("user-list-filter");
  },
  methods: {
    addNewUser() {
      this.selectedUser = {};
      Bus.$emit("user-show-user-form", this.selectedUser);
    },
    async getUserList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        keyword: this.filterForm.keyword,
        position: this.filterForm.position,
        status: this.tab == 0 ? "active" : "inactive",
        mobile: this.filterForm.mobile,
      };
      const loader = this.$loading.show();
      const response = await UserRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.users = _.get(response, "data", []);
        this.totalItem = _.get(response, "totalItem", 0);
      }
    },
  },
};
</script>
