import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/customer`

export default {
  async list(params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async detail(id) {
    const response = await Repository.get(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async create(user) {
    const response = await Repository.post(`${resource}`, user, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update(id, user) {
    const response = await Repository.put(`${resource}/${id}`, user, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async delete(id) {
    const response = await Repository.delete(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async resetPassword(payload) {
    const response = await Repository.post(`${resource}/reset-password`, payload, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateStatus(id, params) {
    const response = await Repository.patch(`${resource}/${id}/status`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async listCustomerGroup(params) {
    const response = await Repository.get(`${resource}/group`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  
  async listMarketing(params) {
    const response = await Repository.get(`${resource}/by-department-type`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async getDetail(id) {
    const response = await Repository.get(`${resource}/profile/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  }
}
