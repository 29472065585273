<template>
  <div
    class="no-result text-center mt-2"
  >
    <span>Không tìm thấy kết quả</span>
  </div>
</template>

<script>
  export default {
    name: 'NoResult',
  }
</script>
