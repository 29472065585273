import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/role`

export default {
  async list (params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  // async permissionList (params) {
  //   const response = await Repository.get(`${resource}/permissions`, {
  //     params,
  //     headers: TokenService.getHeader(),
  //   })
  //   return response
  // },

  async detail (id) {
    const response = await Repository.get(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update (id, role) {
    const response = await Repository.patch(`${resource}/${id}`, role, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async delete (id) {
    const response = await Repository.delete(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async create (role) {
    const response = await Repository.post(`${resource}`, role, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
