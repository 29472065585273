import DashboardLayout from '../layouts/DashboardLayout'

export const discountMenu = {
    path: '/discounts',
    component: DashboardLayout,
    name: 'Discounts',
    redirect: '/discounts',
    children: [
        {
            path: '/',
            name: 'DiscountList',
            component: () => import(/* webpackChunkName: "about" */'../pages/discount/DiscountList.vue'),
            meta: {
                roles: ['discount_read', 'discount_create', 'discount_update', 'discount_delete', 'discount_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Cài đặt chiết khấu',
                }]
            },
        },
    ],
}
