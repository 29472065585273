import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/appointment`

export default {
    async list (params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async listByUserLogin (params) {
        const response = await Repository.get(`${resource}/user/list`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async create (appointment) {
        const response = await Repository.post(`${resource}`, appointment, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async update (id, appointment) {
        const response = await Repository.patch(`${resource}/${id}`, appointment, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async delete (id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async updateStatus (id, params) {
        const response = await Repository.patch(`${resource}/${id}/status`, params, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    
    async detail (id, params = {}) {
        const response = await Repository.get(`${resource}/${id}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
}
