import DashboardLayout from '../layouts/DashboardLayout'
// import ContactSourceList from '../pages/contact-source/ContactSourceList'
// import ContactStatusList from '../pages/contact-status/ContactStatusList'
import RoleList from '../pages/roles/RoleList'
import RoleForm from '../pages/roles/RoleForm'

// import ContactSetting from '../pages/contact/ContactSetting'
// import Announcement from '../pages/announcement/Announcement'

// import SchoolLevel from '../pages/school-level/SchoolLevel'

export const dataSetting = {
  path: '/data-setting',
  component: DashboardLayout,
  name: 'DataSetting',
  redirect: '/data-setting',
  children: [
    // {
    //   path: 'contact-setting',
    //   name: 'ContactSetting',
    //   component: () => import(/* webpackChunkName: "about" */'../pages/contact/ContactSetting.vue'),
    //   meta: {
    //     roles: ['contact-setting_read','contact-setting_create','contact-setting_update','contact-setting_delete'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Cài đặt khách hàng',
    //     }]
    //   },
    // },
    // {
    //   path: 'contact-source-list',
    //   name: 'Contact Source List',
    //   component: () => import(/* webpackChunkName: "about" */'../pages/contact-source/ContactSourceList.vue'),
    //   meta: {
    //     roles: ['contact-source_read','contact-source_create','contact-source_update','contact-source_delete'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Nguồn khách hàng',
    //     }]
    //   },
    // },
    // {
    //   path: 'contact-status',
    //   name: 'ContactStatusList',
    //   component: () => import(/* webpackChunkName: "about" */'../pages/contact-status/ContactStatusList.vue'),
    //   meta: {
    //     roles: ['contact-status_read','contact-status_create','contact-status_update','contact-status_delete'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Danh sách mối quan hệ',
    //     }]
    //   }
    // },
    {
      path: 'role',
      name: 'RoleList',
      component: () => import(/* webpackChunkName: "about" */'../pages/roles/RoleList.vue'),
      meta: {
        roles: ['role_read', 'role_create', 'role_update', 'role_delete'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách quyền',
        }]
      },
    },
    {
      path: 'create-role',
      name: 'CreateRole',
      component: () => import(/* webpackChunkName: "about" */'../pages/roles/RoleForm.vue'),
      meta: {
        roles: ['role_create'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách quyền',
          to: '/data-setting/role'
        }, {
          title: 'Tạo mới quyền',
        }]
      },
    },
    {
      path: 'role/edit/:id',
      name: 'EditRole',
      component: () => import(/* webpackChunkName: "about" */'../pages/roles/RoleForm.vue'),
      meta: {
        roles: ['role_update'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách quyền',
          to: '/data-setting/role'
        }, {
          title: 'Chỉnh sửa quyền',
        }]
      },
    },
    {
      path: 'icon',
      name: 'IconList',
      component: () => import(/* webpackChunkName: "about" */'../pages/icon-notification/IconList.vue'),
      meta: {
        roles: ['icon_read', 'icon_create', 'icon_update', 'icon_delete', 'icon_view_all'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách icon',
        }]
      },
    },
    {
      path: 'announcement',
      name: 'AnnouncementList',
      component: () => import(/* webpackChunkName: "about" */'../pages/announcement/Announcement.vue'),
      meta: {
        roles: ['announcement_read', 'announcement_create', 'announcement_update', 'announcement_delete', 'announcement_view_all'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách thông báo',
        }]
      },
    },
    {
      path: 'notification',
      name: 'NotificationList',
      component: () => import(/* webpackChunkName: "about" */'../pages/notification/NotificationList.vue'),
      meta: {
        roles: ['notification_read', 'notification_create', 'notification_update', 'notification_delete', 'notification_view_all'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Thông báo nội bộ',
        }]
      },
    },
    {
      path: 'notification-customer',
      name: 'NotificationCustomerList',
      component: () => import(/* webpackChunkName: "about" */'../pages/notification-customer/NotificationList.vue'),
      meta: {
        roles: ['notification_customer_read', 'notification_customer_create', 'notification_customer_update', 'notification_customer_delete', 'notification_customer_view_all'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Thông báo tới khách hàng',
        }]
      },
    },
  ],
}