<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm nhóm khách hàng</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <!--              <v-list-item-title class="headline mb-2">-->
              <!--                Tìm Kiếm nhóm khách hàng-->
              <!--              </v-list-item-title>-->
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="filterForm.keyword"
                    label="Tìm kiếm"
                    placeholder="Tìm kiếm theo tên, mô tả..."
                    clearable
                    @keyup.enter="searchDepartment"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="filterForm.departmentTypes"
                    :items="departmentTypes"
                    item-value="value"
                    item-text="label"
                    label="Tìm kiếm theo loại"
                    placeholder="Chọn theo loại"
                    clearable
                    multiple
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchDepartment">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const DepartmentTypeRepository = RepositoryFactory.get("department_type");

export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      departmentTypes: [],
    };
  },
  async created() {
    this.getDepartmentTypeList()
  },
  methods: {
    async getDepartmentTypeList() {
      const dTypeList = await DepartmentTypeRepository.list();
      this.departmentTypes = _.get(dTypeList, "data.list_department_type", []);
    },

    searchDepartment() {
      Bus.$emit("department-list-filter", this.filterForm);
    },
  },
};
</script>
