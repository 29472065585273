import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/customer-support`

export default {
    async getRequestAction() {
        const response = await Repository.get(`${resource}/request-action`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async create(request) {
        const response = await Repository.post(`${resource}`, request, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async list(params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async delete(id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async updateStatus(id, body) {
        const response = await Repository.post(`${resource}/${id}`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async updateRequest(id, body) {
        const response = await Repository.patch(`${resource}/${id}`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async statusList() {
        const response = await Repository.get(`${resource}/request-result-action`, {
            headers: TokenService.getHeader(),
        })
        return response
    },


    async urgentList() {
        const response = await Repository.get(`${resource}/urgent-request`, {
            headers: TokenService.getHeader(),
        })
        return response
    }
}