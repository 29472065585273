import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/schedule`

export default {
    async list (params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async create (params) {
        const putParams = JSON.parse(JSON.stringify(params))
        if ('id' in putParams) {
            delete putParams.id
        }
        const response = await Repository.post(`${resource}`, putParams, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async update (id, schedule) {
        const response = await Repository.patch(`${resource}/${id}`, schedule, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async updateStatus (id, scheduleStatus) {
        const response = await Repository.patch(`${resource}/${id}/status`, scheduleStatus, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async statistic (params) {
        const response = await Repository.get(`${resource}/statistic/hour`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async delete (id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },
}
