import DashboardLayout from '../layouts/DashboardLayout'

export const bannerMenu = {
    path: '/banners',
    component: DashboardLayout,
    name: 'Banners',
    redirect: '/banners',
    children: [
        {
            path: 'banner-list',
            name: 'BannerList',
            component: () => import(/* webpackChunkName: "about" */'../pages/banner/BannerList.vue'),
            meta: {
                roles: ['banner_read', 'banner_create', 'banner_update', 'banner_delete', 'banner_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Danh sách banner',
                }]
            },
        },
    ],
}
