import Repository from './Repository'
import TokenService from '../helpers/token'
const resource = `${Repository.defaults.baseURL}/contact`
const resourceCustomer = `${Repository.defaults.baseURL}/customer`
export default {
  async detail(contactId) {
    const response = await Repository.get(`${resource}/${contactId}/`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async list(params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async create(body) {
    const response = await Repository.post(`${resource}`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update(body, contactId) {
    const response = await Repository.patch(`${resource}/${contactId}`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateContactStatus(body) {
    const response = await Repository.post(`${resource}/change-status`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateContactUser(body) {
    const response = await Repository.post(`${resource}/change-user`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteContacts(body) {
    const response = await Repository.delete(`${resource}`, {
      data: body,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getContactHistorys(params, contactId) {
    const response = await Repository.get(`${resource}/${contactId}/history`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getContactHistorysTransportNote(params, contactId) {
    const response = await Repository.get(`${resource}/${contactId}/order-care-note`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteContactHistory(contactId) {
    const response = await Repository.delete(`${resource}/history/${contactId}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateContactHistory(contactId, body) {
    const response = await Repository.post(`${resource}/history/${contactId}`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async uploadContact(file, store = 'T') {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await Repository.post(`${resource}/import-excel`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
      params: { store }
    });
    return response;
  },

  async createContactHistory(body, contactId) {
    const response = await Repository.post(`${resource}/${contactId}/history`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async createContactColumn(body) {
    const response = await Repository.post(`${resource}/column`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getContactColumns(params = {}) {
    const response = await Repository.get(`${resource}/column/list`, {
      headers: TokenService.getHeader(),
      params,
    })
    return response
  },

  async updateContactColumn(body, id) {
    const response = await Repository.patch(`${resource}/column/${id}`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteContactColumn(id) {
    const response = await Repository.delete(`${resource}/column/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteContactCustomer(customerId) {
    const response = await Repository.delete(`${resource}/customer/${customerId}`,{
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateProductGroup(body) {
    const response = await Repository.post(`${resource}/add-product-group`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateCustomer(body, customerId) {
    const response = await Repository.patch(`${resourceCustomer}/${customerId}`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async createCustomer(body) {
    const response = await Repository.post(`${resourceCustomer}`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async exportExcelContact(params = {}) {
    const response = Repository.get(`${resource}/export/excel`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async returnToBackStatus(id) {
    const response = await Repository.post(`${resource}/${id}/back-after-support`, {}, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async additionToNumberOfCall(id, store) {
    const response = await Repository.post(`${resource}/${id}/action-call`, {store: store}, {
      headers: TokenService.getHeader(),
    })
    return response 
  },

  async mergeContact(params) {
    const response = await Repository.post(`${resource}/merge`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async unmergeContact(params) {
    const response = await Repository.post(`${resource}/unmerge`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateByOrder(params) {
    const response = await Repository.post(`${resource}/updateByOrder`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getResultStudy(contactId, params) {
    if(+contactId){
      const response = Repository.get(`${resource}/${contactId}/study-result`, {
        params,
        headers: TokenService.getHeader(),
      })
      return response  
    }
    else{
      return {}
    }
  },

  async updateContactUserMarketing(body) {
    const response = await Repository.post(`${resource}/change-user-marketing-name`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateContactSchoolLevel(body) {
    const response = await Repository.post(`${resource}/change-school-level-id`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
