import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/training`

export default {
    async list(params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async detail(id) {
        const response = await Repository.get(`${resource}/file/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async createFile(body) {
        const response = await Repository.post(`${resource}/file`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async updateFile(id, body) {
        const response = await Repository.patch(`${resource}/file/${id}`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async deleteFile(id) {
        const response = await Repository.delete(`${resource}/file/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async orderFile(id, body) {
        const response = await Repository.post(`${resource}/file-order/${id}`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async createFolder(body) {
        const response = await Repository.post(`${resource}/folder`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async updateFolder(id, body) {
        const response = await Repository.patch(`${resource}/folder/${id}`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async deleteFolder(id) {
        const response = await Repository.delete(`${resource}/folder/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async orderFolder(id, body) {
        const response = await Repository.post(`${resource}/folder-order/${id}`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    }
}
