import DashboardLayout from '../layouts/DashboardLayout'

// import LeaderReport from '../pages/report/sale-leader-report/LeaderReport'
// import ResultReport from '../pages/report/department-report/result-report/ResultReport'
// import EmployeeReport from '../pages/report/department-report/employee-report/EmployeeReport'
// import StatusReport from '../pages/report/department-report/status-report/StatusReport'
// import ContactSourceReport from '../pages/report/department-report/contact-source-report/ContactSourceReport'
// import BestEmployeeReport from '../pages/report/best-employees-report/BestEmployee'
// import ProductGroupReport from '../pages/report/revenue-report/product-group-report/ProductGroupReport'
// import CustomerReport from '../pages/report/revenue-report/customer-report/CustomerReport'
// import PersonalReport from '../pages/report/personal-report/PersonalReport'
// import ContactRegisterReport from '../pages/report/contact-register-report/ContactRegisterReport'
// import DeliveryReport from '../pages/report/delivery-report/DeliveryReport'
// import EmployeeReportADepartment from '../pages/report/department-report/employee-report-a-department/EmployeeReportADepartment'
// import GeneralReport from '../pages/report/general-report/GeneralReport'

// import AccountReport from '../pages/reports/account-report/AccountReport'
// import SalesReport from '../pages/reports/sales-report/SalesReport'
// import ProductReport from '../pages/reports/product-report/ProductReport'

export const reportMenu = {
  path: '/reports',
  component: DashboardLayout,
  // name: 'Report',
  redirect: '/reports',
  children: [
    {
      path: 'account-report',
      name: 'AccountReport',
      component: () => import(/* webpackChunkName: "about" */'../pages/reports/account-report/AccountReport.vue'),
      meta: {
        roles: ['account_report_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Báo cáo tài khoản'
        }]
      },
    },
    {
      path: 'sales-report',
      name: 'SalesReport',
      component: () => import(/* webpackChunkName: "about" */'../pages/reports/sales-report/SalesReport.vue'),
      meta: {
        roles: ['sales_report_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Sổ chi tiết bán hàng'
        }]
      },
    },
    {
      path: 'product-report',
      name: 'ProductReport',
      component: () => import(/* webpackChunkName: "about" */'../pages/reports/product-report/ProductReport.vue'),
      meta: {
        roles: ['product_report_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Báo cáo sản phẩm'
        }]
      },
    },
    // {
    //   path: 'leader-report',
    //   name: 'LeaderReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/sale-leader-report/LeaderReport.vue'),
    //   meta: {
    //     roles: ['sale-leader_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo trưởng nhóm'
    //     }]
    //   },
    // },
    // {
    //   path: 'result-report',
    //   name: 'ResultReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/department-report/result-report/ResultReport.vue'),
    //   meta: {
    //     roles: ['result-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo kết quả'
    //     }]
    //   },
    // },
    // {
    //   path: 'employee-report',
    //   name: 'EmployeeReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/department-report/employee-report/EmployeeReport.vue'),
    //   meta: {
    //     roles: ['human-resources-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo nguồn lực'
    //     }]
    //   },
    //   query: { v: '' }
    // },
    // {
    //   path: 'status-report',
    //   name: 'StatusReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/department-report/status-report/StatusReport.vue'),
    //   meta: {
    //     roles: ['status-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo trạng thái'
    //     }]
    //   },
    // },
    // {
    //   path: 'contact-source-report',
    //   name: 'ContactSourceReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/department-report/contact-source-report/ContactSourceReport.vue'),
    //   meta: {
    //     roles: ['contact-source-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo nguồn contact'
    //     }]
    //   },
    // },
    // {
    //   path: 'best-employees',
    //   name: 'BestEmployeeReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/best-employees-report/BestEmployee.vue'),
    //   meta: {
    //     roles: ['best-employee_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Nhân viên xuất sắc'
    //     }]
    //   },
    // },    
    // {
    //   path: 'product-group',
    //   name: 'ProductGroupReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/revenue-report/product-group-report/ProductGroupReport.vue'),
    //   meta: {
    //     roles: ['product-group-revenue-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo doanh thu theo nhóm sản phẩm'
    //     }]
    //   },
    // },
    // {
    //   path: 'customer-report',
    //   name: 'CustomerReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/revenue-report/customer-report/CustomerReport.vue'),
    //   meta: {
    //     roles: ['customer-revenue-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo khách hàng'
    //     }]
    //   },
    // },
    // {
    //   path: 'personal-report',
    //   name: 'PersonalReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/personal-report/PersonalReport.vue'),
    //   meta: {
    //     roles: ['personal-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo thành tích cá nhân'
    //     }]
    //   },
    // },
    // {
    //   path: 'contact-register',
    //   name: 'ContactRegisterReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/contact-register-report/ContactRegisterReport.vue'),
    //   meta: {
    //     roles: ['contact-register_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo đăng ký theo ngày'
    //     }]
    //   }
    // },
    // {
    //   path: 'delivery',
    //   name: 'DeliveryReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/delivery-report/DeliveryReport.vue'),
    //   meta: {
    //     roles: ['delivery-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo giao hàng'
    //     }]
    //   }
    // },
    // {
    //   path: 'employee-report-fe',
    //   name: 'EmployeeReportFe',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/department-report/employee-report-fe/EmployeeReport.vue'),
    //   meta: {
    //     roles: ['employee-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo nhân viên'
    //     }]
    //   },
    // },
    // {
    //   path: 'employee-report-:id',
    //   name: 'EmployeeReportADepartment',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/department-report/employee-report-a-department/EmployeeReportADepartment.vue'),
    //   meta: {
    //     roles: ['employee-report-a-department_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo nhân viên Nhóm khách hàng trực thuộc'
    //     }]
    //   },
    // },
    // {
    //   path: 'general',
    //   name: 'GeneralReport',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/general-report/GeneralReport.vue'),
    //   meta: {
    //     roles: ['general-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo chung'
    //     }]
    //   },
    // },
    // {
    //   path: 'personal-report-fe',
    //   name: 'PersonalReportFe',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/personal-report-fe/PersonalReport.vue'),
    //   meta: {
    //     roles: ['personal-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo thành tích cá nhân fe'
    //     }]
    //   },
    // },
    // {
    //   path: 'employee-report-fe',
    //   name: 'EmployeeReportFe',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/report/department-report/employee-report-fe/EmployeeReport.vue'),
    //   meta: {
    //     roles: ['employee-report_read'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Báo cáo theo nhân viên fe'
    //     }]
    //   },
    // },
  ],
}