<template>
    <div class="mobile-hidden">
        <div v-for="(notification, index) in hideNotifications" :key="index">
            <v-card 
                :class="'s-popup-notification pos-' + notificationPosition.indexOf(notification.id)"
                v-if="notification.showModal"
                :style="{ top: 'calc(73vh - ' + notificationPosition.indexOf(notification.id)*150 + 'px)', width: '350px', backgroundColor: '#FFFACD' }">
                <v-card-title class="font-weight-bold mb-0 d-flex justify-space-between" style="line-height: 1.2;">
                    <div>
                        <v-row>
                            <v-icon style="font-size: 25px; color: #a847bf; padding: 0px 5px">mdi-bell</v-icon>
                            <div>
                                <span>Bạn có lịch hẹn lúc {{getTime(notification.date)}}, Hôm nay</span><br>
                                <span>KH: {{notification.customer.fullName}}</span><br>
                                <span>SĐT: {{notification.mobile}}</span>
                            </div>
                        </v-row>
                    </div>
                    <v-btn
                            color="default"
                            @click="closeModal(index)"
                            class="text-right mb-4"
                            icon
                            small
                    >
                        <v-icon aria-label="Close">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pt-0 pb-3 notification-desc">
                    Ghi chú: {{notification.description}}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        min-width="22px !important"
                        height="22"
                        dark
                        small
                        class="mb-3"
                        size="16"
                        @click="showAppointmentInfo(index)"
                    >
                        Chi tiết lịch hẹn
                    </v-btn>
                    <v-menu offset-y v-if="notification.customer.contact && notification.customer.contact.contactType == 'main'">
                        <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                        class="ml-3 btn-notification mb-3"
                                        min-width="22px !important"
                                        height="22"
                                        dark
                                        small
                                        v-bind="attrs"
                                        v-on="{...tooltip, ...menu}"
                                    >
                                        <v-icon class="icon-flipped" size="16">mdi-phone</v-icon>
                                    </v-btn>
                                </template>
                                <span>Thực hiện lịch hẹn</span>
                            </v-tooltip>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="showAppoimentAction(index, _.get(notification, 'mobile', ''))"
                                class="main-mobile"
                            >
                                <v-list-item-title>{{_.get(notification, 'mobile', '')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-for="(phone, phoneIndex) in notification.customer.contact.phoneRelated"
                                :key="phoneIndex"
                                @click="showAppoimentAction(index, phone)"
                                class="sub-mobile"
                            >
                                <v-list-item-title>{{phone}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on }">
                             <v-btn
                                min-width="22px !important"
                                height="22"
                                dark
                                small
                                class="ml-3 btn-notification mb-3"
                                v-on="{ on }"
                                @click="showAppoimentAction(index, _.get(notification, 'mobile', ''))"
                            >
                                <v-icon class="icon-flipped" size="16">mdi-phone</v-icon>
                            </v-btn>
                        </template>
                        <span>Thực hiện lịch hẹn</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment'
export default {
    props: {
        notificationPosition: {
            type: Array,
            default: () => {[]} 
        },
        hideNotifications: {
            type: Array,
            default: () => {[]} 
        },
    },
    created() {
        window.addEventListener('scroll', this.scrollModalNotification);
    },
    methods: {
        closeModal(index) {
            this.$emit('closeNotification', index)
        },
        getTime(date) {
            return moment(date).format('H:mm:ss')
        },
        showAppointmentInfo(index) {
            let contact = this.hideNotifications[index].customer.contact
            const appointmentDetail = {
                id: this.hideNotifications[index].id,
                mainId: contact.mainId,
                mainMobile: contact.phoneRelated ? contact.phoneRelated[0] : '',
                customer: {
                    contactId: contact.id
                }
            };
            this.closeModal(index)
            Bus.$emit('appointment-show-form-from-appointment', appointmentDetail)
        },
        showAppoimentAction(index, phone) {
            let contact = this.hideNotifications[index].customer.contact
            const appointmentDetail = {
                id: this.hideNotifications[index].id,
                mainId: contact.mainId,
                mainMobile: contact.phoneRelated ? contact.phoneRelated[0] : '',
                customer: {
                    contactId: contact.id
                },
                mobile: phone
            };
            this.closeModal(index)
            Bus.$emit('appointment-show-form-from-action', appointmentDetail)
        },
        scrollModalNotification(event) {
            let popups = document.getElementsByClassName('s-popup-notification')
            let distance = window.scrollY + 'px';
            if (popups.length > 0) {
                popups.forEach(element => {
                    element.style.top = 'calc(75vh + ' + distance + ' - ' + parseInt(element.className.substr(-1))*130 + 'px)'
                })
            }
        },
    }
}
</script>

<style scoped>
    .v-card__actions {
        text-align: center;
        padding: 0px !important;
    }

    .notification-desc {
        font-size: 13px; 
        padding: 0px 37px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>