import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/contact-source`

export default {
  async list (params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async detail (id) {
    const response = await Repository.get(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async create (id) {
    const response = await Repository.post(`${resource}`, id, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update (id, body) {
    const response = await Repository.patch(`${resource}/${id}`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async delete (id) {
    const response = await Repository.delete(`${resource}/${id}`, id, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
