import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/site`

export default {
    async list() {
        const response = await Repository.get(`${resource}`, {
            headers: TokenService.getHeader(),
        })
        return response
    }
}