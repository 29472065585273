import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/stores`

export default {
    async create(request) {
        const response = await Repository.post(`${resource}`, request, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async list(params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async deleteOneItem(id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async deleteManyItems (ids) {
        const response = await Repository.delete(`${resource}/`, {
            headers: TokenService.getHeader(),
            data: {
                ids,
            },
        })
        return response
    },

    async update(id, body) {
        const response = await Repository.patch(`${resource}/${id}`, body, {
            headers: TokenService.getHeader(),
        })
        return response
    }
}