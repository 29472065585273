<template>
    <div>
        <div v-for="(item, keyItem) in listSession_call" :key="keyItem">
            <div
                    v-if="item.showModal && position_modal.indexOf(keyItem) > -1"
                    :class="'s-popup-incoming pos-' + position_modal.indexOf(keyItem)"
                    :style="{ top: 'calc(81vh - ' + (position_modal.indexOf(keyItem) + isCallOut)*120 + 'px)', 
                            width: '360px', 
                            right: '20px'}"
                    
            >
                <v-card class="text-center popup-call">
                    <v-card-title class="font-weight-bold mb-0 d-flex justify-space-between">
                        <h3 style="font-size: 14px">Cuộc gọi  <span v-show="item.showTimer">{{ minutes }} : {{ seconds }}</span></h3>
                        <v-btn
                                v-if="!item.showEndCall"
                                color="default"
                                @click="closeModal(keyItem)"
                                class="text-right"
                                icon
                                small
                        >
                            <v-icon aria-label="Close">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <template v-if="item.errorMessage">
                        <v-alert
                                class="mx-4 pa-3 mb-0"
                                type="error"
                        >
                        {{ item.errorMessage }}
                        </v-alert>
                    </template>
                    <v-card-text class="px-6 pt-0 pb-3">
                        <div class="row align-center">
                            <div class="col-md-9 text-left py-0 pr-0">
                                <div class="d-flex">
                                    <label class="font-weight-bold"> Số điện thoại :</label> <strong class="pl-1" :id="'contact-mobile-' + keyItem">{{ item.mobile }}</strong>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                size="16"
                                                width="20"
                                                height="20"
                                                class="mr-1"
                                                icon
                                                small
                                                color="success"
                                                v-on="on"
                                                @click="copyContactMobile(keyItem)"
                                            >
                                                <v-icon size="16">mdi-content-copy</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Copy SĐT</span>
                                    </v-tooltip>
                                </div>
                                <div class="d-flex">
                                    <label class="font-weight-bold" style="min-width: 75px">Trạng thái :</label>
                                    <span>{{ item.callStatus }}</span>
                                </div>
                            </div>
                            <div class="col-md-3 py-0 pr-0">
                                <!-- <v-btn class="btn-voice-call ma-0" color="success" fab medium @click="executeCall" v-if="callAgain">
                                    Gọi
                                </v-btn> -->
                                <v-btn class="btn-voice-call ma-0" color="primary" fab medium @click="endSession(keyItem)" v-if="item.callWait">
                                    Kết thúc
                                </v-btn>
                                <v-btn class="btn-voice-call ma-0" color="primary" fab medium @click="endCall(keyItem)" v-if="item.showEndCall">
                                    Kết thúc
                                </v-btn>
                                <v-btn class="btn-voice-call ma-0" color="success" fab medium @click="acceptCall(keyItem)" v-if="item.confirmAnswer">
                                    Trả lời
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>

                    <v-dialog
                        v-if="item.showConfirm"
                        v-model="item.showConfirm"
                        persistent
                        max-width="525"
                    >
                        <common-confirm-call
                            :showCreateAppointment="showCreateAppointment"
                            :mobile="item.mobile"
                            :dataAppointment="dataAppointment"
                        />
                    </v-dialog>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    // import JsSIP from '../../assets/js/jssip-3.3.11.min.js'
    import JsSIP from 'jssip'
    import TokenService from "../../helpers/token";
    import RepositoryFactory from '../../repositories/RepositoryFactory'
    const ContactRepository = RepositoryFactory.get('contact')
    const CallRepository = RepositoryFactory.get('call_center')
    import axios from 'axios'

    export default {
        name: 'VoiceCall',
        data() {
            return {
                showModal: false,
                callError: false,
                session: false,
                configuration: {},
                callPhone: null,
                callStatus: null,
                showEndCall: false,
                callAgain: false,
                isSetting: false,
                callSuccessEnd: false,
                callWait: false,
                errorMessage: '',
                showConfirm: false,
                mobile: '',
                dataAppointment: {},
                appointmentId: null,
                showCreateAppointment: true,
                timer: '',
                minutes: '00',
                seconds: '00',
                showTimer: false,
                contactId: '',
                callStatusCareSoft: '',
                statusChange: '',
                callcenter_id: 0,
                session_call: false,
                confirmAnswer: false,
                isInCall: false,
                listSession_call: [],
                position_modal: []
            }
        },
        computed: {
            isCallOut() {
                return this.$store.state.isCallOut
            }
        },
        watch: {
            
        },
        async created() {
            let user = TokenService.getUser()
            if (_.get(TokenService.getUser(), 'ext', '') || _.get(TokenService.getUser(), 'extPassword', '')) {
                if (user.callcenter_id == 1) {
                    this.connectePacific()
                }
            } else {
                this.errorMessage = 'Cuộc gọi không thành công do thiếu số máy lẻ và mật khẩu máy lẻ !'
                return
            }
            window.addEventListener('scroll', this.scrollModalCall);
            window.addEventListener('beforeunload', this.endCall)
        },
        beforeDestroy () {
            this.endCall()
            Bus.$off('close-popup-confirm')
            window.removeEventListener('scroll', this.scrollModalCall);
        },

        methods: {
            scrollModalCall(event) {
                let defaultPopups = document.getElementsByClassName('s-popup-incoming')
                let distance = window.scrollY + 'px'
                if (defaultPopups.length > 0) {
                    defaultPopups.forEach(element => {
                        element.style.top = 'calc(81vh + ' + distance + ' - ' + (element.className.substr(-1) + this.isCallOut)*120 + 'px)'
                    })
                }
            },
            initData() {
                this.callError = false,
                this.callAgain = false
            },
            closeConfirm() {
                this.showConfirm = false
            },
            closeModal (index) {
                // this.showPopupCall = false
                try {
                    console.log('pause sound');
                    let sound = document.getElementById('incomingCallAudioIncoming')
                    if (sound) {
                        sound.pause()
                        sound.remove()
                    }
                    this.listSession_call[index].showTimer = false
                    let key = this.position_modal.indexOf(index)
                    this.position_modal.splice(key, 1)
                    this.minutes = '00'
                    this.seconds = '00'
                    this.endSession(index)
                }
                catch(e) {
                    console.log(e)
                }
                finally {
                    this.listSession_call[index].showModal = false;
                    this.listSession_call[index].mobile = '';
                    // if (this.callcenter_id == 1) {
                    //     this.incomingCall()
                    // }
                }
            },
            endSession(index) {
                let user = TokenService.getUser()
                if (user.ext == '') {
                    return
                }
                if (!user.callcenter_id || user.callcenter_id == 1) {
                    if (this.listSession_call[index].session) {
                        this.listSession_call[index].session.terminate()
                        this.listSession_call[index].session = false
                    }
                } else {
                    endCall(index)
                }
            },
            endCall(index) {
                this.listSession_call[index].callAgain = true
                this.listSession_call[index].showEndCall = false
                this.endSession(index)
            },
            
            acceptCall(key) {
                if (this.$store.state.isInCall) {
                    return
                }
                if (!this.callcenter_id || this.callcenter_id == 1) {
                    let sound = document.getElementById('incomingCallAudio')
                    if (sound) {
                        sound.pause()
                        sound.remove()
                    }
                    this.listSession_call[key].confirmAnswer = false
                    this.listSession_call[key].session.answer()
                } else {
                    this.listSession_call[key].confirmAnswer = false
                    onAcceptCall()
                }
            },
            createTimer() {
                let min = 0
                let sec = 0
                let self = this
                self.timer = setInterval(function() {
                    sec++
                    if (sec < 10) {
                        self.seconds = '0' + sec
                    } else if (sec == 60) {
                        sec = 0
                        self.seconds = '00'
                        self.minutes = ++min
                    } else {
                        self.seconds = sec
                    }
                }, 1000)
            },
            connectePacific() {
                this.socket = new JsSIP.WebSocketInterface(process.env.VUE_APP_CALL_VOICE_URL)
                this.socket.via_transport = "tcp";
                var remoteAudio = new window.Audio();
                remoteAudio.autoplay = true;
                this.configuration = {
                    sockets: [this.socket],
                    uri: `${_.get(TokenService.getUser(), 'ext', '')}@vuihochn155.ccall.vn`,
                    password: _.get(TokenService.getUser(), 'extPassword', ''),
                    session_timers: false,
                    // session_timers_refresh_method: 'invite',
                    register_expires: 600,
                    log: {
                        builtinEnabled: false,
                        level: 3,
                        connector: function (level, category, label, content) {
                            console.log('Log: ', content);
                        }
                    },
                    socket: {
                        uri           : process.env.VUE_APP_CALL_VOICE_URL,
                        via_transport : 'auto',
                    },
                    use_preloaded_route : false,
                    pcConfig: {
                        rtcpMuxPolicy : 'negotiate',
                        iceServers    :
                        [
                            { urls : [ ] }
                        ]
                    }
                };
                this.ua = new JsSIP.UA(this.configuration)
                let self = this
                this.ua.on('connected', function(e){ 
                    console.log('connected', e);
                });
                this.ua.on('disconnected', function(e){
                    console.log('disconnected', e);
                });
                this.ua.on('registrationFailed', function(ev){
                    alert('Registering on SIP server failed with error: ' + ev.cause);
                    configuration.uri = null;
                    configuration.password = null;
                    // updateUI();
                });
                this.ua.on('newRTCSession',async function(ev){
                    console.log(ev)
                    var newSession = ev.session;
                    // console.log(self.isInCall)
                    // if (self.isInCall) {
                    //     newSession.terminate()
                    //     return
                    // } else {
                    //     self.isInCall = true
                    // }
                    // self.session_call = newSession; 

                    if (self.listSession_call.length > 0) {
                        if (self.listSession_call.find(item => item.isFinish == false && item.mobile == newSession._remote_identity._uri.user)) {
                            return
                        } 
                    }

                    let index = self.listSession_call.push({
                        session: newSession,
                        callStatus: '',
                        callAgain: false,
                        callWait: false,
                        showEndCall: false,
                        mobile: newSession._remote_identity._uri.user,
                        showModal: false,
                        showTimer: false,
                        confirmAnswer: false,
                        callError: false,
                        callSuccessEnd: false,
                        errorMessage: '',
                        showConfirm: false,
                        isFinish: false}) - 1
        
                    // var completeSession = function(){
                    //     console.log('completeSession');
                    //     session = null;
                    //     incomingCallAudio.pause();
                    //     // updateUI();
                    // };
                    if(newSession.direction === 'incoming') {
                        console.log('stream incoming  -------->');               
                        newSession.on('connecting', function() {
                            console.log('CONNECT'); 
                        });
                        newSession.on('peerconnection', function(e) {
                            console.log('1accepted');
                            // add_stream();        
                            newSession.connection.addEventListener('addstream',function(e) {
                                remoteAudio.srcObject = (e.stream);
                            })    
                        });
                        newSession.on('ended', function(e) {
                            clearInterval(self.timer)
                            console.log('Kết thúc cuộc gọi');
                            let sound = document.getElementById('incomingCallAudio')
                            if (sound) {
                                sound.pause()
                                sound.remove()
                            }
                            self.listSession_call[index].callStatus = "Kết thúc cuộc gọi (" + _.get(e, 'cause') + ')'
                            self.listSession_call[index].callAgain = false
                            self.listSession_call[index].showEndCall = false
                            self.listSession_call[index].callWait = false
                            self.listSession_call[index].isFinish = true
                            self.$store.dispatch('SET_IN_CALL', false)
                            setTimeout(() => {
                                self.endCall(index)
                                // self.showTimer = false
                                // self.minutes = '00'
                                // self.seconds = '00'
                                // self.showModal = false
                                // self.incomingCall()
                            }, 3000)
                        });
                        newSession.on('failed', function (e) {
                            let sound = document.getElementById('incomingCallAudio')
                            if (sound) {
                                sound.pause()
                                sound.remove()
                            }
                            console.log('call failed with cause: ', e)
                            self.listSession_call[index].callStatus = "Cuộc gọi lỗi do " + _.get(e, 'cause')
                            if(_.get(e, 'message.status_code', 0) == 403) { self.callStatus = 'Lỗi xác thực (số máy lẻ và mật khẩu không đúng)'}
                            self.listSession_call[index].callAgain = true
                            self.listSession_call[index].showEndCall = false
                            self.listSession_call[index].callWait = false
                            self.listSession_call[index].callError = false
                            self.listSession_call[index].confirmAnswer = false
                            self.listSession_call[index].isFinish = true
                            setTimeout(() => {
                                self.endCall(index)
                            }, 3000)
                        });
                        newSession.on('accepted',function(e) {
                            console.log('accepted')
                        });
                        newSession.on('confirmed',function(e){
                            self.createTimer()
                            console.log('Bắt đầu cuộc gọi...');
                            self.$store.dispatch('SET_IN_CALL', true)
                            self.listSession_call[index].showTimer = true
                            self.listSession_call[index].callStatus = 'Đang bắt đầu cuộc gọi'
                            self.listSession_call[index].showEndCall = true
                            self.listSession_call[index].callSuccessEnd = true
                            self.listSession_call[index].callWait = false
                            self.listSession_call[index].isFinish = true
                        });
                        // session.answer(options);
                        // incomingCallAudio.pause();
                        // remoteAudio.src = window.URL.createObjectURL(e.stream);
                        let sound = document.getElementById('incomingCallAudio')
                        if (!sound && self.$store.state.isCallOut == 0) {
                            sound          = document.createElement('audio');
                            sound.id       = 'incomingCallAudio';
                            sound.controls = 'controls';
                            sound.loop     = 'loop' 
                            sound.autoplay = 'autoplay'
                            sound.src      = 'https://freesound.org/data/previews/345/345737_191884-lq.mp3';
                            sound.type     = 'audio/mpeg';
                            sound.style    = 'display: none'
                            sound.volume   = 0.5
                            document.getElementById('app-bar').appendChild(sound)
                        }
                        self.listSession_call[index].showEndCall = false
                        self.listSession_call[index].callAgain = false
                        self.listSession_call[index].callWait = false
                        self.listSession_call[index].callStatus = 'Đang gọi đến...'
                        self.listSession_call[index].mobile = newSession._remote_identity._uri.user,
                        self.listSession_call[index].showModal = true
                        self.listSession_call[index].confirmAnswer = true
                        self.position_modal.push(index)
                    }
                    self.listSession_call[index].session = newSession
                    // updateUI();
                });
                this.ua.start()
                this.$store.dispatch('SET_UA', this.ua)
            },
            copyContactMobile(index) {
                let copyText = document.getElementById("contact-mobile-" + index)
                let textArea = document.createElement("textarea")
                textArea.value = copyText.textContent
                document.body.appendChild(textArea)
                textArea.select()
                document.execCommand("Copy")
                textArea.remove()
            }
        }
    }
</script>
<style lang="scss">
  .btn-callcenter {
    float: right;
    margin: 0 !important;
    position: relative;
    top: -6px;
  }
  .float-right {
    float: right;
  }
  .v-card__actions {
    display: block;
    padding-bottom: 20px;
    overflow: hidden;
  }
  .v-card {
    overflow: hidden;
  }
  .popup-call {
    border: 1px solid #A9A9A9 !important;
  }
</style>
