import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/setting`

export default {
  async detail () {
    const response = await Repository.get(`${resource}/auto-assign-contact`, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async update (params) {
    const response = await Repository.post(`${resource}/auto-assign-contact`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async list(params) {
    const response = await Repository.get(`${resource}/user-list`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async updateUser(id, user) {
    const response = await Repository.patch(`${resource}/user-update/${id}`, user, {
      headers: TokenService.getHeader(),
    })
    return response
  }
}
