import Vue from 'vue'
import moment from 'moment'
/* eslint-disable */
import TokenService from '../helpers/token';
import { userPositions } from '../helpers/constants';
Vue.mixin({
  methods: {
    formatDate(date) {
      if (date) {
        return moment(date)
          .format('YYYY-MM-DD');
      }
      return '';
    },
    formatHour(date) {
      if (date) {
        return moment(date)
          .format('HH:mm');
      }
      return '';
    },
    formatPrice(value) {
      const valueABS = Math.abs(value);
      if (isNaN(valueABS))
        return value;
      if (valueABS > -1000 && valueABS < 1000) {
        return value;
      }
      const valueNumber = Intl.NumberFormat('en-US').format(valueABS);
      if (Number(value) < 0) {
        return `-${valueNumber}`;
      }
      return valueNumber;
    },
    scrollToError(className = 'v-messages__message', container = 'body') {
      setTimeout(() => {
        const errorElement = document.getElementsByClassName(className);
        if (errorElement.length > 0) {
          // errorElement[0].scrollIntoView(false)
          this.$scrollTo(errorElement[0], {
            // container,
            duration: 500,
            easing: 'ease-in',
            offset: -150,
            force: true,
            cancelable: false,
            // x: true,
            y: true,
          });
        }
        if (container != 'body') {
          const errorElementContainer = document.getElementsByClassName(container);
          if (errorElementContainer.length > 0 && errorElement.length > 0) {
            let me = this
            this.$scrollTo(errorElement[0], {
              // container,
              duration: 500,
              easing: 'ease-in',
              offset: -150,
              force: true,
              cancelable: false,
              // x: true,
              y: true,
              onDone: function() {
                me.$scrollTo(errorElement[0], {
                  container: `.${container}`,
                  duration: 10,
                  easing: 'linear',
                  offset: 0,
                  force: true,
                  cancelable: false,
                  x: true,
                  y: false,
                })
              }
            });
          }
        }
      }, 100);
    },
    getTotalItem(totalItem, limit = 10) {
      return Math.floor((totalItem - 1) / limit) + 1
    },
    isStaff() {
      return TokenService.getPosition() === userPositions.TELESALES;
    },
    isAdmin() {
      return TokenService.getPosition() === userPositions.SALE_MANAGER;
    },
    isLeader() {
      return TokenService.getPosition() === userPositions.SALE_LEADER;
    }
  },
});
