import DashboardLayout from '../layouts/DashboardLayout'

export const orderHistoryMenu = {
    path: '/orders',
    component: DashboardLayout,
    name: 'OrderHistory',
    redirect: '/orders',
    children: [
        {
            path: 'order-history',
            name: 'OrderHistoryList',
            component: () => import(/* webpackChunkName: "about" */'../pages/order-history/OrderHistoryList.vue'),
            meta: {
                roles: ['order_history_read', 'order_history_create', 'order_history_update', 'order_history_delete', 'order_history_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Lịch sử duyệt đơn',
                }]
            },
        },
    ],
}
