<template>
  <v-container
    id="user-role"
    fluid
    tag="section"
  >

  <filter-form 
    :filter-form="filterForm" 
  />

  <role-table
    :roles="roles"
    :filter-form="filterForm"
  />

  <common-no-result
    v-if="!loading && totalItem === 0"
  />

  <v-row class="mt-4">
    <v-col
      cols="12"
      sm="6"
    />
    <v-col
      cols="12"
      sm="6"
    >
      <v-pagination
        v-if="totalItem > limit"
        v-model="currentPage"
        class="justify-sm-end"
        :length="(totalItem -1)/limit + 1"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      />
    </v-col>
  </v-row>
   
  </v-container>
</template>

<script>
  import FilterForm from './components/FilterForm'
  import RoleTable from './components/RoleTable'
  import RepositoryFactory from '../../repositories/RepositoryFactory'
  const RoleRepository = RepositoryFactory.get('role')
  
  export default {
    components: { 
      FilterForm,
      RoleTable 
    },
    data() {
      return {
        filterForm: {
          warehouse: 'sale'
        },
        currentPage: 1,
        limit: 10,
        totalItem: 0,
        roles: [],
        loading: false,
      }
    },
    watch: {
      currentPage: {
        handler () {
          this.getRoleList()
        },
      },
      limit: {
        handler () {
          if (this.currentPage === 1) {
            this.getRoleList()
          } else {
            this.currentPage = 1
          }
        },
      },
    },
    created() {
      this.getRoleList()
      Bus.$on('role-list-filter', (filterForm) => {
        this.filterForm = filterForm
        this.currentPage = 1
        this.getRoleList()
      })
      Bus.$on('role-list-reload', () => {
        this.getRoleList()
      })
    },
    beforeDestroy () {
      Bus.$off('role-list-filter')
    },
    methods: {
      async getRoleList () {
        this.loading = true
        const params = {
          limit: this.limit,
          page: this.currentPage,
          keyword: this.filterForm.keyword,
        }
        const loader = this.$loading.show()
        const response = await RoleRepository.list(params)
        loader.hide()
        this.loading = false
        if (response.success) {
          this.roles = _.get(response, 'data', []);
          this.totalItem = _.get(response, "totalItem", 0);
        }
      }
    }
  }
</script>
