// import Dashboard from '../pages/dashboard/Dashboard'
import DashboardLayout from "@/layouts/DashboardLayout";

export const dashboardMenu = {
  path: '/dashboard',
  component: DashboardLayout,
  name: 'Dashboard',
  children: [
    {
      path: '/dashboard',
      component: () => import (/* webpackChunkName: "about" */'../pages/dashboard/Dashboard.vue'),
      meta: {
        roles: ['dashboard_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Dashboard'
        }]
      },
    }
  ],
}