import AddressRepository from './AddressRepository'
import AuthRepository from './AuthRepository'
import UserRepository from './UserRepository'
import RoleRepository from './RoleRepository'
import DepartmentRepository from './DepartmentRepository'
import DepartmentRepositoryType from './DepartmentRepositoryType'
import ProductRepository from './ProductRepository'
import ProductGroupRepository from './ProductGroupRepository'
import ProductFolderRepository from './ProductFolderRepository'
import TreatmentRepository from './TreatmentRepository'
import UtilityRepository from './UtilityRepository'
import UnitRepository from './UnitRepository'
import ImageRepository from './ImageRepository'
import ScheduleRepository from './ScheduleRepository'
import ContactRepository from './ContactRepository'
import ContactSourceRepository from './ContactSourceRepository'
import ContactStatusRepository from './ContactStatusRepository'
import ComboRepository from './ComboRepository'
import KpiRepository from './KpiRepository'
import NotificationRepository from './NotificationRepository'
import AppointmentRespository from './AppointmentRespository'
import CallCenterRespository from './CallCenterRespository'
import OrderRepository from './OrderRepository'
import SettingRepository from './SettingRepository'
import ReportRepository from './ReportRepository'
import SupportRepository from './SupportRepository'
import SmsRepository from "@/repositories/SmsRepository";
import CustomerFileRepository from "@/repositories/CustomerFileRepository";
import SiteRepository from './SiteRepository'
import AnnouncementRepository from './AnnouncementRepository'
import SchoolLevelRepository from './SchoolLevelRepository'
import StoreRepository from "@/repositories/StoreRepository"
import TrainingRepository from './trainingRepository'
import CoreActionsLog from './CoreActionLogRepository'
import TagRepository from './TagRepository'
import CustomerRepository from './CustomerRepository'
import BannerRepository from './BannerRepository'
import ManufactureRepository from './ManufactureRepository'
import DiscountRepository from './DiscountRepository'
import ContactWorkRepository from './ContactWorkRepository'
import ShippingMethodRepository from './ShippingMethodRepository'
import PaymentInformationRepository from './PaymentInformationRepository'
import RegularInformationRepository from './RegularInformationRepository'
import PrivacyPolicyRepository from './PrivacyPolicyRepository'
import AboutUsRepository from './AboutUsRepository'
import QuestionAnswerRepository from './QuestionAnswerRepository'
import SiteConfigRepository from './SiteConfigRepository'
import PartnerRepository from './PartnerRepository'
import EvaluationRepository from './EvaluationRepository'
import PromotionsRepository from './PromotionsRepository'
import IconRepository from './IconRepository'
import OrderHistoryRepository from './OrderHistoryRepository'
import PathologyRepository from './PathologyRepository'
import BehaviorRepository from './BehaviorRepository'


const repositories = {
  address: AddressRepository,
  auth: AuthRepository,
  user: UserRepository,
  customer: CustomerRepository,
  role: RoleRepository,
  department: DepartmentRepository,
  department_type: DepartmentRepositoryType,
  product: ProductRepository,
  product_group: ProductGroupRepository,
  product_folder: ProductFolderRepository,
  treatment: TreatmentRepository,
  utility: UtilityRepository,
  unit: UnitRepository,
  tag: TagRepository,
  image: ImageRepository,
  schedule: ScheduleRepository,
  contact: ContactRepository,
  contact_source: ContactSourceRepository,
  contact_status: ContactStatusRepository,
  combo: ComboRepository,
  kpi: KpiRepository,
  notification: NotificationRepository,
  appointment: AppointmentRespository,
  call_center: CallCenterRespository,
  order: OrderRepository,
  setting: SettingRepository,
  report: ReportRepository,
  sms: SmsRepository,
  customer_file: CustomerFileRepository,
  support: SupportRepository,
  site: SiteRepository,
  announcement: AnnouncementRepository,
  school_level: SchoolLevelRepository,
  core_actions_log: CoreActionsLog,
  store: StoreRepository,
  training: TrainingRepository,
  banner: BannerRepository,
  manufacture: ManufactureRepository,
  discount: DiscountRepository,
  contact_work: ContactWorkRepository,
  shipping_method: ShippingMethodRepository,
  payment_information: PaymentInformationRepository,
  regular: RegularInformationRepository,
  privacy_policy: PrivacyPolicyRepository,
  about_us: AboutUsRepository,
  question_answer: QuestionAnswerRepository,
  site_config: SiteConfigRepository,
  partner: PartnerRepository,
  evaluation: EvaluationRepository,
  promotions: PromotionsRepository,
  icon: IconRepository,
  order_history: OrderHistoryRepository,
  pathology: PathologyRepository,
  behavior: BehaviorRepository,
}

export default {
  get: (name) => repositories[name],
}
