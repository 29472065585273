import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/site/question-answer`

export default {
    async list(params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async getQuestionAnswer(params) {
        const { id } = params;
        const response = await Repository.get(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async create(data) {
        const response = await Repository.post(`${resource}`, data, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async update(id, data) {
        const response = await Repository.put(`${resource}/${id}`, data, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async delete(id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },
}