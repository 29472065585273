import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/auth`

export default {
  async login(payload) {
    const response = await Repository.post(`${resource}/login`, payload)
    return response
  },
  async profile() {
    const response = await Repository.get(`${resource}/profile`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateProfile(user) {
    const response = await Repository.post(`${resource}/profile`, user, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async changePassword(user) {
    const response = await Repository.post(`${resource}/change-password`, user, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async confirmReset(payload) {
    const response = await Repository.post(`${resource}/reset`, payload, {
      headers: {
        Language: _.get(TokenService.getlang(), '', 'vi'),
      },
    })
    return response
  },

  async checkToken(token) {
    const response = await Repository.get(`${resource}/check/${token}`, {
      headers: {
        Language: _.get(TokenService.getlang(), '', 'vi'),
      },
    })
    return response
  },
}
