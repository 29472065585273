<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('department_create')"
        class="primary mx-0"
        @click="addNewDepartment"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên nhóm khách hàng</th>
            <th>Kiểu</th>
            <th>Mô tả</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(department, index) in departments">
            <tr :key="department.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(department, "name") }}</td>
              <td>{{ getDepartmentTypeText(_.get(department, "type")) }}</td>
              <td>{{ _.get(department, "description") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('department_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editDepartment(department)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin nhóm khách hàng</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('department_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(department)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa nhóm khách hàng</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <department-form />
  </div>
</template>
<script>
import { departmentTypeOptions } from "../../../helpers/constants";
import DepartmentForm from "./DepartmentForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const DepartmentRepository = RepositoryFactory.get("department");
export default {
  components: { DepartmentForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    departments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDepartment: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("department_update") ||
        this.checkAuthorization("department_delete")
      );
    },
  },
  methods: {
    addNewDepartment() {
      this.selectedDepartment = {};
      Bus.$emit("department-show-form", this.selectedDepartment);
    },
    editDepartment(department) {
      this.selectedDepartment = department;
      Bus.$emit("department-show-form", this.selectedDepartment);
    },
    async onClickDelete(department) {
      this.$swal({
        title: "Xóa nhóm khách hàng",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await DepartmentRepository.delete(department.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("department-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    getDepartmentTypeText(type) {
      const departmentType = departmentTypeOptions.find(
        (o) => o.value === type
      );
      return _.get(departmentType, "label", "");
    },
  },
};
</script>
