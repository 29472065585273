var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"table-layout pa-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"th-id"},[_vm._v("#")]),_c('th',[_vm._v("Tên đăng nhập")]),_c('th',[_vm._v("Họ và tên")]),_c('th',[_vm._v("Ảnh đại diện")]),_c('th',[_vm._v("Số điện thoại")]),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Chức vụ")]),_c('th',[_vm._v("Đăng nhập lần cuối")]),(_vm.showAction)?_c('th',[_vm._v("Thao tác")]):_vm._e()])]),_c('tbody',[_vm._l((_vm.users),function(user,index){return [_c('tr',{key:user.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(_vm._.get(user, "username")))]),_c('td',[_vm._v(_vm._s(_vm._.get(user, "fullName")))]),_c('td',[_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"color":"grey"}},[(_vm._.get(user, 'avatar.id'))?_c('img',{attrs:{"src":_vm._.get(user, 'avatar.filePath', '')}}):_c('v-img',{attrs:{"src":require("@/assets/default-avatar.jpg")}})],1)],1),_c('td',[_vm._v(_vm._s(_vm._.get(user, "phone")))]),_c('td',[_vm._v(_vm._s(_vm._.get(user, "email")))]),_c('td',[_vm._v(" "+_vm._s(_vm._.get( _vm.userPositionOptions.find( function (position) { return position.value === user.position; } ), "text", "" ))+" ")]),_c('td',[_vm._v(_vm._s(_vm._.get(user, "lastLoginAt")))]),(_vm.showAction)?_c('td',[_c('div',{staticClass:"action"},[(user.status === 'inactive')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"22","color":"info","icon":""},on:{"click":function($event){return _vm.convertStatus(user.id, 'active')}}},on),[_vm._v("mdi-account-check-outline")])]}}],null,true)},[_c('span',[_vm._v("Chuyển sang trạng thái hoạt động")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"22","color":"success","icon":""},on:{"click":function($event){return _vm.convertStatus(user.id, 'inactive')}}},on),[_vm._v("mdi-account-cancel-outline")])]}}],null,true)},[_c('span',[_vm._v("Chuyển sang trạng thái ngừng hoạt động")])]),(_vm.checkAuthorization('user_update'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"size":"22","icon":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editUser(user)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Cập nhật thông tin người dùng")])]):_vm._e(),(_vm.checkAuthorization('user_update'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"size":"22","icon":"","small":"","color":"info","dark":""},on:{"click":function($event){return _vm.resetPassword(user)}}},on),[_c('v-icon',[_vm._v("mdi-lock-reset")])],1)]}}],null,true)},[_c('span',[_vm._v("Cập nhật mật khẩu người dùng")])]):_vm._e(),(_vm.checkAuthorization('user_delete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"size":"22","icon":"","small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.onClickDelete(user)}}},on),[_c('v-icon',[_vm._v("mdi-window-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa người dùng")])]):_vm._e()],1)]):_vm._e()])]})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }