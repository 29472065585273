import Repository from './Repository'
import TokenService from '../helpers/token'
const resource = `${Repository.defaults.baseURL}/address`
export default {
  async provinces (params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async districts (params, provinceId) {
    const response = await Repository.get(`${resource}/${provinceId}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async wards (params, provinceId, districtId) {
    const response = await Repository.get(`${resource}/${provinceId}/${districtId}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
}
