<template>
  <div v-if="items.length > 0">
    <v-list-item @click="toggle">
      <v-list-item-action>
        <v-icon
          :class="{ 'color-inherit': selectAllItem}"
          class="primary--text"
        >
          {{ icon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-title>
        Chọn Tất Cả
      </v-list-item-title>
    </v-list-item>
    <v-divider
      slot="prepend-item"
      style="margin-top: 0px; margin-bottom: 0px;"
    />
    <input
      style="display:none; height: 0px; width: 0px;"
      :value="item"
    >
  </div>
</template>

<style scoped>
  .color-inherit {
    color: inherit;
  }
</style>

<script>

  export default {
    name: 'SelectAll',
    inheritAttrs: false,
    model: {
      prop: 'item',
    },
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      item: {
        type: [String, Number, Array, Object],
        default: () => [],
      },
      nameAttribute: {
        type: String,
        default: '',
      },
      change: {
        type: Function,
        default: () => (
          false
        ),
      },
    },
    computed: {
      selectAllItem () {
        return this.items.length === this.item.length
      },
      selectSomeItem () {
        return this.item.length > 0 && !this.selectAllItem
      },
      icon () {
        if (this.selectAllItem) return 'mdi-checkbox-marked'
        // if (this.selectSomeItem) return 'mdi-checkbox-blank-outline';
        return 'mdi-checkbox-blank-outline'
      },
    },
    beforeDestroy () {
      this.$off('input')
    },
    methods: {
      toggle () {
        this.$nextTick(() => {
          let itemTemp = this.items.slice()
          if (this.selectAllItem) {
            itemTemp = []
          }
          this.$emit('select-all', !this.selectAllItem);
          this.$emit('input', itemTemp)
          this.change()
        })
      },
    },
  }
</script>
