import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/combo`

export default {
    async list (params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async detail (id) {
        const response = await Repository.get(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async create (combo) {
        const response = await Repository.post(`${resource}`, combo, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async update (id, combo) {
        const response = await Repository.patch(`${resource}/${id}`, combo, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async deleteOneItem (id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async deleteManyItems (ids) {
        const response = await Repository.delete(`${resource}/`, {
            headers: TokenService.getHeader(),
            data: {
                ids,
            },
        })
        return response
    },
    async updateStatus (id, params) {
        const response = await Repository.patch(`${resource}/${id}/status`, params, {
            headers: TokenService.getHeader(),
        })
        return response
    },
}
