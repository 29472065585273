import DashboardLayout from '../layouts/DashboardLayout'
export const promotionsMenu = {
  path: '/promotions',
  component: DashboardLayout,
  name: 'Promotions',
  redirect: '/promotions',
  children: [
    {
      path: '/promotions',
      // name: 'Promotions',
      component: () => import (/* webpackChunkName: "about" */'../pages/promotions/PromotionsList'),
      meta: {
        requiresAuth: true,
        roles: ['promotion_read'],
        breadcrumb: [{
          title: 'Chương trình khuyến mãi'
        }]
      },
    },
    {
      path: '/edit-promotions/:id',
      name: 'EditPromotions',
      component: () => import(/* webpackChunkName: "about" */'../pages/promotions/components/PromotionsForm'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách chương trình khuyến mãi',
          to: '/promotions',
        }, {
          title: 'Chỉnh sửa chương trình khuyến mãi',
          to: '',
        }],
        roles: ['promotion_update'],
      },
    },
    {
      path: 'create-promotions',
      name: 'CreatePromotions',
      component: () => import(/* webpackChunkName: "about" */'../pages/promotions/components/PromotionsForm'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách chương trình khuyến mãi',
          to: '/products/product-list',
        }, {
          title: 'Tạo chương trình khuyến mãi',
          to: '',
        }],
        roles: ['promotion_create'],
      },
    },
  ],
}