import Repository from './Repository';
import TokenService from '../helpers/token';

const resource = `${Repository.defaults.baseURL}/sms`;

export default {
  
  async create(params) {
    const response = await Repository.post(`${resource}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async list(params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async detail(id) {
    const response = await Repository.get(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update(id, params) {
    const response = await Repository.patch(`${resource}/${id}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async send(params) {
    const response = await Repository.post(`${resource}/send`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async delete(id) {
    const response = await Repository.delete(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async listHistory(params) {
    const response = await Repository.get(`${resource}/history`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async detailHistory(id) {
    const response = await Repository.get(`${resource}/history/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async resend(params) {
    const response = await Repository.post(`${resource}/resend`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async countSms(params) {
    const response = await Repository.post(`${resource}/count-sms`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getHanaParams() {
    const response = await Repository.get(`${resource}/hana`, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
