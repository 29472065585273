<template>
  <span>
    <v-autocomplete
      class="select-all--user"
      v-model="valueUserTemp"
      :items="items"
      :label="label"
      :placeholder="placeholder"
      :multiple="multiple"
      :clearable="clearable"
      @change="onChangeUser(); changeUser()"
      :error-messages="errorMessages"
      :readonly="readonly"
      :disabled="disabled"
      chips
      :color="color"
      :item-text="itemText"
      :item-value="itemValue"
      :prepend-icon="prependIcon"
    >
      <template v-if="multiple" #prepend-item>
        <common-select-all
          v-model="valueUserTemp"
          :items="items.filter(s => s.id).map(s => s.id)"
          @select-all="selectAll"
        />
      </template>
      <template v-if="multiple && !disabled" v-slot:selection="data">
        <v-chip
          class="bg-transparent"
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
        >{{ data.item.name || data.item.fullName }}</v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-avatar>
            <img v-if="data.item.avatar" :src="data.item.avatar" />
            <v-img v-else src="../../assets/default-avatar.jpg" />
          </v-list-item-avatar>
          <v-list-item-content class="pa-1 px-0">
            <v-list-item-title v-html="data.item.fullName || data.item.name " />
            <v-list-item-subtitle v-html="data.item.group" />
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <input style="display:none; height: 0px; width: 0px;" v-bind:value="valueUser" />
  </span>
</template>

<script>
export default {
  name: 'SelectUser',
  model: {
    prop: 'valueUser'
  },
  props: {
    changeUser: {
      type: Function,
      default: () => {},
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text'
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'value'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dateRangeValue: {
      type: Array,
      default: () => []
    },
    valueUser: {
      type: [String, Number, Array],
      default: () => ''
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    prependIcon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valueUserTemp: ''
    };
  },
  created() {
    this.valueUserTemp = this.valueUser;
  },
  updated() {
    this.valueUserTemp = this.valueUser;
  },
  beforeDestroy () {
    this.$off('input')
  },
  methods: {
    onChangeUser() {
      this.$emit('input', this.valueUserTemp);
    },
    selectAll(value) {
      if (value) {
        this.valueUserTemp = this.items.filter(s => s.id).map(s => s.id);
      } else {
        this.valueUserTemp = [];
      }
      this.$emit('input', this.valueUserTemp);
    },
    remove(item) {
      const index = this.valueUserTemp.indexOf(item.id);
      if (index >= 0) this.valueUserTemp.splice(index, 1);
      this.$emit('input', this.valueUserTemp);
    }
  }
};
</script>
<style scoped>
  /deep/.select-all--user .v-select__selections{
    height: 32px;
    overflow: hidden;
    min-height: 32px;
  }
</style>
