<template>
  <v-card>
    <v-card-title
      class="display-2 font-weight-bold"
    >Cuộc gọi kết thúc. Bạn đã hoàn thành xong lịch hẹn của khách hàng này chưa ?</v-card-title>

    <v-card-actions class="mt-3 d-flex justify-center">
      <v-btn color="success" class="ma-0 mr-2" @click="confirmCall()" small>Hoàn thành</v-btn>

      <v-btn color="primary" class="ma-0 mr-2" @click="cancelCall()" small>Chưa hoàn thành</v-btn>
      <v-btn v-if="showCreateAppointment" class="ma-0" small color="warning" @click="createAppointment()">Tạo lịch hẹn mới</v-btn>
    </v-card-actions>

    <v-dialog persistent v-model="showRelation" max-width="480">
      <v-card>
        <validation-observer v-slot="{ handleSubmit }">
          <v-card-text class="d-flex align-center justify-space-between">
            Chuyển mối quan hệ
            <v-btn color="default" class="ma-0" small icon @click="closeRelation()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-text>
          <div style="padding: 0px 10px;">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required"
                name="Chuyển mối quan hệ"
              >
                <v-autocomplete
                  v-model="statusId"
                  :items="selectContactStatusList"
                  item-text="name"
                  item-value="id"
                  label
                  :error-messages="errors"
                />
              </validation-provider>
            </div>

          <v-card-actions class="d-flex justify-center mt-3">
            <v-btn
              color="success"
              class="mr-2"
              small
              @click.prevent="handleSubmit(changeSelectStatus)"
            >Đồng ý</v-btn>

          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import RepositoryFactory from '../../repositories/RepositoryFactory';
const ContactRepository = RepositoryFactory.get('contact');
const ContactStatusRepository = RepositoryFactory.get('contact_status');
const AppointmentRepository = RepositoryFactory.get('appointment')

export default {
  props: {
    dataAppointment: {
      default: () => ({})
    },
    showCreateAppointment: {
      type: Boolean,
      default: true
    },
    mobile: {
      type: [String, Number]
    },
  },
  data() {
    return {
      showRelation: false,
      statusId: '',
      changeStatus: false,
      contactStatusList: [],
      dataContact: {},
    };
  },
  computed: {
    selectContactStatusList: function () {
      const selectType = _.get(
              this.dataContact,
              'contactStatus.type',
              'T'
      ).includes('T')
              ? ['T1', 'T2', 'T3']
              : ['C1', 'C2', 'C3'];
      // if (this.isStaff() && !this.checkAuthorization('contact_view_all')) {
      //   selectType.shift();
      // }
      const contactStatusList = [];
      const checkType = {};
      this.contactStatusList.forEach(contactStatus => {
        const typeContactStatus = contactStatus.type;
        if (selectType.includes(typeContactStatus)) {
          if (!checkType[typeContactStatus]) {
            checkType[typeContactStatus] = true;
            if (contactStatusList.length > 0) {
              contactStatusList.push({divider: true});
            }
            contactStatusList.push({
              header: typeContactStatus,
              style: 'color: red;'
            });
          }
          contactStatusList.push(contactStatus);
        }
      });
      for (let i = 0; i < contactStatusList.length; i++) {
        if (contactStatusList[i].header === 'T0' || contactStatusList[i].type === 'T0' ||
                contactStatusList[i].header === 'C0' || contactStatusList[i].type === 'C0') {
          contactStatusList.splice(i, 1)
        }
      }
      return contactStatusList;
    }
  },
  async created() {
    if(_.get(this.dataAppointment,'customer.contact.id', '') && this.dataAppointment.id) {
      this.dataContact = _.get(this.dataAppointment, 'customer.contact', {}) || {};
      await this.getContactStatusList();
    }
  },
  destroyed() {
    Bus.$off('hide-button-finish')
  },
  methods: {
    closeRelation() {
      this.showRelation = false;
      this.statusId = '';
    },
    async updateAppointmentStatusDone () {
      const statusAppointment = {
        status: 'done'
      }
      const response = await AppointmentRepository.updateStatus(this.dataAppointment.id, statusAppointment)
      if (!response.success) {
         this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', ''),
        }).then(() => {});
      } else {
        // Dong popup goi dien
        // this.$emit('end-call')
        // Fetch lai data
        Bus.$emit('appointment-list-reload')
        Bus.$emit('reset-fech-notifications')
        // Show appointment form
        Bus.$emit('appointment-show-form-from-appointment', {id: this.dataAppointment.id})
      }
    },
    async getContactStatusList() {
      const loading = this.$loading.show();
      const store = _.get(
        this.dataContact,
        'contactStatus.type',
        'T'
      ).includes('T') ? 'T' : 'C';
      const response = await ContactStatusRepository.list({store});
      loading.hide();
      if (response.success) {
        const contactStatusList = [];
        _.get(response, 'data', []).forEach(contactStatus => {
          _.get(contactStatus, 'data', []).forEach(data => {
            contactStatusList.push({
              type: contactStatus.type,
              id: _.get(data, 'id', 0),
              name: _.get(data, 'name', 0),
            });
          });
        });
        this.contactStatusList = contactStatusList;
      } else {
        this.contactStatusList = [];
      }
    },
    async changeSelectStatus() {
     await this.callAPIChangeStatus();
    },
    async callAPIChangeStatus() {
      const dataUpdate = [
        {
          contactId: this.dataContact.id,
          statusId: this.statusId
        }
      ];
      const loading = this.$loading.show();
      const response = await ContactRepository.updateContactStatus(dataUpdate);
      loading.hide();
      if (response.error) {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', ''),
        }).then(() => {
          this.showRelation = false;
        });
      } else {
        this.changeStatus = true;
        this.showRelation = false;
        await this.updateAppointmentStatusDone();
        this.cancelCall();
        // const routeData = this.$router.push({
        //   name: 'Appointment',
        //   query: {
        //     keyword: _.get(this.dataContact,'mobile', ''),
        //   }
        // });
      }
    },
    async confirmCall() {
      if (
        _.get(this.dataContact, 'id', '') &&
        this.checkAuthorization('contact_change_status_read')
      ) {
        this.showRelation = true;
      } else {

        if(this.dataAppointment.id) {
          await this.updateAppointmentStatusDone();
        }
      }
    },
    cancelCall() {
      Bus.$emit('close-popup-confirm')
    },
    async createAppointment() {
      const statusAppointment = {
        status: 'done'
      }
      const response = await AppointmentRepository.updateStatus(this.dataAppointment.id, statusAppointment)
      if (!response.success) {
        this.$swal({
          type: 'error',
          title: 'Lỗi !',
          text: _.get(response, 'message', ''),
        }).then(() => {});
      } else {
        // this.$emit('end-call')
        // Fetch lai data
        Bus.$emit('appointment-list-reload')
        Bus.$emit('reset-fech-notifications')
        // show new appointment form
        const data = {
            contactId: _.get(this.dataAppointment, 'customer.contact.id', ''),
            mobile: _.get(this.dataAppointment, 'customer.mobile', ''),
            fullName: _.get(this.dataAppointment, 'customer.fullName', ''),
        }
        Bus.$emit('appointment-show-form-from-contact', data);
      }
    }
  }
};
</script>
