import TokenService from '@/helpers/token'
import Repository from './Repository'
import axios from 'axios'

const baseUrl = `${Repository.defaults.baseURL}/call-center`

export default {
    async list(params) {
        const response = await Repository.get(`${baseUrl}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async reportList(params) {
        const response = await Repository.get(`${baseUrl}/report`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async reportEmployeesList(params) {
        const response = await Repository.get(`${baseUrl}/report-employees`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async loginCareSoft(body) {
        const response = await axios.post(`${Repository.defaults.baseURL}/external/caresoft/login`, body, {
            headers: TokenService.getHeader()
        })
        return response.data
    }
}
