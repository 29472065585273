import TokenService from '@/helpers/token'
import Repository from './Repository'

const baseUrl = `${Repository.defaults.baseURL}/product-folder`

export default {
  async list (params) {
    const response = await Repository.get(`${baseUrl}/list`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteOneItem (id) {
    const response = await Repository.delete(`${baseUrl}/delete/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteManyItems (ids) {
    const response = await Repository.delete(`${baseUrl}/`, {
      headers: TokenService.getHeader(),
      data: {
        ids,
      },
    })
    return response
  },

  async create (productGroup) {
    const response = await Repository.post(`${baseUrl}/create`, productGroup, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update (id, productGroup) {
    const response = await Repository.patch(`${baseUrl}/update/${id}`, productGroup, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getDetails (id) {
    const response = await Repository.get(`${baseUrl}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateStatus (id, params) {
    const response = await Repository.patch(`${baseUrl}/${id}/status`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
