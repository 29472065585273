<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới nhóm khách hàng </template>
          <template v-else> Cập nhật thông tin nhóm khách hàng </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên nhóm khách hàng"
              >
                <v-text-field
                  v-model="department.name"
                  label="Tên nhóm khách hàng *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required"
                name="Loại nhóm khách hàng"
              >
                <v-autocomplete
                  :items="departmentType"
                  item-text="label"
                  item-value="value"
                  v-model="department.type"
                  label="Loại nhóm khách hàng *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Mô tả"
                rules="max: 255"
              >
                <v-textarea
                  v-model="department.description"
                  label="Mô tả"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveDepartment)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import { departmentTypeOptions } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const DepartmentRepository = RepositoryFactory.get("department");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      department: {
        name: "",
        description: "",
        type: "",
      },
      isCreate: true,
      departments: [],
      errorMessage: "",
      departmentType: departmentTypeOptions,
    };
  },
  created() {
    Bus.$on("department-show-form", (department) => {
      this.showModal = true;
      this.department = _.cloneDeep(department);
      if (department.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveDepartment() {
      if (this.isCreate) {
        const department = {
          name: _.trim(this.department.name),
          description: _.trim(this.department.description),
          type: this.department.type,
        };
        const loader = this.$loading.show();
        const response = await DepartmentRepository.create(department);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm nhóm khách hàng thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("department-list-reload");
          });
        }
        loader.hide();
      } else {
        const department = {
          name: _.trim(this.department.name),
          description: _.trim(this.department.description),
          type: this.department.type,
        };
        const loader = this.$loading.show();
        const response = await DepartmentRepository.update(
          this.department.id,
          department
        );
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật nhóm khách hàng thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("department-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
