import Repository from './Repository';
import TokenService from '../helpers/token';

const resource = `${Repository.defaults.baseURL}/contact-filter`;

export default {
  
  async create(params) {
    const response = await Repository.post(`${resource}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async list(params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async detail(id) {
    const response = await Repository.get(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update(id, params) {
    const response = await Repository.patch(`${resource}/${id}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async delete(id) {
    const response = await Repository.delete(`${resource}/${id}`, {
      headers: TokenService.getHeader()
    });
    return response;
  },

  async listContact(id) {
    const response = await Repository.get(`${resource}/${id}/list-contact`, {
      headers: TokenService.getHeader()
    });
    return response;
  },

  async listResult(id) {
    const response = await Repository.get(`${resource}/${id}/send-result`, {
      headers: TokenService.getHeader()
    });
    return response;
  },

  async listFilter() {
    const response = await Repository.get(`${resource}/list`, {
      headers: TokenService.getHeader()
    });
    return response;
  }
}
