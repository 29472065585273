import DashboardLayout from '../layouts/DashboardLayout'
import TagList from '../pages/tag/TagList.vue'

export const newsMenu = {
  path: '/tags',
  component: DashboardLayout,
  name: 'Tags',
  redirect: '/tags',
  children: [
    {
      path: 'tag-list',
      name: 'TagList',
      component: () => import (/* webpackChunkName: "about" */'../pages/tag/TagList.vue'),
      meta: {
        roles: ['tag_read','tag_create','tag_update','tag_delete', 'tag_view_all'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách thẻ',
        }]
      },
    },
  ],
}
