import Repository from './Repository'
import TokenService from '../helpers/token'
const resource = `${Repository.defaults.baseURL}/core-action-log`
export default {
    async detail(params) {
        console.log('this my sysstem', params)
        const response = await Repository.get(`${resource}/list/by-contact`, {
            params,
            headers: TokenService.getHeader(),
        })
        console.log('response', response)
        return response
    },
}