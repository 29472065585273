import TokenService from './token'
import { regex, fbContactSources } from './constants'

const moment = require('moment')
const axios = require('axios')

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function formatDate(date) {
  if (date) {
    return moment(date)
      .format('YYYY-MM-DD')
  }
  return null
}

export function formatHour(date) {
  if (date) {
    return moment(date)
      .format('HH:MM:SS')
  }
  return null
}

export async function downloadFile(urlFile, fileName) {
  axios({
    method: 'get',
    url: urlFile,
    responseType: 'arraybuffer',
  })
    .then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
    })
    .catch(() => console.log('error occured'))
}

export function findByIdInTree(id, array, childName) {
  let o
  array.some(function iter(a) {
    if (a.id === id) {
      o = a
      return true
    }
    return Array.isArray(a[childName]) && a[childName].some(iter)
  })
  return o
}

export function formatPrice(value) {
  const valueABS = Math.abs(value);
  if (isNaN(valueABS))
    return value;
  if (valueABS > -1000 && valueABS < 1000) {
    return value;
  }
  const valueNumber = Intl.NumberFormat('en-US').format(valueABS);
  if (Number(value) < 0) {
    return `-${valueNumber}`;
  }
  return valueNumber;
}

export function updateProfileInto(user) {
  if (user && user.id) {
    let userRole = { name: user.role.name, permissions: [] };
    for (let i = 0; i < user.role.permissions.length; i++) {
      if (user.role.permissions[i].model == 'search_all') {
        userRole.permissions.push(user.role.permissions[i]);
      }
    }
    TokenService.setUser({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      email: user.email,
      avatar: user.avatar,
      position: user.position,
      listDepartmentIds: user.listDepartment.map(item => item.id),
      role: userRole
    })
    const permissions = _.get(user, 'role.permissions', []).map(p => `${p.model}_${p.action}`);
    TokenService.setPermissions(permissions);
  }
}

export function checkMobile() {
  let check = false
  // if (regex.isMobile1.test(navigator.userAgent) || regex.isMobile2.test(navigator.userAgent.substr(0, 4))) {
  //   check = true
  // }

  // if (regex.isMobile1.test(navigator.vendor) || regex.isMobile2.test(navigator.vendor.substr(0, 4))) {
  //   check = true
  // }

  // if (typeof (window.opera) != 'undefined') {
  //   if (regex.isMobile1.test(window.opera) || regex.isMobile2.test(window.opera.substr(0, 4))) {
  //     check = true
  //   }
  // }
  return check
}

export function showFbMessengerIcon(contactSources) {
  if (typeof (contactSources) == 'undefined') {
    return false
  } else {
    if (typeof (contactSources) == 'string') {
      contactSources = contactSources.split(', ')
    } else if (typeof (contactSources) == 'object' && typeof (contactSources[0]) == 'object') {
      contactSources = contactSources.map(item => item.name)
    }

    if (fbContactSources.some(val => contactSources.indexOf(val) > -1)) {
      return true
    }

    return false
  }
}

export function filterItemValidArray(arr) {
  let tarr = [];
  for (let item of arr) {
    if (item) tarr.push(item);
  }
  return tarr;
}