import DashboardLayout from '../layouts/DashboardLayout'
import UserList from '../pages/users/UserList'
import DepartmentList from '../pages/departments/DepartmentList'
// import KPIList from '../pages/kpi/KPIList'
// import KPIDetail from '../pages/kpi/KPIDetail'
// import ScheduleList from '../pages/schedules/ScheduleList'
// import ScheduleForm from '../pages/schedules/ScheduleCreateForm'
// import Appointment from '../pages/appointment/Appointment'

export const userMenu = {
  path: '/users',
  component: DashboardLayout,
  name: 'Users',
  redirect: '/users',
  children: [
    {
      path: 'customer-list',
      name: 'CustomerList',
      component: () => import (/* webpackChunkName: "about" */'../pages/customer/CustomerList.vue'),
      meta: {
        roles: ['customer_read','customer_create','customer_update','customer_delete', 'customer_view_all'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách khách hàng',
        }]
      },
    },
    {
      path: 'user-list',
      name: 'UserList',
      component: () => import (/* webpackChunkName: "about" */'../pages/users/UserList.vue'),
      meta: {
        roles: ['user_read','user_create','user_update','user_delete', 'user_view_all'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách người dùng',
        }]
      },
    },
    {
      path: 'department-list',
      name: 'Department List',
      component: () => import (/* webpackChunkName: "about" */'../pages/departments/DepartmentList.vue'),
      meta: {
        roles: ['department_read','department_create','department_update','department_delete'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Nhóm khách hàng',
        }]
      },
    },
    // {
    //   path: 'kpi',
    //   name: 'KPIList',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/kpi/KPIList.vue'),
    //   meta: {
    //     roles: ['kpi_read','kpi_create','kpi_update','kpi_delete'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Danh sách KPI',
    //     }]
    //   },
    // },
    // {
    //   path: '/users/kpi/detail/:id',
    //   name: 'KPIDetail',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/kpi/KPIDetail.vue'),
    //   meta: {
    //     roles: ['kpi_read', 'kpi_update'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Danh sách KPI',
    //       to: '/users/kpi'
    //     }, {
    //       title: 'Chi tiết KPI'
    //     }]
    //   },
    // },
    // {
    //   path: 'schedule-list',
    //   name: 'ScheduleList',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/schedules/ScheduleList.vue'),
    //   meta: {
    //     roles: ['schedule_read','schedule_create','schedule_update','schedule_delete', 'schedule_view_all'],
    //     requiresAuth: true,
    //     breadcrumb: [{
    //       title: 'Danh sách lịch làm việc',
    //     }]
    //   },
    // },
    // {
    //   path: 'edit-schedule/:id',
    //   name: 'EditSchedule',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/schedules/ScheduleCreateForm.vue'),
    //   meta: {
    //     roles: ['schedule_update'],
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: 'create-schedule',
    //   name: 'CreateSchedule',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/schedules/ScheduleCreateForm.vue'),
    //   meta: {
    //     roles: ['schedule_create'],
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: 'appointment',
    //   name: 'Appointment',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/appointment/Appointment.vue'),
    //   meta: {
    //     roles: ['product_link_customer_user_read','appointment_create','appointment_update','appointment_delete', 'appointment_view_all'],
    //     requiresAuth: true,
    //     role: 'all',
    //     breadcrumb: [{
    //       title: 'Danh sách lịch hẹn'
    //     }]
    //   },
    // },
    {
      path: 'manager-product-to-customer-list',
      name: 'Management',
      component: () => import (/* webpackChunkName: "about" */'../pages/management/ManagementList.vue'),
      meta: {
        roles: ['product_link_customer_user_read','product_link_customer_user_create','product_link_customer_user_update','product_link_customer_user_delete'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Quản lý kinh doanh'
        }]
      },
    },
    {
      path: 'create-customer',
      name: 'CreateCustomer',
      component: () => import (/* webpackChunkName: "about" */'../pages/customer/components/CustomerForm'),
      meta: {
        roles: ['customer_create'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Quản lý khách hàng',
          to: 'users/customer-list'
        }, {
          title: 'Tạo mới khách hàng',
        }]
      }
    },
    {
      path: 'edit-customer/:id',
      name: 'EditCustomer',
      component: () => import (/* webpackChunkName: "about" */'../pages/customer/components/CustomerForm'),
      meta: {
        roles: ['customer_update'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Quản lý khách hàng',
          to: 'users/customer-list'
        }, {
          title: 'Chỉnh sửa khách hàng',
        }]
      }
    }
  ],
}
