export const models = [
    // {
    //     name: 'contact_managment',
    //     text: 'Khách hàng',
    //     permissions: [],
    //     level: 1,
    // },
    // {
    //     name: 'contact',
    //     text: 'Thông tin khách hàng',
    //     permissions: ['read', 'create', 'update', 'delete', 'view_all', 'download'],
    //     level: 2,
    // },
    // {
    //     name: 'contact_change_user',
    //     text: 'Chuyển người phụ trách',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'contact_change_status',
    //     text: 'Chuyển mối quan hệ',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'contact_change_phone',
    //     text: 'Chuyển số điện thoại',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'contact_move_to_cc',
    //     text: 'Thu hồi contact về kho CC',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'contact_move_to_m',
    //     text: 'Thu hồi contact về kho M',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'search_all',
    //     text: 'Tìm kiếm tất cả',
    //     permissions: ['read'],
    //     level: 2
    // },
    // {
    //     name: 'merge-contact',
    //     text: 'Gộp contact',
    //     permissions: ['read'],
    //     level: 2
    // },
    // {
    //     name: 'admin-search',
    //     text: 'Tìm kiếm cấp admin',
    //     permissions: ['read'],
    //     level: 2
    // },
    // {
    //     name: 'hide-contact-log',
    //     text: 'Ẩn log ghi chú',
    //     permissions: ['read'],
    //     level: 2
    // },
    // {
    //     name: 'lock-edit-attr-dashboard',
    //     text: 'Khóa edit thông tin trực tiếp trên dashboard',
    //     permissions: ['read'],
    //     level: 2,
    // },
    {
        name: 'dashboard_management',
        text: 'Dashboard',
        permissions: [],
        level: 1,
    },
    {
        name: 'dashboard',
        text: 'Dashboard',
        permissions: ['read'],
        level: 2,
    },
    {
        name: 'user_managment',
        text: 'Tài khoản',
        permissions: [],
        level: 1,
    },
    {
        name: 'customer',
        text: 'Khách hàng',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'user',
        text: 'Người dùng',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'department',
        text: 'Nhóm khách hàng',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product_link_customer_user',
        text: 'Quản lý kinh doanh',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    // {
    //     name: 'kpi',
    //     text: 'KPI',
    //     permissions: ['read', 'create', 'update', 'delete'],
    //     level: 2,
    // },
    // {
    //     name: 'schedule',
    //     text: 'Lịch làm việc',
    //     permissions: ['read', 'create', 'update', 'delete', 'view_all'],
    //     level: 2,
    // },
    // {
    //     name: 'appointment',
    //     text: 'Lịch hẹn',
    //     permissions: ['read', 'create', 'update', 'delete', 'view_all'],
    //     level: 2,
    // },
    {
        name: 'report',
        text: 'Báo cáo',
        permissions: [],
        level: 1,
    },
    {
        name: 'account_report',
        text: 'Báo cáo tài khoản',
        permissions: ['read', 'download'],
        level: 2,
    },
    {
        name: 'product_report',
        text: 'Báo cáo sản phẩm',
        permissions: ['read', 'download'],
        level: 2,
    },
    {
        name: 'sales_report',
        text: 'Sổ chi tiết bán hàng',
        permissions: ['read', 'download'],
        level: 2,
    },
    // {
    //     name: 'general-report',
    //     text: 'Báo cáo chung',
    //     permissions: ['read'],
    //     level: 2
    // },
    // {
    //     name: 'department-report',
    //     text: 'Báo cáo Nhóm khách hàng',
    //     permissions: [],
    //     level: 2,
    // },
    // {
    //     name: 'result-report',
    //     text: 'Theo kết quả',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'employee-report',
    //     text: 'Theo nhân viên',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'status-report',
    //     text: 'Theo trạng thái',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'contact-source-report',
    //     text: 'Theo nguồn contact',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'human-resources-report',
    //     text: 'Theo nguồn lực',
    //     permissions: ['read', , 'download'],
    //     level: 3,
    // },
    // {
    //     name: 'best-employee',
    //     text: 'Báo cáo nhân viên xuất sắc',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'sale-leader',
    //     text: 'Báo cáo trưởng nhóm',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'revenue-report',
    //     text: 'Báo cáo doanh thu',
    //     permissions: [],
    //     level: 2,
    // },
    // {
    //     name: 'product-group-revenue-report',
    //     text: 'Theo sản phẩm, nhóm sản phẩm',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'customer-revenue-report',
    //     text: 'Theo khách hàng',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'personal-report',
    //     text: 'Báo cáo thành tích cá nhân',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'contact-register',
    //     text: 'Báo cáo đăng ký theo ngày',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'delivery-report',
    //     text: 'Báo cáo giao hàng',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'a-department-report',
    //     text: 'Báo cáo Nhóm khách hàng trực thuộc',
    //     permissions: [],
    //     level: 2,
    // },
    // {
    //     name: 'employee-report-a-department',
    //     text: 'Theo nhân viên',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'view-total-report',
    //     text: 'View tổng',
    //     permissions: ['read'],
    //     level: 2,
    // },
    {
        name: 'order_management',
        text: 'Đơn hàng',
        permissions: [],
        level: 1,
    },
    {
        name: 'order',
        text: 'Đơn hàng',
        permissions: ['read', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'order_history',
        text: 'Lich sử duyệt đơn',
        permissions: ['read', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product_management',
        text: 'Sản phẩm',
        permissions: [],
        level: 1,
    },
    {
        name: 'product_folder',
        text: 'Loại sản phẩm',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product',
        text: 'Sản phẩm',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product_utility',
        text: 'Nhóm công dụng',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product_treatment',
        text: 'Nhóm điều trị',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product_pathology',
        text: 'Nhóm dược lý',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product_manufacture',
        text: 'Nhà sản xuất',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'product_unit',
        text: 'Đơn vị tính',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'discount_management',
        text: 'Cài đặt chiết khấu',
        permissions: [],
        level: 1,
    },
    {
        name: 'discount',
        text: 'Phần trăm chiết khấu',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'promotion_management',
        text: 'Khuyến mại',
        permissions: [],
        level: 1,
    },
    {
        name: 'promotion',
        text: 'Chương trình khuyến mại',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'banner_management',
        text: 'Quảng cáo',
        permissions: [],
        level: 1,
    },
    {
        name: 'banner',
        text: 'Banner',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'behavior_management',
        text: 'Lịch sử hoạt động',
        permissions: [],
        level: 1,
    },
    {
        name: 'behavior_customer',
        text: 'Khách hàng',
        permissions: ['read'],
        level: 2,
    },
    {
        name: 'behavior_user',
        text: 'Nội bộ',
        permissions: ['read'],
        level: 2,
    },
    {
        name: 'news_management',
        text: 'Thẻ',
        permissions: [],
        level: 1,
    },
    {
        name: 'tag',
        text: 'Thẻ',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'information_policy_management',
        text: 'Chính sách',
        permissions: [],
        level: 1,
    },
    {
        name: 'question_answer',
        text: 'Câu hỏi thường gặp',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'shipping_method',
        text: 'Chính sách giao hàng',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'payment',
        text: 'Phương thức thanh toán',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'regular',
        text: 'Chính sách đổi trả',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'privacy_policy',
        text: 'Chính sách bảo mật',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'about_us',
        text: 'Giới thiệu công ty',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'site_config_management',
        text: 'Website',
        permissions: [],
        level: 1,
    },
    {
        name: 'filter_price',
        text: 'Lọc theo giá',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'filter_percent_discount',
        text: 'Lọc theo chiết khấu',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'partner',
        text: 'Đối tác',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'evaluation',
        text: 'Cảm nhận khách hàng',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'contact_work_management',
        text: 'Liên hệ',
        permissions: [],
        level: 1,
    },
    {
        name: 'contact_work',
        text: 'Khách hàng liên hệ',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    // {
    //     name: 'call_center_management',
    //     text: 'Call Center',
    //     permissions: [],
    //     level: 1,
    // },
    // {
    //     name: 'call_center',
    //     text: 'Danh sách cuộc gọi',
    //     permissions: ['read', 'download', 'view_all'],
    //     level: 2,
    // },
    // {
    //     name: 'general-call-center-report',
    //     text: 'Báo cáo chung',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'employee-call-center-report',
    //     text: 'Báo cáo nhân viên',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'download-call-center-record',
    //     text: 'Download file ghi âm',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'order-ctv-create',
    //     text: 'CTV tạo đơn',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'order-contact',
    //     text: 'Xem tài khoản',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'order-payment',
    //     text: 'Xác nhận thanh toán',
    //     permissions: ['read'],
    //     level: 2,
    // },
    {
        name: 'data_setting_management',
        text: 'Cài đặt dữ liệu',
        permissions: [],
        level: 1,
    },
    {
        name: 'role',
        text: 'Phân quyền',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'icon',
        text: 'Icon thông báo',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    // {
    //     name: 'announcement',
    //     text: 'Bảng tin thông báo',
    //     permissions: ['read', 'create', 'update', 'delete'],
    //     level: 2,
    // },
    {
        name: 'notification',
        text: 'Thông báo nội bộ',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    {
        name: 'notification_customer',
        text: 'Thông báo tới khách hàng',
        permissions: ['read', 'create', 'update', 'delete'],
        level: 2,
    },
    // {
    //     name: 'setting',
    //     text: 'Cấu hình',
    //     permissions: [],
    //     level: 1,
    // },
    // {
    //     name: 'setting-contact',
    //     text: 'Phân bổ contact',
    //     permissions: ['read', 'update'],
    //     level: 2,
    // },
    // {
    //     name: 'dashboard_management',
    //     text: 'Dashboard',
    //     permissions: [],
    //     level: 1,
    // },
    // {
    //     name: 'dashboard',
    //     text: 'Dashboard',
    //     permissions: ['read'],
    //     level: 2,
    // },
    // {
    //     name: 'mkt-sms',
    //     text: 'Marketing SMS',
    //     permissions: [],
    //     level: 2,
    // },
    // {
    //     name: 'mkt-sms-message',
    //     text: 'Tin nhắn',
    //     permissions: ['read', 'update', 'create', 'delete'],
    //     level: 3,
    // },
    // {
    //     name: 'mkt-sms-customer',
    //     text: 'Tệp khách hàng',
    //     permissions: ['read', 'update', 'create', 'delete'],
    //     level: 3,
    // },
    // {
    //     name: 'mkt-sms-contact',
    //     text: 'Xem danh sách contact của tệp',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'mkt-sms-message-history',
    //     text: 'Lích sử gửi tin',
    //     permissions: ['read'],
    //     level: 3,
    // },
    // {
    //     name: 'training-sale',
    //     text: 'Training sale',
    //     permissions: ['read', 'update', 'create', 'delete', 'download'],
    //     level: 2,
    // },
    // {
    //     name: 'training-manager',
    //     text: 'Training manager',
    //     permissions: ['read', 'update', 'create', 'delete', 'download'],
    //     level: 2,
    // },
];

export const actions = [
    {
        name: 'read',
        text: 'Truy cập',
    },
    {
        name: 'create',
        text: 'Thêm'
    },
    {
        name: 'update',
        text: 'Sửa'
    },
    {
        name: 'delete',
        text: 'Xóa'
    },
    {
        name: 'download',
        text: 'Download/Upload'
    },
    {
        name: 'view_all',
        text: 'Xem tất cả'
    }
];


export function getPermissionByAction(action) {
    return models.filter(m => m.permissions.includes(action)).map(model => `${action}-${model.name}`)
}
