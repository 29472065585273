import TokenService from '@/helpers/token'
import Repository from './Repository'

const baseUrl = `${Repository.defaults.baseURL}/product-group`

export default {
  async list (params) {
    const response = await Repository.get(`${baseUrl}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteOneItem (id) {
    const response = await Repository.delete(`${baseUrl}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async deleteManyItems (ids) {
    const response = await Repository.delete(`${baseUrl}/`, {
      headers: TokenService.getHeader(),
      data: {
        ids,
      },
    })
    return response
  },
  async create (productGroup) {
    const response = await Repository.post(`${baseUrl}`, productGroup, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update (id, productGroup) {
    const response = await Repository.patch(`${baseUrl}/${id}`, productGroup, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getDetails (id) {
    const response = await Repository.get(`${baseUrl}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async updateStatus (id, params) {
    const response = await Repository.patch(`${baseUrl}/${id}/status`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
