import DashboardLayout from '../layouts/DashboardLayout'
// import OrderList from '../pages/order/OrderList'
// import OrderDetail from '../pages/order/OrderDetail'
// import OrderListMobile from '../pages/order/OrderListMobile'
// import OrderCreateContact from '../pages/order/OrderCreateContact'
// import OrderAccountList from '../pages/order/OrderAccountList'
// import OrderAccountListMobile from "@/pages/order/OrderAccountListMobile";

export const orderMenu = {
  path: '/orders',
  component: DashboardLayout,
  name: 'Orders',
  redirect: '/orders',
  children: [
    {
      path: 'order-list',
      name: 'OrderList',
      component: () => import (/* webpackChunkName: "about" */'../pages/order/OrderList.vue'),
      meta: {
        roles: ['order_read','order_create','order_update','order_delete', 'order_view_all', 'search_all_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách đơn hàng',
        }]
      },
    },
    {
      path: '/order-detail/:id',
      name: 'OrderDetail',
      component: () => import (/* webpackChunkName: "about" */'../pages/order/OrderDetail.vue'),
      meta: {
        roles: ['order_update', 'order_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách đơn hàng',
          to: '/orders/order-list'
        },{
          title: 'Thông tin đơn hàng',
        }]
      },
    },
    {
      path: '/mobile/orders/order-list',
      name: 'OrderListMobile',
      component: () => import (/* webpackChunkName: "about" */'../pages/order/OrderListMobile.vue'),
      meta: {
        roles: ['order_read','order_create','order_update','order_delete', 'order_view_all', 'search_all_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách đơn hàng',
        }]
      }
    },
    {
      path: 'order-create',
      name: 'OrderCreateContact',
      component: () => import (/* webpackChunkName: "about" */'../pages/order/OrderCreateContact.vue'),
      meta: {
        roles: ['order-ctv-create_read'],
        requiresAuth: true,
        breadcrumb: []
      },
    },
    {
      path: ':id/account',
      name: 'OrderAccountList',
      component: () => import (/* webpackChunkName: "about" */'../pages/order/OrderAccountList.vue'),
      meta: {
        roles: ['order-contact_read'],
        requiresAuth: true,
        breadcrumb: []
      },
    },
    {
      path: '/mobile/orders/:id/account',
      name: 'OrderAccountListMobile',
      component: () => import (/* webpackChunkName: "about" */'../pages/order/OrderAccountListMobile.vue'),
      meta: {
        roles: ['order-contact_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách tài khoản',
        }]
      }
    },
  ],
}