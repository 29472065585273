<template>
  <div class="s-datepicker-range mt-6">
    <label>{{label}}</label>
    <date-range-picker
      ref="picker"
      :opens="opens"
      :locale-data="{
        direction: 'ltr',
        format: 'yyyy-mm-dd',
        separator: ' - ',
        applyLabel: 'Đồng ý',
        cancelLabel: 'Hủy',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        firstDay: 0,
      }"
      :minDate="minDate" :maxDate="maxDate"
      :singleDatePicker="singleDatePicker"
      :timePicker="timePicker"
      :timePicker24Hour="timePicker24Hour"
      :showWeekNumbers="showWeekNumbers"
      :showDropdowns="showDropdowns"
      :autoApply="autoApply"
      v-model="dateRange"
      @update="updateValues"
      @toggle="checkOpen"
      :linkedCalendars="linkedCalendars"
      :always-show-calendars="false"
      :alwaysShowCalendars="alwaysShowCalendars"
      :disabled="readonly"
      :hide-details="hideDetails"
    >
      <template slot="ranges">
        <div class="ranges col-12 col-md-auto col-sm-auto">
          <ul>
            <li
              v-for="range in ranges"
              :key="range.duration"
              v-bind:class="{'active':(range.isActive == true)}"
              @click="setRange(range.duration)"
            >
              {{range.text}}
            </li>
          </ul>
        </div>
      </template>
      <template slot="input">
        <div v-if="dateRange.startDate || duration == 'eternal'" style="display: flex; justify-content: space-between;">
            <span v-if="duration == 'eternal'">Trọn đời</span>
            <span v-else>{{ formatDate(dateRange.startDate) }} ~ {{formatDate(dateRange.endDate)}} </span>
            <span v-show="clearable"><v-icon @click="onClickRemove" color="primary">mdi-close</v-icon></span>
        </div>
        <div v-else>
          <span> - </span>
        </div>       
      </template>

    </date-range-picker>

    <input
      style="display:none; height: 0px; width: 0px;"
      v-bind:value="dateRangeValue"
    >
  </div>
</template>

<script>
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import * as moment from 'moment'

  export default {
    name: 'RangePicker',
    components: {
      DateRangePicker
    },
    model: {
      prop: 'dateRangeValue',
    },
    props: {
      label: {
        type: String,
        default: 'Tìm kiếm theo ngày'
      },
      dateRangeValue: {
        type: Array,
        default: () => [],
      },
      defaultRange: {
        type: Object,
        default: () => ({}),
      },
      aLight: {
        type: String,
        default: () => ''
      },
      clearable: {
        type: Boolean,
        default: true
      },
      readonly: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      hasEternal: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        opens: this.aLight,
        minDate: '',
        maxDate: '',
        show_ranges: true,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showDropdowns: true,
        autoApply: true,
        showWeekNumbers: true,
        linkedCalendars: true,
        alwaysShowCalendars: true,
        duration: '',
        dateRange: {
          startDate: '',
          endDate: '',
        },
        ranges: [
          {
            duration: 'today',
            text: 'Hôm nay',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            isActive: false
          }, {
            duration: 'yesterday',
            text: 'Hôm qua',
            startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            isActive: false
          }, {
            duration: 'week',
            text: 'Tuần này',
            startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
            endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
            isActive: false
          }, {
            duration: 'month',
            text: 'Tháng này',
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            isActive: false
          }, {
            duration: 'quarter',
            text: 'Quý này',
            startDate: moment().startOf('quarter').format('YYYY-MM-DD'),
            endDate: moment().endOf('quarter').format('YYYY-MM-DD'),
            isActive: false
          }, {
            duration: 'year',
            text: 'Năm nay',
            startDate: moment().startOf('year').format('YYYY-MM-DD'),
            endDate: moment().endOf('year').format('YYYY-MM-DD'),
            isActive: false
          }
        ],
      }
    },
    watch: {
      dateRangeValue: {
        deep: true,
        handler(newVal, oldVal) {
          if (_.isEmpty(newVal)) {
            this.dateRange = {
              startDate: '',
              endDate: '',
            }
          } else {
            this.dateRange = {
              startDate: new Date(newVal[0]),
              endDate: new Date(newVal[1]),
            }
          }
        }
      }
    },
    created() {
      if (Array.isArray(this.dateRangeValue) && this.dateRangeValue.length > 1) {
        this.dateRange = {
          startDate: new Date(this.dateRangeValue[0]) || new Date(),
          endDate: new Date(this.dateRangeValue[1]) || new Date(),
        };
      }
      if (!_.isEmpty(this.defaultRange)) {
        this.dateRange = this.defaultRange
      }

      if (this.hasEternal) {
        this.ranges.push({
            duration: 'eternal',
            text: 'Trọn đời',
            startDate: 'eternal',
            endDate: 'eternal',
            isActive: false
          })
      }

      this.ranges.forEach((range,key) => {
        this.ranges[key].isActive = false
        if (this.ranges[key].duration == 'eternal') {
          return
        }
        if( moment(this.dateRange.startDate).format('YYYY-MM-DD') == this.ranges[key].startDate && moment(this.dateRange.endDate).format('YYYY-MM-DD') == this.ranges[key].endDate ) {
          this.dateRange.duration = this.ranges[key].duration
          this.ranges[key].isActive = true
          return
        }
      });
    },
    methods: {
      onClickRemove(e) {
        e.stopPropagation();
        this.duration = ''
        this.dateRange = {
          startDate: '',
          endDate: '',
        };
        this.$emit('change-date-range', this.dateRange);
        this.$emit('input', []);
        this.ranges.forEach((range,key) => {
          this.ranges[key].isActive = false
        })
      },
      updateValues (values) {
        this.dateRange.startDate = moment(values.startDate).format('YYYY-MM-DD');
        this.dateRange.endDate = moment(values.endDate).format('YYYY-MM-DD');
        this.$emit('change-date-range', this.dateRange);
        const dateRangeValue = [moment(values.startDate).format('YYYY-MM-DD'), moment(values.endDate).format('YYYY-MM-DD')];
        this.$emit('input', dateRangeValue);

        // tim trong this.ranges xem có mang nao tra ve gia tri nhu dateRangeValue
        this.ranges.forEach((range,key) => {
          this.ranges[key].isActive = false
          if (this.ranges[key].duration == 'eternal') {
            return
          }
          if(this.dateRange.startDate == this.ranges[key].startDate && this.dateRange.endDate == this.ranges[key].endDate ) {
            this.dateRange.duration = this.ranges[key].duration
            this.ranges[key].isActive = true
            return
          }
        });

      },
      checkOpen (open) {
        if(open) {
          this.$emit('show-date-picker')
        }
      },
      dateFormat (classes, date) {
        let yesterday = moment().subtract(1, 'day');
        if (!classes.disabled) {
          classes.disabled = moment(date).isSame(yesterday, 'day')
        }
        return classes
      },
      setRange (duration) {
        this.duration = duration
        for(let i = 0; i <= this.ranges.length - 1; i++) {
          this.ranges[i].isActive = false
          if(duration === this.ranges[i].duration) {
            this.ranges[i].isActive = true
            this.dateRange.duration = duration
            this.dateRange.startDate = this.ranges[i].startDate
            this.dateRange.endDate = this.ranges[i].endDate
            this.$emit('change-date-range', this.dateRange);
            let dateRangeValue = [moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')];
            if (duration == 'eternal') {
              dateRangeValue = ['eternal', 'eternal']
            }
            this.$emit('input', dateRangeValue);
            this.$refs.picker.togglePicker(false, true)
          }
        }
      },
    }
  }
</script>
