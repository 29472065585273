import DashboardLayout from '../layouts/DashboardLayout'
// import CustomerSupport from '../pages/support/CustomerSupport'
// import CustomerSupportMobile from '../pages/support/CustomerSupportMobile'

export const supportMenu = {
    path: '/customer-support',
    component: DashboardLayout,
    name: 'CustomerSupport',
    children: [
        {
            path: '/customer-support',
            component: () => import (/* webpackChunkName: "about" */'../pages/support/CustomerSupport.vue'),
            meta: {
                roles: ['contact_read', 'dashboard_read'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Hỗ trợ khách hàng'
                }]
            },
        },
        {
            path: '/mobile/customer-support',
            component: () => import (/* webpackChunkName: "about" */'../pages/support/CustomerSupportMobile.vue'),
            meta: {
                roles: ['contact_read', 'dashboard_read'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Hỗ trợ khách hàng'
                }]
            },
        }
    ],
}