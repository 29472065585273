<template>
  <v-dialog v-model="showModal" max-width="1000" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">{{ errorMessage }}</v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">Thêm mới thành viên</template>
          <template v-else>Cập nhật thông tin thành viên</template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12" md="4">
              <div class="upload-image upload-image--user">
                <v-tooltip bottom v-if="_.get(user, 'avatar.id', null)">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="delete-img"
                      @click="deleteImage"
                      icon
                      small
                      color="default"
                      v-on="on"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                  <span>Xóa ảnh đại diện</span>
                </v-tooltip>
                <img
                  v-if="_.get(user, 'avatar.id', null)"
                  :src="_.get(user, 'avatar.filePath')"
                  @click="chooseImage"
                  style="width: 100%"
                />
                <v-img
                  v-else
                  src="@/assets/default-avatar.jpg"
                  @click="chooseImage"
                />
                <input
                  ref="fileInput"
                  style="display: none"
                  type="file"
                  accept="image/*"
                  @input="onSelectFile"
                />
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    :rules="{
                      required: true,
                      max: 50,
                      /* regex: regex.isUsername, */
                    }"
                    name="Tên đăng nhập"
                  >
                    <v-text-field
                      v-model="user.username"
                      label="Tên đăng nhập *"
                      :error-messages="errors"
                      color="primary"
                      :readonly="!isCreate"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    name="Số điện thoại"
                    :rules="{ regex: regex.isVNPhoneMobile, required: true }"
                  >
                    <v-text-field
                      v-model="user.phone"
                      label="Số điện thoại *"
                      :error-messages="errors"
                      color="primary"
                      type="number"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    rules="required|max:255"
                    name="Họ và tên đệm"
                  >
                    <v-text-field
                      v-model="user.lastName"
                      label="Họ và tên đệm *"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    rules="required|max:50"
                    name="Tên"
                  >
                    <v-text-field
                      v-model="user.firstName"
                      label="Tên *"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>
                <v-col v-if="isCreate" cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      min: 6,
                      regex: regex.isPassword,
                      max: 20,
                    }"
                    name="Mật khẩu"
                    vid="password"
                  >
                    <v-text-field
                      v-model="user.password"
                      label="Mật khẩu *"
                      :error-messages="errors"
                      color="primary"
                      :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (visiblePassword = !visiblePassword)"
                      :type="visiblePassword ? 'password' : 'text'"
                    />
                  </validation-provider>
                </v-col>
                <v-col v-if="isCreate" cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nhập lại mật khẩu"
                    rules="required|confirmed:password"
                  >
                    <v-text-field
                      v-model="user.confirm"
                      label="Xác nhận lại mật khẩu *"
                      :error-messages="errors"
                      color="primary"
                      :append-icon="
                        visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="
                        () => (visibleConfirmPassword = !visibleConfirmPassword)
                      "
                      :type="visibleConfirmPassword ? 'password' : 'text'"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    rules="required"
                    name="Giới tính"
                  >
                    <v-autocomplete
                      v-model="user.gender"
                      attach
                      :items="userGenderOptions"
                      :error-messages="errors"
                      item-text="text"
                      item-value="value"
                      label="Giới tính *"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    ref="datePicker"
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :return-value.sync="user.birthday"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="user.birthday"
                        color="primary"
                        label="Ngày sinh"
                        prepend-icon="mdi-calendar-outline"
                        readonly
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="user.birthday"
                      color="secondary"
                      scrollable
                      @change="
                        $refs.datePicker.save(user.birthday),
                          (datePicker = false)
                      "
                      :max="currentDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Email"
                :rules="{
                  max: 255,
                  email: true,
                  regex: regex.isEmail,
                }"
              >
                <v-text-field
                  v-model="user.email"
                  label="Email"
                  :error-messages="errors"
                  color="primary"
                  type="email"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Nhóm quyền"
              >
                <v-autocomplete
                  v-model="user.roleId"
                  :items="roles"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Thiết lập nhóm quyền *"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required"
                name="Chức danh"
              >
                <v-autocomplete
                  v-model="user.position"
                  :items="userPositionOptions"
                  :error-messages="errors"
                  item-text="text"
                  item-value="value"
                  label="Chức danh *"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <!-- <v-col cols="12" md="4">
              <validation-provider v-slot="{ errors }" name="Nhóm khách hàng">
                <v-autocomplete
                  v-model="user.departmentId"
                  :items="departments"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Nhóm khách hàng"
                  color="primary"
                  @change="changeDepartment(true)"
                />
              </validation-provider>
            </v-col> -->

            <!-- <v-col
              cols="12"
              md="4"
              v-if="user.position === userPositions.SALE_LEADER"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Quản lý thêm"
                class="mb-2"
              >
                <v-autocomplete
                  v-model="user.listdepartmentId"
                  :items="departmentsForSaleLeader"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Quản lý thêm *"
                  color="primary"
                  multiple
                  class="selectAll-group-department"
                  clearable
                  :disabled="!user.departmentId"
                  eager
                  @change="getProductGroupByDepartment()"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="user.listdepartmentId"
                      :item="departmentsForSaleLeader"
                      :items="departmentsForSaleLeader.map((s) => s.id)"
                    ></common-select-all>
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col> -->
            <!-- <v-col
              v-if="
                user.position === userPositions.TELESALES ||
                user.position === userPositions.SALE_LEADER
              "
              cols="12"
              md="4"
            >
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Nhóm sản phẩm"
                rules="required"
              >
                <v-autocomplete
                  v-model="user.productGroups"
                  :items="productGroupsFilter"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Nhóm sản phẩm *"
                  color="primary"
                  multiple
                  class="selectAll-group-product"
                  clearable
                  :disabled="!user.departmentId"
                  eager
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="user.productGroups"
                      :item="user.productGroups"
                      :items="productGroups.map((s) => s.id)"
                    ></common-select-all>
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col> -->
            <!-- <v-col cols="12" md="4">
              <validation-provider v-slot="{ errors }" name="Call center">
                <v-autocomplete
                  v-model="user.callcenter_id"
                  :items="callCenters"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Call center *"
                  color="primary"
                  eager
                />
              </validation-provider>
            </v-col> -->
            <!-- <v-col v-if="user.position === userPositions.TELESALES || user.position === userPositions.SALE_LEADER" cols="12" md="4">
              <v-switch
                v-model="user.onAssignContact"
                label="Tự động phân bổ contact"
              />
            </v-col>
            <v-col cols="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                :rules="{
                  max: 10,
                  regex: regex.isNumber
                }"
                name="Hạn mức phân bổ"
              >
                <v-text-field
                  v-model.trim="user.number_of_contact"
                  label="Hạn mức phân bổ"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col> -->
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveUser)"
            >Lưu lại</v-btn
          >
          <v-spacer />

          <v-btn color="primary" @click="hideModal">Hủy bỏ</v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { formatDate } from "../../../helpers/utils";
import {
  regex,
  userPositionOptions,
  userGenderOptions,
  userPositions,
} from "../../../helpers/constants";
const UserRepository = RepositoryFactory.get("user");
const RoleRepository = RepositoryFactory.get("role");
const ImageRepository = RepositoryFactory.get("image");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    departments: {
      default: () => [],
    },
  },
  data() {
    return {
      datePicker: false,
      regex,
      showModal: false,
      user: {
        productGroups: [],
        onAssignContact: false,
        listdepartmentId: [],
      },
      isManager: false,
      isCreate: true,
      roles: [],
      productGroups: [],
      userPositionOptions,
      userPositions,
      userGenderOptions,
      errorMessage: "",
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Kích thước hình đại diện phải nhỏ hơn 2 MB!",
      ],
      visibleExtPassword: true,
      visibleConfirmPassword: true,
      visiblePassword: true,
    };
  },
  created() {
    this.getRoleList();
    Bus.$on("user-show-user-form", (user) => {
      this.user = _.cloneDeep(user);
      this.user.birthday = formatDate(user.birthday);
      if (user.id) this.isCreate = false;
      else this.isCreate = true;
      this.showModal = true;
    });
  },
  computed: {
    currentDate() {
      const d = new Date();
      return d.toISOString().slice(0, 10);
    },
  },
  watch: {},
  methods: {
    deleteImage() {
      this.$set(this.user, "avatar", null);
      this.$set(this.user, "avatarId", null);
    },
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },

    async getRoleList() {
      const response = await RoleRepository.list({ limit: 1000 });
      if (_.get(response, "success", false)) {
        this.roles = _.get(response, "data");
      }
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    async onSelectFile() {
      const loader = this.$loading.show();
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files.length) {
        await this.uploadOneFile(files[0]);
      }
      loader.hide();
    },
    async uploadOneFile(file) {
      const uploadResponse = await ImageRepository.upload(file);
      if (_.get(uploadResponse, "success", false)) {
        const newImage = _.get(uploadResponse, "data", {});
        const avatar = {
          id: _.get(newImage, "id", null),
          filePath: _.get(newImage, "filePath", ""),
        };
        this.$set(this.user, "avatar", avatar);
        this.$set(this.user, "avatarId", avatar.id);
      } else {
        this.$swal("Upload ảnh đại diện không thành công");
      }
    },
    async saveUser() {
      if (this.isCreate) {
        let user = {
          username: _.trim(this.user.username),
          password: this.user.password,
          firstName: _.trim(this.user.firstName),
          lastName: _.trim(this.user.lastName),
          position: this.user.position,
          email: this.user.email,
          phone: this.user.phone,
          roleId: this.user.roleId,
          departmentId: this.user.departmentId,
          gender: this.user.gender,
          birthday: this.user.birthday,
          avatarId: this.user.avatarId,
          listDeparment: this.user.listdepartmentId,
        };
        const loader = this.$loading.show();
        const response = await UserRepository.create(user);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm người dùng thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("user-list-reload");
          });
        }
      } else {
        const user = {
          firstName: _.trim(this.user.firstName),
          lastName: _.trim(this.user.lastName),
          position: this.user.position,
          email: this.user.email,
          phone: this.user.phone,
          roleId: this.user.roleId,
          departmentId: this.user.departmentId,
          gender: this.user.gender,
          birthday: this.user.birthday,
          avatarId: this.user.avatarId,
          listDeparment: this.user.listdepartmentId,
        };
        const loader = this.$loading.show();
        const response = await UserRepository.update(this.user.id, user);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật người dùng thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            // Bus.$emit('user-list-reload')
            Bus.$emit("user-list-reload");
          });
        }
      }
    },
  },
};
</script>
