import DashboardLayout from '../layouts/DashboardLayout'

export const productMenu = {
  path: '/products',
  component: DashboardLayout,
  name: 'Products',
  redirect: '/products',
  roles: ['product_read', 'product_create', 'product_update', 'product_delete'],
  children: [
    {
      path: 'product-list',
      name: 'ProductList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/product/ProductList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách sản phẩm',
        }],
        roles: ['product_read', 'product_create', 'product_update', 'product_delete'],
      },
    },
    {
      path: '/products/product-list/edit/:id',
      name: 'EditProduct',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/product/components/ProductForm.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách sản phẩm',
          to: '/products/product-list',
        }, {
          title: 'Chỉnh sửa sản phẩm',
          to: '',
        }],
        roles: ['product_update'],
      },
    },
    {
      path: 'create-product',
      name: 'CreateProduct',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/product/components/ProductForm.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách sản phẩm',
          to: '/products/product-list',
        }, {
          title: 'Tạo sản phẩm',
          to: '',
        }],
        roles: ['product_create'],
      },
    },
    {
      path: 'product-category',
      name: 'ProductGroupList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/product-group/ProductGroupList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách nhóm sản phẩm'
        }],
        roles: ['product_read', 'product_create', 'product_update', 'product_delete'],
      },
    },
    {
      path: 'product-folder',
      name: 'ProductFolderList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/product-folder/ProductFolderList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách loại sản phẩm'
        }],
        roles: ['product_folder_read', 'product_folder_create', 'product_folder_update', 'product_folder_delete'],
      },
    },
    {
      path: 'product-manufacture',
      name: 'ManufactureList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/manufacture/ManufactureList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách nhà sản xuất'
        }],
        roles: ['product_manufacture_read', 'product_manufacture_create', 'product_manufacture_update', 'product_manufacture_delete'],
      },
    },
    {
      path: 'product-utility',
      name: 'UtilityList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/utility/UtilityList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách nhóm công dụng'
        }],
        roles: ['product_utility_read', 'product_utility_create', 'product_utility_update', 'product_utility_delete'],
      },
    },
    {
      path: 'product-treatment',
      name: 'TreatmentList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/treatment/TreatmentList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách nhóm hỗ trợ điều trị'
        }],
        roles: ['product_treatment_read', 'product_treatment_create', 'product_treatment_update', 'product_treatment_delete'],
      },
    },
    {
      path: 'product-pathology',
      name: 'PathologyList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/pathology/PathologyList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách nhóm dược lý'
        }],
        roles: ['product_pathology_read', 'product_pathology_create', 'product_pathology_update', 'product_pathology_delete'],
      },
    },
    {
      path: 'product-unit',
      name: 'UnitList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/unit/UnitList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Danh sách đơn vị tính'
        }],
        roles: ['product_unit_read', 'product_unit_create', 'product_unit_update', 'product_unit_delete'],
      },
    },
    {
      path: 'combo-list',
      name: 'ComboList',
      component: () => import(/* webpackChunkName: "about" */'../pages/products/combo/ComboList.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [{
          title: 'Combo Sản Phẩm',
          to: '',
        }],
        roles: ['product_read', 'product_create', 'product_update', 'product_delete'],
      },
    },
  ],
}