import TokenService from '@/helpers/token'
import Repository from './Repository'

const resource = `${Repository.defaults.baseURL}/notification`

export default {
  async list(params) {
    const response = await Repository.get(`${resource}/all`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async create(data) {
    const response = await Repository.post(`${resource}`, data, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update(id, params) {
    const response = await Repository.put(`${resource}/${id}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteOneItem(id) {
    const response = await Repository.delete(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
