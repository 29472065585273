<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
      <v-list-item-title class="headline mb-2 text-center">
        Không có quyền truy cập
          <br/>
          <v-btn
                  color=""
                  class="success--text mt-2"
                  @click="returnToHome()"
          >
              Trang chủ
          </v-btn>
      </v-list-item-title>

  </v-container>
</template>

<script>
import TokenService from '@/helpers/token'
export default {
    methods: {
      returnToHome() {
        let permissions = TokenService.getPermissions()
        if (permissions.includes('dashboard_read')) {
          this.$router.push('/dashboard')
        } else {
          this.$router.push('/dashboard')
        }
      }
    }
}
</script>
