import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/image`

export default {
  async upload (file) {
    const bodyFormData = new FormData()
    bodyFormData.append('file', file)
    const response = await Repository.post(`${resource}`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    })
    return response
  },
  async uploads (files) {
    const bodyFormData = new FormData()
    let file
    for (let i = 0; i < files.length; i++) {
      file = files[i]
      // bodyFormData.append('files[' + i + ']', file)
      bodyFormData.append('files', file)
    }
    const response = await Repository.post(`${resource}/uploads`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    })
    return response
  },
}
