import DashboardLayout from '../layouts/DashboardLayout'

export const contactWorkMenu = {
    path: '/contact-us',
    component: DashboardLayout,
    name: 'ContactWork',
    redirect: '/contact-us',
    children: [
        {
            path: '/',
            name: 'ContactWorkList',
            component: () => import(/* webpackChunkName: "about" */'../pages/contact-work/ContactWorkList.vue'),
            meta: {
                roles: ['contact_work_read', 'contact_work_create', 'contact_work_update', 'contact_work_delete', 'contact_work_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Danh sách liên hệ',
                }]
            },
        },
    ],
}
