import TokenService from '@/helpers/token'
import Repository from './Repository'

const baseUrl = `${Repository.defaults.baseURL}/product`

export default {
  async list (params) {
    const response = await Repository.get(`${baseUrl}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async listAll (params) {
    const response = await Repository.get(`${baseUrl}/list/all`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteOneItem (id) {
    const response = await Repository.delete(`${baseUrl}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async deleteManyItems (ids) {
    const response = await Repository.delete(`${baseUrl}/`, {
      headers: TokenService.getHeader(),
      data: {
        ids,
      },
    })
    return response
  },

  async getDetails (id) {
    const response = await Repository.get(`${baseUrl}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update (id, params) {
    const response = await Repository.patch(`${baseUrl}/${id}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async create (params) {
    const response = await Repository.post(`${baseUrl}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async updateStatus (id, params) {
    const response = await Repository.patch(`${baseUrl}/${id}/status`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  async changeEndDateManyItems(params) {
    const response = await Repository.patch(`${baseUrl}/endDate`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  }
}
