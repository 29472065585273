import Vue from 'vue'
import TokenService from '../helpers/token'

Vue.mixin({
  methods: {
    checkAuthorization (role) {
      const roles = TokenService.getPermissions();
      return !!roles.includes(role);
    },
    showInSidebar (roles) {
      const userPermissions = TokenService.getPermissions();
      return roles === 'all' || !!_.intersection(roles, userPermissions).length;
      // return true;
    },
    // checkConditionT4NT5 (type) {
    //   if (['T4', 'T5', 'C4', 'C5'].includes(type)) {
    //     if (this.checkAuthorization('contact_move_to_cc_read')) {
    //       return true
    //     } 
    //     return false
    //   }
    //   return true
    // },
    // checkIncludesT4NT5 (type) {
    //   if (['T4', 'T5', 'C4', 'C5'].includes(type)) {
    //     return true
    //   }
    //   return false
    // }
  },
})
