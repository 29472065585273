var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-datepicker-range mt-6"},[_c('label',[_vm._v(_vm._s(_vm.label))]),_c('date-range-picker',{ref:"picker",attrs:{"opens":_vm.opens,"locale-data":{
      direction: 'ltr',
      format: 'yyyy-mm-dd',
      separator: ' - ',
      applyLabel: 'Đồng ý',
      cancelLabel: 'Hủy',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      firstDay: 0,
    },"minDate":_vm.minDate,"maxDate":_vm.maxDate,"singleDatePicker":_vm.singleDatePicker,"timePicker":_vm.timePicker,"timePicker24Hour":_vm.timePicker24Hour,"showWeekNumbers":_vm.showWeekNumbers,"showDropdowns":_vm.showDropdowns,"autoApply":_vm.autoApply,"linkedCalendars":_vm.linkedCalendars,"always-show-calendars":false,"alwaysShowCalendars":_vm.alwaysShowCalendars,"disabled":_vm.readonly,"hide-details":_vm.hideDetails},on:{"update":_vm.updateValues,"toggle":_vm.checkOpen},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('template',{slot:"ranges"},[_c('div',{staticClass:"ranges col-12 col-md-auto col-sm-auto"},[_c('ul',_vm._l((_vm.ranges),function(range){return _c('li',{key:range.duration,class:{'active':(range.isActive == true)},on:{"click":function($event){return _vm.setRange(range.duration)}}},[_vm._v(" "+_vm._s(range.text)+" ")])}),0)])]),_c('template',{slot:"input"},[(_vm.dateRange.startDate || _vm.duration == 'eternal')?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[(_vm.duration == 'eternal')?_c('span',[_vm._v("Trọn đời")]):_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.dateRange.startDate))+" ~ "+_vm._s(_vm.formatDate(_vm.dateRange.endDate))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.clearable),expression:"clearable"}]},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":_vm.onClickRemove}},[_vm._v("mdi-close")])],1)]):_c('div',[_c('span',[_vm._v(" - ")])])])],2),_c('input',{staticStyle:{"display":"none","height":"0px","width":"0px"},domProps:{"value":_vm.dateRangeValue}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }