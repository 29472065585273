import TokenService from '@/helpers/token'
import Repository from './Repository'

const baseUrl = `${Repository.defaults.baseURL}/behavior`

export default {
  async list(params) {
    const response = await Repository.get(`${baseUrl}/log/follow`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
}
