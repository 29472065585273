import TokenService from '@/helpers/token'
import Repository from './Repository'

const baseUrl = `${Repository.defaults.baseURL}/promotion-program`

export default {
    async list (params) {
        const response = await Repository.get(`${baseUrl}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async listAll (params) {
        const response = await Repository.get(`${baseUrl}/list/all`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },

    async deleteOneItem (id) {
        const response = await Repository.delete(`${baseUrl}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async getDetails (id) {
        const response = await Repository.get(`${baseUrl}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async update (id, params) {
        const response = await Repository.put(`${baseUrl}/${id}`, params, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async create (params) {
        const response = await Repository.post(`${baseUrl}`, params, {
            headers: TokenService.getHeader(),
        })
        return response
    }
}
