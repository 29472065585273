import DashboardLayout from '../layouts/DashboardLayout'

export const websiteMenu = {
    path: '/setting-website',
    component: DashboardLayout,
    name: 'Website',
    redirect: '/setting-website',
    roles: ['setting_website_read','setting_website_create','setting_website_update','setting_website_delete'],
    children: [
        {
            path: 'price-filter',
            name: 'PriceList',
            component: () => import(/* webpackChunkName: "about" */'../pages/price-filter/PriceList.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Lọc theo giá',
                }],
                roles: ['filter_price_read','filter_price_create','filter_price_update','filter_price_delete'],
            },
        },
        {
            path: 'percent-discount-filter',
            name: 'PercentDiscountList',
            component: () => import(/* webpackChunkName: "about" */'../pages/percent-discount-filter/PercentDiscountList.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Lọc theo chiết khấu',
                }],
                roles: ['filter_percent_discount_read','filter_percent_discount_create','filter_percent_discount_update','filter_percent_discount_delete'],
            },
        },
        {
            path: 'partner',
            name: 'PartnerList',
            component: () => import(/* webpackChunkName: "about" */'../pages/partner/PartnerList.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Đối tác',
                }],
                roles: ['partner_read','partner_create','partner_update','partner_delete'],
            },
        },
        {
            path: 'evaluation',
            name: 'EvaluationList',
            component: () => import(/* webpackChunkName: "about" */'../pages/evaluation/EvaluationList.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Cảm nhận khách hàng',
                }],
                roles: ['evaluation_read','evaluation_create','evaluation_update','evaluation_delete'],
            },
        },
    ],
}