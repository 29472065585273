import DashboardLayout from '../layouts/DashboardLayout';
// import SmsList from '../pages/sms/sms-list/SmsList';
// import SmsHistory from '../pages/sms/sms-history/SmsHistory';

export const smsMenu = {
    path: '/sms',
    component: DashboardLayout,
    name: 'SmsList',
    children: [
        {
            path: 'sms-list',
            component: () => import (/* webpackChunkName: "about" */'../pages/sms/sms-list/SmsList.vue'),
            meta: {
                roles: ['mkt-sms-customer_read'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Sms Maketing'
                }]
            },
        },
        {
            path: 'sms-history',
            component: () => import (/* webpackChunkName: "about" */'../pages/sms/sms-history/SmsHistory.vue'),
            meta: {
                roles: ['mkt-sms-message-history_read'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Lịch sử gửi tin nhắn'
                }]
            }
        }
    ],
}