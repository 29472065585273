import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/department`

export default {
    async list (params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async listUsers (params) {
        const response = await Repository.get(`${resource}/list/users`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async detail (id) {
        const response = await Repository.get(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async create (department) {
        const response = await Repository.post(`${resource}`, department, {
            headers: TokenService.getHeader(),
        })
        return response
    },

    async update (id, department) {
        const response = await Repository.patch(`${resource}/${id}`, department, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async delete (id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },
}
