var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v(" Thêm mới nhóm khách hàng ")]:[_vm._v(" Cập nhật thông tin nhóm khách hàng ")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v(" mdi-close ")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:255","name":"Tên nhóm khách hàng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên nhóm khách hàng *","error-messages":errors,"color":"primary"},model:{value:(_vm.department.name),callback:function ($$v) {_vm.$set(_vm.department, "name", $$v)},expression:"department.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required","name":"Loại nhóm khách hàng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.departmentType,"item-text":"label","item-value":"value","label":"Loại nhóm khách hàng *","error-messages":errors,"color":"primary"},model:{value:(_vm.department.type),callback:function ($$v) {_vm.$set(_vm.department, "type", $$v)},expression:"department.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Mô tả","rules":"max: 255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Mô tả","error-messages":errors,"color":"primary"},model:{value:(_vm.department.description),callback:function ($$v) {_vm.$set(_vm.department, "description", $$v)},expression:"department.description"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveDepartment)}}},[_vm._v(" Lưu lại ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy bỏ ")])],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }