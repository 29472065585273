<template>
  <span>
    <v-autocomplete
      v-model="valueYearTemp"
      :items="selectYears"
      :label="label"
      :placeholder="placeholder"
      :multiple="multiple"
      :clearable="clearable"
      @change="onChangeYear"
      :error-messages="errorMessages"
      :readonly="readonly"
      :hide-details="hideDetails"
    >
      <template slot="label">
        <div>{{label}} <span style="color: red" v-if="required">*</span></div>
      </template>
    </v-autocomplete>
    <input style="display:none; height: 0px; width: 0px;" v-bind:value="valueYear" />
  </span>
</template>

<script>
export default {
  name: 'SelectYear',
  model: {
    prop: 'valueYear'
  },
  props: {
    label: {
      type: String,
      default: 'Năm'
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dateRangeValue: {
      type: Array,
      default: () => []
    },
    valueYear: {
      type: [String, Number, Array],
      default: () => ''
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectYears: [],
      valueYearTemp: ''
    };
  },
  created() {
    this.valueYearTemp = this.valueYear;
    const selectYears = [];
    const yearNow = new Date().getFullYear();
    for (let i = yearNow; i >= 1900; i--) {
      selectYears.push(i);
    }
    this.selectYears = selectYears;
  },
  methods: {
    onChangeYear() {
      this.$emit('input', this.valueYearTemp);
    }
  }
};
</script>
