import Vue from 'vue'
import VueRouter from 'vue-router'
import TokenService from '../helpers/token'
import { updateProfileInto, checkMobile } from '../helpers/utils'
import RepositoryFactory from '../repositories/RepositoryFactory'
import AuthLayout from '../layouts/AuthLayout'

// pages
import Error403 from '../pages/Error403'
import Login from '../pages/Login'

const AuthRepository = RepositoryFactory.get('auth')

const { userMenu } = require('./user')

const { productMenu } = require('./product')

// const { contactMenu, getBreadcrumb } = require('./contact')

// const { CallCenterMenu } = require('./callCenter')

const { profileMenu } = require('./profile')

const { reportMenu } = require('./report')

const { settingMenu } = require('./setting')

const { dataSetting } = require('./dataSetting')

const { orderMenu } = require('./order')

const { supportMenu } = require('./support')

const { dashboardMenu } = require('./dashboard')

const { smsMenu } = require('./sms')

// const { reportThptMenu } = require('./reportThpt')

const { storeMenu } = require('./store')

// const { trainingMenu } = require('./training')

const { newsMenu } = require('./news')

const { bannerMenu } = require('./banner')

const { discountMenu } = require('./discount')

const { contactWorkMenu } = require('./contactWork')

const { informationMenu } = require('./information')

const { websiteMenu } = require('./website')

const { promotionsMenu } = require('./promotions')

const { orderHistoryMenu } = require('./orderHistory')

const { behaviorHistoryMenu } = require('./behaviorHistory')

const permission = TokenService.getPermissions();

let isDashboardRole = permission.includes("dashboard_read");

const authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        onlyWhenLoggedOut: true,
        public: true,
      },
    },
    {
      path: '/error-403',
      name: 'Error403',
      component: Error403,
      meta: {
        layout: 'login',
        public: true,
      },
    },
  ],
}

const routes = [
  {
    path: '/',
    // redirect: isDashboardRole ? '/dashboard' : '/contact/contact-list',
    redirect: isDashboardRole ? '/dashboard' : '/dashboard',
    name: 'Home',
  },
  authPages,
  profileMenu,
  productMenu,
  userMenu,
  // contactMenu,
  // CallCenterMenu,
  orderMenu,
  reportMenu,
  dataSetting,
  settingMenu,
  supportMenu,
  dashboardMenu,
  smsMenu,
  // reportThptMenu,
  storeMenu,
  // trainingMenu,
  newsMenu,
  bannerMenu,
  discountMenu,
  contactWorkMenu,
  informationMenu,
  websiteMenu,
  promotionsMenu,
  orderHistoryMenu,
  behaviorHistoryMenu,
]
Vue.use(VueRouter)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
  linkExactActiveClass: 'nav-item active',
})

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from, next) => {
  const isPublic = _.get(to.meta, 'public', false)
  const onlyWhenLoggedOut = _.get(to.meta, 'onlyWhenLoggedOut', false)
  const loggedIn = !!TokenService.getToken()
  const isMobile = checkMobile()

  // if (loggedIn && isMobile && to.fullPath == '/orders/order-list') {
  //   return next('/mobile/orders/order-list')
  // }

  // if (loggedIn && isMobile && to.fullPath == '/customer-support') {
  //   return next('/mobile/customer-support')
  // }

  // if (loggedIn && isMobile && to.fullPath == '/orders/' + to.params.id + '/account') {
  //   return next('/mobile' + to.fullPath)
  // }

  // if (loggedIn && isMobile && to.fullPath == '/profile') {
  //   return next('/mobile/profile')
  // }

  // if ((to.fullPath == '/report/employee-report-' + to.params.id) && TokenService.getUser().departmentId != to.params.id && !isNaN(parseInt(to.params.id))) {
  //   return next('/error-403')
  // }

  if (isPublic) next()
  if (!isPublic && !loggedIn) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit entry page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/dashboard')
  }

  // xu ly breadcrumb
  if (to.name === 'OrderCreate' || to.name === 'ContactHistory') {
    to.meta.breadcrumb = getBreadcrumb(to)
  }

  // check role of user
  if (loggedIn) {
    // get user's profile
    const profile = await AuthRepository.profile()
    updateProfileInto(_.get(profile, 'data'))
    // check permission
    const metaRole = _.get(to.meta, 'roles', []);
    const userPermissions = TokenService.getPermissions();

    // Do not allow user to visit entry page if they are logged in or has not according role
    if (metaRole === 'all') {
      next();
    } else if (!_.intersection(metaRole, userPermissions).length) {
      return next('/error-403');
    }
  }
  next()
})

export default router
