<template>
    <div>
        <div v-for="(item, keyItem) in listSession_call" :key="keyItem">
            <div
                    v-if="item.showModal && position_modal.indexOf(keyItem) > -1"
                    :class="'s-popup-incoming pos-' + position_modal.indexOf(keyItem)"
                    :style="{ top: 'calc(81vh - ' + (position_modal.indexOf(keyItem) + isCallOut)*120 + 'px)', 
                            width: '360px', 
                            right: '20px'}"
                    
            >
                <v-card class="text-center popup-call">
                    <v-card-title class="font-weight-bold mb-0 d-flex justify-space-between">
                        <h3 style="font-size: 14px">Cuộc gọi
                          <span v-show="item.showTimer">{{ minutes }} : {{ seconds }}</span>
                          <span v-show="item.isFinish">{{ item.timer }}</span>
                        </h3>
                        <v-btn
                                v-if="!item.showEndCall"
                                color="default"
                                @click="closeModal(keyItem)"
                                class="text-right"
                                icon
                                small
                        >
                            <v-icon aria-label="Close">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <template v-if="item.errorMessage">
                        <v-alert
                                class="mx-4 pa-3 mb-0"
                                type="error"
                        >
                        {{ item.errorMessage }}
                        </v-alert>
                    </template>
                    <v-card-text class="px-6 pt-0 pb-3">
                        <div class="row align-center">
                            <div class="col-md-9 text-left py-0 pr-0">
                                <div class="d-flex">
                                    <label class="font-weight-bold"> Số điện thoại :</label> <strong class="pl-1" :id="'contact-mobile-' + keyItem">{{ item.mobile }}</strong>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                size="16"
                                                width="20"
                                                height="20"
                                                class="mr-1"
                                                icon
                                                small
                                                color="success"
                                                v-on="on"
                                                @click="copyContactMobile(keyItem)"
                                            >
                                                <v-icon size="16">mdi-content-copy</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Copy SĐT</span>
                                    </v-tooltip>
                                </div>
                                <div class="d-flex">
                                    <label class="font-weight-bold" style="min-width: 75px">Trạng thái :</label>
                                    <span>{{ item.callStatus }}</span>
                                </div>
                            </div>
                            <div class="col-md-3 py-0 pr-0">
                                <!-- <v-btn class="btn-voice-call ma-0" color="success" fab medium @click="executeCall" v-if="callAgain">
                                    Gọi
                                </v-btn> -->
                                <v-btn class="btn-voice-call ma-0" color="primary" fab medium @click="endSession(keyItem)" v-if="item.callWait">
                                    Kết thúc
                                </v-btn>
                                <v-btn class="btn-voice-call ma-0" color="primary" fab medium @click="endCall(keyItem)" v-if="item.showEndCall">
                                    Kết thúc
                                </v-btn>
                                <v-btn class="btn-voice-call ma-0" color="success" fab medium @click="acceptCall(keyItem)" v-if="item.confirmAnswer">
                                    Trả lời
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>

                    <v-dialog
                        v-if="item.showConfirm"
                        v-model="item.showConfirm"
                        persistent
                        max-width="525"
                    >
                        <common-confirm-call
                            :showCreateAppointment="showCreateAppointment"
                            :mobile="item.mobile"
                            :dataAppointment="dataAppointment"
                        />
                    </v-dialog>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    // import JsSIP from '../../assets/js/jssip-3.3.11.min.js'
    import JsSIP from 'jssip'
    import TokenService from "../../helpers/token";
    import RepositoryFactory from '../../repositories/RepositoryFactory'
    const ContactRepository = RepositoryFactory.get('contact')
    const CallRepository = RepositoryFactory.get('call_center')
    import axios from 'axios'

    export default {
        name: 'VoiceCall',
        data() {
            return {
                showModal: false,
                callError: false,
                session: false,
                configuration: {},
                callPhone: null,
                callStatus: null,
                showEndCall: false,
                callAgain: false,
                isSetting: false,
                callSuccessEnd: false,
                callWait: false,
                errorMessage: '',
                showConfirm: false,
                mobile: '',
                dataAppointment: {},
                appointmentId: null,
                showCreateAppointment: true,
                timer: '',
                minutes: '00',
                seconds: '00',
                showTimer: false,
                contactId: '',
                incomingCallStatusCareSoft: '',
                statusChange: '',
                callcenter_id: 0,
                session_call: false,
                confirmAnswer: false,
                isInCall: false,
                listSession_call: [],
                position_modal: [],
                indexCall:''
            }
        },
        computed: {
            isCallOut() {
                return this.$store.state.isCallOut
            }
        },
      watch: {
        incomingCallStatusCareSoft: {
          deep: true,
          handler(newVal) {
            console.log('incomingCallStatusCareSoft val ' + newVal)
            if (newVal == 'Call ringing') {
              this.listSession_call[this.indexCall].callStatus = 'Bắt đầu gọi ' + this.mobile + ', đợi trả lời!'
              this.listSession_call[this.indexCall].callWait = true
            } else if (newVal == 'Accept call') {
              this.createTimer()
              this.listSession_call[this.indexCall].callStatus = 'Đang bắt đầu cuộc gọi'
              this.listSession_call[this.indexCall].showEndCall = true
              this.listSession_call[this.indexCall].callSuccessEnd = true
              this.listSession_call[this.indexCall].callWait = false
              this.listSession_call[this.indexCall].showTimer = true
            } else if (newVal == 'End call') {
              let closed = true
              if (this.listSession_call[this.indexCall].callWait) {
                closed = false
              }
              clearInterval(this.timer)
              clearInterval(this.listSession_call[this.indexCall].statusChange)
              if (this.callSuccessEnd && this.appointmentId) {
                this.listSession_call[this.indexCall].callSuccessEnd = false
                this.listSession_call[this.indexCall].callWait = false
                this.listSession_call[this.indexCall].showConfirm = false
              }
              this.listSession_call[this.indexCall].callStatus = "Kết thúc cuộc gọi"
              this.listSession_call[this.indexCall].callAgain = false
              this.listSession_call[this.indexCall].showEndCall = false
              this.listSession_call[this.indexCall].callWait = false
              this.listSession_call[this.indexCall].confirmAnswer = false
              if (this.minutes > '00' || this.seconds > '00') {
                this.listSession_call[this.indexCall].timer = this.minutes + ' : ' + this.seconds
                this.listSession_call[this.indexCall].isFinish = true
                this.listSession_call[this.indexCall].showTimer = false
                this.minutes = '00'
                this.seconds = '00'
              }

            } else if (newVal == 'error') {
              this.listSession_call[this.indexCall].errorMessage = "Cuộc gọi lỗi"
              this.listSession_call[this.indexCall].showEndCall = false
              this.listSession_call[this.indexCall].callSuccessEnd = false
              this.listSession_call[this.indexCall].callWait = false
            } else if (newVal == 'failed') {
              this.listSession_call[this.indexCall].callAgain = true
              this.listSession_call[this.indexCall].callWait = false
              this.listSession_call[this.indexCall].showEndCall = false
              this.listSession_call[this.indexCall].callStatus = "Máy bận"
              csVoice.isCallout = false
              if (this.contactId) {
                ContactRepository.additionToNumberOfCall(this.contactId, this.store)
                ContactRepository.createContactHistory({
                  action: 'APPOINTMENT',
                  description: 'Thực hiện cuộc gọi lỡ.',
                  contactId: this.contactId
                }, this.contactId)
              }
            }
          }
        }
      },
        async created() {
          Bus.$on('incoming-call-cs', (phone) => {
            console.log('incoming-call-cs', phone);
            this.mobile = phone
            this.callcenter_id = 2
            this.incomingCallCareSoft()
          })
          // window.addEventListener('scroll', this.scrollModalCall);
          // window.addEventListener('beforeunload', this.endCall)
        },
        beforeDestroy () {
            this.endCall()
            Bus.$off('close-popup-confirm')
            window.removeEventListener('scroll', this.scrollModalCall);
        },

        methods: {
          scrollModalCall(event) {
              let defaultPopups = document.getElementsByClassName('s-popup-incoming')
              let distance = window.scrollY + 'px'
              if (defaultPopups.length > 0) {
                  defaultPopups.forEach(element => {
                      element.style.top = 'calc(81vh + ' + distance + ' - ' + (element.className.substr(-1) + this.isCallOut)*120 + 'px)'
                  })
              }
          },
          initData() {
              this.callError = false,
              this.callAgain = false
          },
          closeConfirm() {
              this.showConfirm = false
          },
          closeModal (index) {
              // this.showPopupCall = false
              try {
                  console.log('pause sound');
                  let sound = document.getElementById('incomingCallAudioIncoming')
                  if (sound) {
                      sound.pause()
                      sound.remove()
                  }
                  this.listSession_call[index].showTimer = false
                  let key = this.position_modal.indexOf(index)
                  this.position_modal.splice(key, 1)
                  this.minutes = '00'
                  this.seconds = '00'
                  this.endSession(index)
              }
              catch(e) {
                  console.log(e)
              }
              finally {
                  this.listSession_call[index].showModal = false;
                  this.listSession_call[index].mobile = '';
                  // if (this.callcenter_id == 1) {
                  //     this.incomingCall()
                  // }
              }
          },
          endSession(index) {
                  endCall(index)
          },
          endCall(index) {
              this.listSession_call[index].callAgain = true
              this.listSession_call[index].showEndCall = false
              this.endSession(index)
          },

          acceptCall(key) {
              if (this.$store.state.isInCall) {
                  return
              }
              let sound = document.getElementById('incomingCallAudio')
              if (sound) {
                  sound.pause()
                  sound.remove()
              }

              this.listSession_call[key].confirmAnswer = false
              onAcceptCall()
          },
          createTimer() {
              let min = 0
              let sec = 0
              let self = this
              self.timer = setInterval(function() {
                  sec++
                  if (sec < 10) {
                      self.seconds = '0' + sec
                  } else if (sec == 60) {
                      sec = 0
                      self.seconds = '00'
                      self.minutes = ++min
                  } else {
                      self.seconds = sec
                  }
              }, 1000)
          },
          copyContactMobile(index) {
              let copyText = document.getElementById("contact-mobile-" + index)
              let textArea = document.createElement("textarea")
              textArea.value = copyText.textContent
              document.body.appendChild(textArea)
              textArea.select()
              document.execCommand("Copy")
              textArea.remove()
          },
          async incomingCallCareSoft() {
            let self = this
            // let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
            window.incomingCallStatusCareSoft = ''

            this.incomingCallStatusCareSoft = ''
            this.statusChange =  setInterval(function() {
              self.incomingCallStatusCareSoft = window.incomingCallStatusCareSoft
            }, 1000)

            if (!csVoice.enableVoice) {
              csEnableCall()
            }
            let index = this.listSession_call.push({
              callAgain: false,
              showEndCall: false,
              callSuccessEnd: false,
              callWait: false,
              confirmAnswer: true,
              callStatus: 'Đang gọi đến...',
              mobile: this.mobile,
              showModal: true,
              showTimer: false,
              isFinish: false,
              timer: ''
            }) - 1
            this.indexCall = index
            this.position_modal.push(index)
          },
        }
    }
</script>
<style lang="scss">
  .btn-callcenter {
    float: right;
    margin: 0 !important;
    position: relative;
    top: -6px;
  }
  .float-right {
    float: right;
  }
  .v-card__actions {
    display: block;
    padding-bottom: 20px;
    overflow: hidden;
  }
  .v-card {
    overflow: hidden;
  }
  .popup-call {
    border: 1px solid #A9A9A9 !important;
  }
</style>
