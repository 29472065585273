<template>
  <v-expansion-panels
          class="mb-4"
  >
    <v-expansion-panel>
      <v-expansion-panel-header> Tìm kiếm theo danh sách quyền</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card
                class="ma-0"
                outlined
        >
          <v-list-item class="pa-0" one-line>
            <v-list-item-content class="pa-0">
              <!--        <v-list-item-title class="headline mb-2">-->
              <!--          Tìm Kiếm-->
              <!--        </v-list-item-title>-->
              <v-row>
                <v-col
                        cols="12"
                        sm="12"
                >
                  <v-text-field
                          v-model="filterForm.keyword"
                          label="Tìm kiếm theo tên hoặc mô tả"
                          clearable
                          @keyup.enter="searchRole"
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn
                        class="primary mx-0"
                        @click="searchRole"
                >
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
  export default {
    name: 'FilterForm',
    props: {
      filterForm: {
        default: () => ({}),
      },
    },
    data () {
      return {
        role: {}
      }
    },
    methods: {
      searchRole () {
        Bus.$emit('role-list-filter', this.filterForm)
      },
    },
  }
</script>
