import DashboardLayout from '../layouts/DashboardLayout'

export const behaviorHistoryMenu = {
    path: '/actions',
    component: DashboardLayout,
    name: 'behaviorHistory',
    redirect: '/actions',
    children: [
        {
            path: 'customer',
            name: 'ActionHistoryList',
            component: () => import(/* webpackChunkName: "about" */'../pages/action-history/ActionHistoryList.vue'),
            meta: {
                roles: ['behavior_customer_read', 'behavior_customer_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Hoạt động khách hàng',
                }]
            },
        },
        {
            path: 'user',
            // name: 'ActionHistoryList',
            component: () => import(/* webpackChunkName: "about" */'../pages/action-history-user/ActionHistoryList.vue'),
            meta: {
                roles: ['behavior_user_read', 'behavior_user_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Hoạt động nội bộ',
                }]
            },
        },
    ],
}
