import DashboardLayout from '../layouts/DashboardLayout';
// import StoreList from '../pages/store/StoreList';

export const storeMenu = {
  path: '/data-setting',
  component: DashboardLayout,
  name: 'Store',
  children: [
    {
      path: 'store',
      component: () => import (/* webpackChunkName: "about" */'../pages/store/StoreList.vue'),
      meta: {
        roles: ['admin-search_read'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Quản lý kho'
        }]
      },
    }
  ],
}