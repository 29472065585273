import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    ua: '',
    isInCall: false,
    isCallOut: 0
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_UA (state, payload) {
      state.ua = payload
    },
    SET_IN_CALL (state, payload) {
      state.isInCall = payload
    },
    SET_CALL_OUT (state, payload) {
      state.isCallOut = payload
    }
  },
  actions: {
    SET_UA (store, ua) {
      store.commit('SET_UA', ua)
    },
    SET_IN_CALL (store, isInCall) {
      store.commit('SET_IN_CALL', isInCall)
    },
    SET_CALL_OUT (store, isCallOut) {
      store.commit('SET_CALL_OUT', isCallOut)
    }
  },
})
