var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-hidden"},_vm._l((_vm.hideNotifications),function(notification,index){return _c('div',{key:index},[(notification.showModal)?_c('v-card',{class:'s-popup-notification pos-' + _vm.notificationPosition.indexOf(notification.id),style:({ top: 'calc(73vh - ' + _vm.notificationPosition.indexOf(notification.id)*150 + 'px)', width: '350px', backgroundColor: '#FFFACD' })},[_c('v-card-title',{staticClass:"font-weight-bold mb-0 d-flex justify-space-between",staticStyle:{"line-height":"1.2"}},[_c('div',[_c('v-row',[_c('v-icon',{staticStyle:{"font-size":"25px","color":"#a847bf","padding":"0px 5px"}},[_vm._v("mdi-bell")]),_c('div',[_c('span',[_vm._v("Bạn có lịch hẹn lúc "+_vm._s(_vm.getTime(notification.date))+", Hôm nay")]),_c('br'),_c('span',[_vm._v("KH: "+_vm._s(notification.customer.fullName))]),_c('br'),_c('span',[_vm._v("SĐT: "+_vm._s(notification.mobile))])])],1)],1),_c('v-btn',{staticClass:"text-right mb-4",attrs:{"color":"default","icon":"","small":""},on:{"click":function($event){return _vm.closeModal(index)}}},[_c('v-icon',{attrs:{"aria-label":"Close"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-3 notification-desc"},[_vm._v(" Ghi chú: "+_vm._s(notification.description)+" ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary","min-width":"22px !important","height":"22","dark":"","small":"","size":"16"},on:{"click":function($event){return _vm.showAppointmentInfo(index)}}},[_vm._v(" Chi tiết lịch hẹn ")]),(notification.customer.contact && notification.customer.contact.contactType == 'main')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 btn-notification mb-3",attrs:{"min-width":"22px !important","height":"22","dark":"","small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"icon-flipped",attrs:{"size":"16"}},[_vm._v("mdi-phone")])],1)]}}],null,true)},[_c('span',[_vm._v("Thực hiện lịch hẹn")])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"main-mobile",on:{"click":function($event){_vm.showAppoimentAction(index, _vm._.get(notification, 'mobile', ''))}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._.get(notification, 'mobile', '')))])],1),_vm._l((notification.customer.contact.phoneRelated),function(phone,phoneIndex){return _c('v-list-item',{key:phoneIndex,staticClass:"sub-mobile",on:{"click":function($event){return _vm.showAppoimentAction(index, phone)}}},[_c('v-list-item-title',[_vm._v(_vm._s(phone))])],1)})],2)],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 btn-notification mb-3",attrs:{"min-width":"22px !important","height":"22","dark":"","small":""},on:{"click":function($event){_vm.showAppoimentAction(index, _vm._.get(notification, 'mobile', ''))}}},{ on: on }),[_c('v-icon',{staticClass:"icon-flipped",attrs:{"size":"16"}},[_vm._v("mdi-phone")])],1)]}}],null,true)},[_c('span',[_vm._v("Thực hiện lịch hẹn")])])],1)],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }