import DashboardLayout from '../layouts/DashboardLayout'
// import Setting from '../pages/settings/SettingList'
// import SettingV1 from '../pages/settings/SettingListV1'

export const settingMenu = {
  path: '/setting',
  component: DashboardLayout,
  name: 'Setting',
  // redirect: '/setting',
  children: [
    {
      path: '/setting/contact',
      // name: 'Settings',
      component: () => import (/* webpackChunkName: "about" */'../pages/settings/SettingList.vue'),
      meta: {
        roles: ['setting-contact_read', 'setting-contact_update'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Phân bổ contact'
        }]
      },
    },
    {
      path: '/setting/contact-v1',
      // name: 'Settings',
      component: () => import (/* webpackChunkName: "about" */'../pages/settings/SettingListV1.vue'),
      meta: {
        roles: ['setting-contact_read', 'setting-contact_update'],
        requiresAuth: true,
        breadcrumb: [{
          title: 'Phân bổ contact riêng'
        }]
      }
    }
  ],
}