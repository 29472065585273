import Repository from './Repository'
import TokenService from '../helpers/token'

const baseUrl = `${Repository.defaults.baseURL}/report`

export default {
  async accountReport(params) {
    const response = await Repository.get(`${baseUrl}/account-report`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async salesReport(params) {
    const response = await Repository.get(`${baseUrl}/sales-report`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async productReport(params) {
    const response = await Repository.get(`${baseUrl}/product-report`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async customerFilterReport(params) {
    const response = await Repository.get(`${baseUrl}/customer-filter-sale-report`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async productFilterReport(params) {
    const response = await Repository.get(`${baseUrl}/product-filter-sale-report`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async userFilterReport(params) {
    const response = await Repository.get(`${baseUrl}/user-filter-sale-report`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async saleLeader(params) {
    const response = await Repository.get(`${baseUrl}/sale-leader/`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async list(params) {
    const response = await Repository.get(`${baseUrl}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async bestEmployees(params) {
    const response = await Repository.get(`${baseUrl}/best-employees`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async productGroupTurnover(params) {
    const response = await Repository.get(`${baseUrl}/product-group-turnover`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async productGroupDensity(params) {
    const response = await Repository.get(`${baseUrl}/product-group-density`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async productGroupSold(params) {
    const response = await Repository.get(`${baseUrl}/product-group-sold`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async fetchTurnover(params) {
    const response = await Repository.get(`${baseUrl}/turnover`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async fetchSource(params) {
    const response = await Repository.get(`${baseUrl}/by-source`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async personalReport(params) {
    const response = await Repository.get(`${baseUrl}/personal-summary-achievements`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async personalChart(params) {
    const response = await Repository.get(`${baseUrl}/personal-chart-achievements`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async personalCallChart(params) {
    const response = await Repository.get(`${baseUrl}/personal-achievements-by-call-center`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async fetchPayment(params) {
    const response = await Repository.get(`${baseUrl}/by-payment-method`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async departmentSummary(params) {
    const response = await Repository.get(`${baseUrl}/by-department-summary`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async departmentChart(params) {
    const response = await Repository.get(`${baseUrl}/by-department-chart`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async fetchCity(params) {
    const response = await Repository.get(`${baseUrl}/by-city`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async reportByContact(params) {
    const response = await Repository.get(`${baseUrl}/personal-achievements-by-contact-sources`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async contactSource(params) {
    const response = await Repository.get(`${baseUrl}/by-contact-sources`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async departmentEmployee(params) {
    let url = params.paramV ? `by-department-employee-${params.paramV}` : 'by-department-employee';
    delete params.paramV;
    const response = await Repository.get(`${baseUrl}/${url}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async reportByContactStatus(params) {
    const response = await Repository.get(`${baseUrl}/by-contact-status`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async personalByContactStatus(params) {
    const response = await Repository.get(`${baseUrl}/personal-achievements-by-contact-status`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async registerReport(params) {
    const response = await Repository.get(`${baseUrl}/by-contact-register`, {
      params,
      headers: TokenService.getHeader()
    })
    return response
  },

  async deliveryReportByPeriod(params) {
    const response = await Repository.get(`${baseUrl}/delivery-report-total`, {
      params,
      headers: TokenService.getHeader()
    })
    return response
  },
  async deliveryReportByDays(params) {
    const response = await Repository.get(`${baseUrl}/delivery-report-by-day`, {
      params,
      headers: TokenService.getHeader()
    })
    return response
  },
  async deliveryReportByPerson(params) {
    const response = await Repository.get(`${baseUrl}/delivery-report-by-employee`, {
      params,
      headers: TokenService.getHeader()
    })
    return response
  },
  async generalReport(params) {
    const response = await Repository.get(`${baseUrl}/general-report`, {
      params,
      headers: TokenService.getHeader()
    })
    return response
  },
  async exportExcelEmployeeReport(params = {}) {
    const response = await Repository.get(`${baseUrl}/export/excel/by-department-employee`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async departmentEmployeeFe(params) {
    const response = await Repository.get(`${baseUrl}/by-department-employee-fe-clickhouse`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async personalReportFe(params) {
    const response = await Repository.get(`${baseUrl}/personal-summary-achievements`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
  async exportExcelAccountReport(params = {}) {
    const response = await Repository.get(`${baseUrl}/export/excel/account-report`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  }
}
