import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/user`
const resourceProduct = `${Repository.defaults.baseURL}/product`

export default {
  async list(params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async detail(id) {
    const response = await Repository.get(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async create(user) {
    const response = await Repository.post(`${resource}`, user, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update(id, user) {
    const response = await Repository.patch(`${resource}/${id}`, user, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async delete(id) {
    const response = await Repository.delete(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async resetPassword(payload) {
    const response = await Repository.post(`${resource}/reset-password`, payload, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async updateStatus(id, params) {
    const response = await Repository.patch(`${resource}/${id}/status`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async listMarketing(params) {
    const response = await Repository.get(`${resource}/by-department-type`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async listManagementLinkProductCustomer(params) {
    const response = await Repository.get(`${resourceProduct}/link/user-customer`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async postLinkCustomerToUserProduct(body) {
    const response = await Repository.post(`${resourceProduct}/link/user-customer`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async deleteLinkCustomerToUserProducts(id) {
    const response = await Repository.delete(`${resourceProduct}/remove/link/user-customer/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },
}
