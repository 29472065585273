export const PAGE_LIMIT = 1000;
export const FIRST_PAGE = 1;
export const STATUS_ACTIVE = 1;
export const thresholdPercentDiscount = 0.5;

export const accountTypes = {
    CUSTOMER: 1,
    USER: 2,
}

// order
export const orderStatusOptions = [
    {
        value: 1,
        text: 'Đang chờ',
        color: '#ff0000',
    },
    {
        value: 2,
        text: 'Đang chuyển hàng',
        color: '#f39c12'
    },
    {
        value: 3,
        text: 'Đã giao hàng',
        color: '#ff00d4'
    },
    {
        value: 4,
        text: 'Đã thanh toán',
        color: '#00a65a'
    },
    {
        value: 5,
        text: 'Đã hủy',
        color: '#403e3e'
    },
    {
        value: 6,
        text: 'Đơn hàng thành công',
        color: '#00c0ef'
    },
];

export const orderStatus = {
    PENDING: 1,
    SHIPPING: 2,
    DELIVERING: 3,
    PAID: 4,
    CANCELLED: 5,
    SUCCESS: 6,
};

export const vatStatusOptions = [
    {
        value: 0,
        text: 'Không xuất hóa đơn',
    },
    {
        value: 1,
        text: 'Yêu cầu xuất hóa đơn',
    },
]

// user
export const userPositionOptions = [
    {
        value: 'admin',
        text: 'Quản trị viên',
    },
    {
        value: 'management',
        text: 'Quản lý kinh doanh',
    },
    {
        value: 'staff',
        text: 'Nhân viên',
    }
]

export const userPositions = {
    SALE_MANAGER: 'sale_manager',
    SALE_LEADER: 'sale_leader',
    TELESALES: 'telesales',
    ADMIN: 'admin',
    MANAGEMENT: 'management',
}

// gender
export const userGenderOptions = [
    {
        value: 'female',
        text: 'Nữ',
    },
    {
        value: 'male',
        text: 'Nam',
    },
    {
        value: 'other',
        text: 'Khác',
    }
]

// product
export const productStatusOptions = [
    {
        value: 1,
        text: 'Nháp',
    },
    {
        value: 2,
        text: 'Đang hoạt động',
    },
    {
        value: 3,
        text: 'Ngừng hoạt động',
    }
]

export const productStatus = {
    DRAFF: 1,
    ACTIVE: 2,
    INACTIVE: 3,
}

export const productFolderStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 0,
        text: 'Ngừng hoạt động',
    }
]

export const productFolderStatus = {
    ACTIVE: 1,
    INACTIVE: 0,
}

// customer
export const customerPositionOptions = [
    {
        value: 1,
        text: 'Khách hàng',
    },
    {
        value: 2,
        text: 'Công ty',
    },
    {
        value: 3,
        text: 'Nhà thuốc',
    },
    {
        value: 4,
        text: 'Phòng khám',
    }
]

// department type
export const departmentTypeOptions = [
    {
        label: 'Nhà thuốc',
        value: 'nha-thuoc'
    },
    {
        label: 'Bệnh viện',
        value: 'benh-vien'
    },
    {
        label: 'Phòng khám',
        value: 'phong-kham'
    },
    {
        label: 'Công ty',
        value: 'cong-ty'
    },
    {
        label: 'Khách hàng lẻ',
        value: 'khach-hang-le'
    },
    {
        label: 'Khác',
        value: 'khac'
    }
]

// Thiết lập giá chiết khấu
export const discountStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 2,
        text: 'Ngừng hoạt động',
    }
]

// Khách hàng liên hệ 
export const contactWorkStatusOptions = [
    {
        value: 1,
        text: 'Đã xử lý',
    },
    {
        value: 0,
        text: 'Chưa xử lý',
    }
]

// Site configuration
export const typeConfig = {
    SEARCH_PRICE: 1,
    SEARCH_PERCENT_DISCOUNT: 2,
}

export const configStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 0,
        text: 'Ngừng hoạt động',
    }
]

export const bannerStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 0,
        text: 'Ngừng hoạt động',
    }
]

export const bannerTypeOptions = [
    {
        value: 'trang-chu',
        text: 'Trang chủ'
    },
    {
        value: 'thuoc',
        text: 'Thuốc'
    },
    {
        value: 'thuc-pham-chuc-nang',
        text: 'Thực phẩm chức năng'
    },
    {
        value: 'my-pham',
        text: 'Mỹ phẩm'
    },
    {
        value: 'thiet-bi-y-te',
        text: 'Thiết bị y tế'
    },
    {
        value: 'san-pham-khac',
        text: 'Sản phẩm khác'
    }
]

export const partnerStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 0,
        text: 'Ngừng hoạt động',
    }
]

export const evaluationStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 0,
        text: 'Ngừng hoạt động',
    }
]

export const iconStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 0,
        text: 'Ngừng hoạt động',
    }
]

export const notificationStatusOptions = [
    {
        value: 1,
        text: 'Đang hoạt động',
    },
    {
        value: 0,
        text: 'Ngừng hoạt động',
    }
]

export const typeNotification = {
    USER_NOTIFICATION: 1,
    CUSTOMER_NOTIFICATION: 2,
}

export const userGenders = {
    FEMALE: 'female',
    MALE: 'male',
    OTHER: 'other',
}

export const regex = {
    isVNPhoneMobile: /^(0|\+84)((3[2-9])|(4[0-9])|(5[2689])|(7[06-9])|(8[1-9])|(9[0-46-9]))(\d)(\d{3})(\d{3})$/,
    isPassword: /^[a-z0-9!@#$%^&*(_)-=+?`\|]+$/i,
    isUsername: /^[a-zA-Z 0-9_-]*$/,
    isNumber: /^[0-9]*$/,
    isEmail: /^[a-z0-9@._-]+$/i,
    isMobile1: /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i,
    isMobile2: /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i,
    isSlug: /^[a-z0-9]+(?:-[a-z0-9]+)*$/
}

export const contactSourceStatusOptions = [
    {
        value: 'active',
        text: 'Đang hoạt động',
    },
    {
        value: 'inactive',
        text: 'Ngừng hoạt động',
    }
]

export const contactSourceStatus = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
}

export const contactDetail = {
    CONTACT: 'Người Liên Hệ',
    PARENT: 'Thông Tin Phụ Huynh',
    CHILD: 'Thông Tin Của Con',
    CUSTOMER: 'Định Vị Khách Hàng',
    MAX_CONTACT: 5,
}

export const shiftList = [
    {
        color: '#ffab91',
        value: 'morning',
        text: 'Sáng',
    },
    {
        color: '#ffab91',
        value: 'afternoon',
        text: 'Chiều',
    },
    {
        color: '#42a5f5',
        value: 'evening',
        text: 'Tối',
    },
    {
        color: '#fff',
        value: 'all_day',
        text: 'Cả Ngày',
    },
    {
        color: '#c0ca33',
        value: 'discontinuous',
        text: 'Ca gãy',
    }
]

export const scheduleStatus = {
    // 'planning', 'done', 'off', 'halfway_off'
    PLANNING: 'planning',
    DONE: 'done',
    OFF: 'off',
    HALFWAY_OFF: 'halfway_off',
}

export const shiftListFilterForm = [
    {
        color: '#ffab91',
        value: 'morning',
        label: 'Sáng',
    },
    {
        color: '#ffab91',
        value: 'afternoon',
        label: 'Chiều',
    },
    {
        color: '#42a5f5',
        value: 'evening',
        label: 'Tối',
    },
    {
        color: '#fff',
        value: 'all_day',
        label: 'Cả Ngày',
    },
    {
        color: '#c0ca33',
        value: 'discontinuous',
        label: 'Ca gãy',
    },
    {
        color: '#616161',
        value: 'halfway_off',
        label: 'Khác',
    },
    {
        color: '#bdbdbd',
        value: 'off',
        label: 'Nghỉ',
    },
]

export const contactStatusTOptions = [
    {
        value: 'T0',
        text: 'T0',
    },
    {
        value: 'T1',
        text: 'T1',
    },
    {
        value: 'T2',
        text: 'T2',
    },
    {
        value: 'T3',
        text: 'T3',
    }
]

export const contactStatusTOptionsView = [
    {
        value: 'T0',
        text: 'T0',
    },
    {
        value: 'T1',
        text: 'T1',
    },
    {
        value: 'T2',
        text: 'T2',
    },
    {
        value: 'T3',
        text: 'T3',
    },
    {
        value: 'T4',
        text: 'T4',
    },
    {
        value: 'T5',
        text: 'T5',
    }
]

export const contactStatusCOptionsView = [
    {
        value: 'C0',
        text: 'C0',
    },
    {
        value: 'C1',
        text: 'C1',
    },
    {
        value: 'C2',
        text: 'C2',
    },
    {
        value: 'C3',
        text: 'C3',
    },
    {
        value: 'C4',
        text: 'C4',
    },
    {
        value: 'C5',
        text: 'C5',
    }
]

export const contactStatusGOptionsView = [
    {
        value: 'G0',
        text: 'G0',
    },
    {
        value: 'G1',
        text: 'G1',
    },
    {
        value: 'G2',
        text: 'G2',
    },
    {
        value: 'G3',
        text: 'G3',
    },
    {
        value: 'G4',
        text: 'G4',
    },
    {
        value: 'G5',
        text: 'G5',
    }
]

export const contactStatusMOptionsView = [
    {
        value: 'M0',
        text: 'M0',
    },
    {
        value: 'M1',
        text: 'M1',
    },
    {
        value: 'M2',
        text: 'M2',
    },
    {
        value: 'M3',
        text: 'M3',
    }
]

export const contactStatusCOptions = [
    {
        value: 'C0',
        text: 'C0',
    },
    {
        value: 'C1',
        text: 'C1',
    },
    {
        value: 'C2',
        text: 'C2',
    },
    {
        value: 'C3',
        text: 'C3',
    }
]

export const contactStatusMOptions = [
    {
        value: 'M0',
        text: 'M0',
    },
    {
        value: 'M1',
        text: 'M1',
    }
]

export const contactStatusGOptions = [
    {
        value: 'G0',
        text: 'G0',
    },
    {
        value: 'G1',
        text: 'G1',
    },
    {
        value: 'G2',
        text: 'G2',
    },
    {
        value: 'G3',
        text: 'G3',
    }
]

export const contactStatusStore = [
    {
        value: 'T',
        text: 'Kho T'
    },
    {
        value: 'C',
        text: 'Kho C'
    },
    {
        value: 'G',
        text: 'Kho G'
    },
    {
        value: 'M',
        text: 'Kho M'
    }
]

export const appointmentStatus = {
    // 'planning', 'done', 'cancel'
    PLANNING: 'planning',
    DONE: 'done',
    CANCEL: 'cancel',
}

export const appointmentOptions = [
    {
        value: 'planning',
        text: 'Chờ',
    },
    {
        value: 'done',
        text: 'Đã hoàn thành',
    },
    {
        value: 'cancel',
        text: 'Hủy',
    },
    {
        value: 'missed',
        text: 'Lỡ'
    }
]

export const shiftTimes = {
    // 'morning', 'afternoon', 'evening', 'all_day', 'discontinuous'
    morning: {
        start: '08:15:00',
        end: '12:15:00',
        totalHours: 4,
    },
    afternoon: {
        start: '13:30:00',
        end: '17:30:00',
        totalHours: 4,
    },
    evening: {
        start: '17:30:00',
        end: '21:30:00',
        totalHours: 4,
    },
    all_day: {
        start: '08:15:00',
        end: '17:30:00',
        totalHours: 8,
    },
    discontinuous: {
        start: '13:30:00',
        end: '21:30:00',
        totalHours: 8,
    },
}

export const filterShiftCallCenter = [
    {
        value: '',
        label: 'Tất cả',
    },
    {
        value: 'morning',
        label: 'Sáng',
    },
    {
        value: 'afternoon',
        label: 'Chiều',
    },
    {
        value: 'evening',
        label: 'Tối',
    },
]

export const contactStatus = {
    T0_SAISO: 1,
    T0: 3, // T0 Đã lọc
    NEW: 4,
    C_NEW: 28,
    CO: 27, // C0 đã lọc
    C0_SAISO: 25,
    T2_HGGL: 9,
    C2_HGGL: 33,
    T2_HUY: 11,
    C2_HUY: 35,
    T3_COD: 12,
    T3_BANK: 13,
    C3_COD: 36,
    C3_BANK: 37,
    T5_COD: 23,
    T5_BANK: 24,
    C5_COD: 47,
    C5_BANK: 48,
    G0: 57, // G0 Đã lọc
    M0: 87 // M0 Đã lọc
}

export const idsOrderContactStatusFinish = [
    contactStatus.C5_COD,
    contactStatus.C5_BANK,
    contactStatus.T5_COD,
    contactStatus.T5_BANK,
    contactStatus.C2_HUY,
    contactStatus.T2_HUY,
];

export const contactDetailTabMenu = function (contactId) {
    return [
        {
            text: 'Thông tin khách hàng',
            to: `/contact/contact-list/contact-detail/${contactId}`
        },
        {
            text: 'Lịch sử đơn hàng',
            to: `/contact/contact-list/${contactId}/order-list`
        },
        {
            text: 'Lịch sử giao dịch',
            to: `/contact/contact-list/${contactId}/contact-history`
        }
    ];
}

export const contactCreator = {
    100000: 'Google',
    100001: 'Chat Bot',
    100002: 'Call Center',
    100003: 'Other',
    100004: 'System',

};

export const typeContactExpand = ['T4', 'C4'];

export const contactStatusDefaultColor = '#f3727244';

export const contactSettingTypes = [
    { value: 'text', text: 'Text' },
    { value: 'textarea', text: 'Textarea' },
    { value: 'dropdown', text: 'Dropdown' },
];

export const contactSettingObjects = [
    { value: 'contact', text: 'Định vị khách hàng' },
    { value: 'child', text: 'Thông tin con' },
    { value: 'parent', text: 'Thông tin phụ huynh' },
    { value: 'main_customer', text: 'Người liên hệ' },
];


export const contactSettingNameRegex = /^[a-zA-Z0-9_-]*$/;

export const colorChart = [
    'teal',
    'royalblue',
    'darkgrey',
    'red',
    'yellow',
    'hotpink',
    'aqua',
    'gold',
    'blueviolet',
    'maroon',
    '#eacf7d',
    'green'
];

export const storeOptions = [
    {
        text: 'Tất cả',
        value: 0,
    },
    {
        text: 'Kho T',
        value: 2,
    },
    {
        text: 'Kho CC',
        value: 1,
    },
    {
        text: 'Kho G',
        value: 3,
    }
];

export const orderPaymentTypes = ['BANK', 'COD'];

export const paymentTypes = {
    BANK: 'BANK',
    COD: 'COD',
};


export const MAX_SIZE_IMAGE = 2097152;

export const shiftListReport = [
    {
        color: '#F44336',
        value: [],
        text: 'Tất cả',
    },
    {
        color: '#F44336',
        value: ['morning'],
        text: 'Sáng',
    },
    {
        color: '#9C27B0',
        value: ['afternoon'],
        text: 'Chiều',
    },
    {
        color: '#4CAF50',
        value: ['evening'],
        text: 'Tối',
    },
    {
        color: '#2196F3',
        value: ['morning', 'afternoon'],
        text: 'Cả Ngày',
    },
    {
        color: '#FF9800',
        value: ['afternoon', 'evening'],
        text: 'Ca gãy',
    }
]

export const fbContactSources = ["Bot.hana.ai", "FAI", "FBCM", "FBCL", "COM"]

export const schoolLevels = {
    PRIMARY_SCHOOL: 'primary_school',
    JUNIOR_HIGH_SCHOOL: 'junior_high_school',
    KINDERGARTEN: 'kindergarten',
}

export const schoolLevelOptions = [
    {
        value: 'kindergarten',
        text: 'Chưa Đi Học',
    },
    {
        value: 'primary_school',
        text: 'Tiểu Học',
    },
    {
        value: 'junior_high_school',
        text: 'Trung Học Cơ Sở',
    }
]