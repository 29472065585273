import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/order`

export default {
  
  async create(params) {
    const response = await Repository.post(`${resource}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async list(params) {
    const response = await Repository.get(`${resource}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async fillProduct(id) {
    const response = await Repository.get(`${resource}/${id}/fill-product`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async detail(id) {
    const response = await Repository.get(`${resource}/${id}`, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async update(id, params) {
    const response = await Repository.patch(`${resource}/${id}`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async changeStatus(id, body) {
    const response = await Repository.patch(`${resource}/${id}/status`, body, {
      headers: TokenService.getHeader(),
    })
    return response
  },
  
  async exportExcelContact(params = {}) {
    const response = await Repository.get(`${resource}/export/excel`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },

  async getEndDate(products) {
    const response = await Repository.post(`${resource}/product-end-date`, products, {
      headers: TokenService.getHeader()
    })
    return response
  },
  
  async confirmPayment(params) {
    const response = await Repository.post(`${resource}/confirm-payment`, params, {
      headers: TokenService.getHeader()
    })
    return response
  },

  async getListOrderAccount(params) {
    const response = await Repository.get(`${resource}/${params.orderId}/account`, {
      headers: TokenService.getHeader()
    });
    return response;
  },

  async ctvCreate(params) {
    const response = await Repository.post(`${resource}/ctv-create`, params, {
      headers: TokenService.getHeader(),
    })
    return response
  },

  async exportExcelContactDoiSoat(params = {}) {
    const response = await Repository.get(`${resource}/export/excel-order-doisoat`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  }
}
