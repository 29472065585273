<template>
    <div
            v-if="showModal"
            class="s-popup-call"
            :style="{ top: '81vh' , width: '430px', right: '20px' }"
    >
        <v-card class="text-center popup-call">
        <v-card-title class="font-weight-bold mb-0 d-flex justify-space-between">
            <h3 style="font-size: 14px">Cuộc gọi  <span v-show="showTimer">{{ minutes }} : {{ seconds }}</span></h3>
            <v-btn
                    v-if="!showEndCall"
                    color="default"
                    @click="closeModal"
                    class="text-right"
                    icon
                    small
            >
                <v-icon aria-label="Close">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <template v-if="errorMessage">
            <v-alert
                    class="mx-4 pa-3 mb-0"
                    type="error"
            >
               {{ errorMessage }}
            </v-alert>
        </template>
        <v-card-text class="px-6 pt-0 pb-3">
            <div class="row align-center">
                <div class="col-md-9 text-left py-0 pr-0">
                    <div class="d-flex">
                        <label class="font-weight-bold">Số điện thoại :</label> <strong class="pl-1">{{ mobile }}</strong>
                    </div>
                    <div class="d-flex">
                        <label class="font-weight-bold" style="min-width: 75px">Trạng thái :</label>
                        <span>{{ callStatus }}</span>
                    </div>
                </div>
                <div class="col-md-3 py-0 pr-0">
                    <v-btn class="btn-voice-call ma-0" color="success" fab medium @click="executeCall" v-if="callAgain">
                        Gọi
                    </v-btn>
                    <v-btn class="btn-voice-call ma-0" color="primary" fab medium @click="endSession" v-if="callWait">
                        Kết thúc
                    </v-btn>
                    <v-btn class="btn-voice-call ma-0" color="primary" fab medium @click="endCall" v-if="showEndCall">
                        Kết thúc
                    </v-btn>
                    <v-btn class="btn-voice-call ma-0" color="success" fab medium @click="acceptCall" v-if="confirmAnswer">
                        Trả lời
                    </v-btn>
                </div>
            </div>
        </v-card-text>

        <v-dialog
            v-if="showConfirm"
            v-model="showConfirm"
            persistent
            max-width="525"
        >
            <common-confirm-call
                :showCreateAppointment="showCreateAppointment"
                :mobile="mobile"
                :dataAppointment="dataAppointment"
            />
        </v-dialog>
    </v-card>
    </div>
</template>

<script>
    // import JsSIP from '../../assets/js/jssip-3.3.11.min.js'
    import JsSIP from 'jssip'
    import TokenService from "../../helpers/token";
    import RepositoryFactory from '../../repositories/RepositoryFactory'
    const ContactRepository = RepositoryFactory.get('contact')
    const CallRepository = RepositoryFactory.get('call_center')
    import axios from 'axios'

    const MOBILE_REGEX = new RegExp(/^(0|\+84)((3[2-9])|(4[0-9])|(5[2689])|(7[06-9])|(8[1-9])|(9[0-46-9]))(\d)(\d{3})(\d{3})$/);

    export default {
        name: 'VoiceCall',
        data() {
            return {
                showModal: false,
                callError: false,
                session: false,
                configuration: {},
                callPhone: null,
                callStatus: null,
                showEndCall: false,
                callAgain: false,
                isSetting: false,
                callSuccessEnd: false,
                callWait: false,
                errorMessage: '',
                showConfirm: false,
                mobile: '',
                dataAppointment: {},
                appointmentId: null,
                showCreateAppointment: true,
                timer: '',
                minutes: '00',
                seconds: '00',
                showTimer: false,
                contactId: '',
                callStatusCareSoft: '',
                statusChange: '',
                callcenter_id: 0,
                session_call: false,
                confirmAnswer: false,
                isInCall: false,
                store: ''
            }
        },
        watch: {
            callStatusCareSoft: {
                deep: true,
                handler(newVal) {
                    console.log('callStatusCareSoft val ' + newVal)
                    if (newVal == 'Call ringing') {
                        this.callStatus = 'Bắt đầu gọi ' + this.mobile + ', đợi trả lời!'
                        this.callWait = true
                    } else if (newVal == 'Accept call') {
                        this.createTimer()
                        this.callStatus = 'Đang bắt đầu cuộc gọi'
                        this.showEndCall = true
                        this.callSuccessEnd = true
                        this.callWait = false
                    } else if (newVal == 'End call') {
                        let closed = true
                        if (this.callWait) {
                            closed = false
                        }
                        clearInterval(this.timer)
                        clearInterval(this.statusChange)
                        if (this.callSuccessEnd && this.appointmentId) {
                            this.callSuccessEnd = false
                            this.callWait = false
                            this.showConfirm = false
                        }
                        this.callStatus = "Kết thúc cuộc gọi"
                        this.callAgain = false
                        this.showEndCall = false
                        this.callWait = false
                        this.confirmAnswer = false
                        if (this.contactId) {
                            ContactRepository.additionToNumberOfCall(this.contactId, this.store)
                            ContactRepository.createContactHistory({
                                action: 'APPOINTMENT',
                                description: 'Thực hiện cuộc gọi thành công.',
                                contactId: this.contactId
                            }, this.contactId)
                        }
                        if (closed) {
                            setTimeout(() => {this.closeModal()}, 3000)
                        } else {
                            this.callAgain = true
                        }
                    } else if (newVal == 'error') {
                        this.errorMessage = "Cuộc gọi lỗi"
                        this.showEndCall = false
                        this.callSuccessEnd = false
                        this.callWait = false
                    } else if (newVal == 'failed') {
                        this.callAgain = true
                        this.callWait = false
                        this.showEndCall = false
                        this.callStatus = "Máy bận"
                        csVoice.isCallout = false
                        if (this.contactId) {
                            ContactRepository.additionToNumberOfCall(this.contactId, this.store)
                            ContactRepository.createContactHistory({
                                action: 'APPOINTMENT',
                                description: 'Thực hiện cuộc gọi lỡ.',
                                contactId: this.contactId
                            }, this.contactId)
                        }
                    }
                }
            }
        },
        async created() {
            let user = TokenService.getUser()
            if (_.get(TokenService.getUser(), 'ext', '') || _.get(TokenService.getUser(), 'extPassword', '')) {
                if (user.callcenter_id == 1) {
                    // this.connectePacific()
                } else if (user.callcenter_id == 2) {
                    let token = csGetCookie(_.get(TokenService.getUser(), 'ext', ''))
                    let csInCall = localStorage.getItem('csInCall')
                    if(csInCall == 1) {
                        // this.$swal({
                        //     title: 'Tính năng gọi của trang này đã bị ngắt.',
                        //     showConfirmButton: false,
                        //     width: '500'
                        // })
                        return
                    }
                    if(token){
                        csInit(token, process.env.VUE_APP_CARESOFT_DOMAIN)
                        setTimeout(async () => {
                            if(isExpiredCSToken){
                                // let res = await CallRepository.loginCareSoft({ipphone: '28029002'})
                                let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
                                if (res.message == 'Success') {
                                    token = res.data.token
                                    csSetCookie(token, _.get(TokenService.getUser(), 'ext', ''))
                                    csInit(token, process.env.VUE_APP_CARESOFT_DOMAIN)
                                } else {
                                    return
                                }        
                            }
                        }, 2000);    
                    }
                    else{
                        let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
                        if (res.message == 'Success') {
                            token = res.data.token
                            csSetCookie(token, _.get(TokenService.getUser(), 'ext', ''))
                            csInit(token, process.env.VUE_APP_CARESOFT_DOMAIN)
                        } else {
                            return
                        }
                    }
                    // if (token == '' || isExpiredCSToken) {
                    //     // let res = await CallRepository.loginCareSoft({ipphone: '28029002'})
                    //     let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
                    //     if (res.message == 'Success') {
                    //         token = res.data.token
                    //         csSetCookie(token, _.get(TokenService.getUser(), 'ext', ''))
                    //     } else {
                    //         return
                    //     }
                    // } 
                    // csInit(token, process.env.VUE_APP_CARESOFT_DOMAIN)
                    setTimeout(() => {
                        if (csVoice.callStatus != 'Online') {
                            changeCallStatus()
                        }
                    }, 3000)
                }
            } else {
                this.errorMessage = 'Cuộc gọi không thành công do thiếu số máy lẻ và mật khẩu máy lẻ !'
                return
            }
            Bus.$on('voice-call-show-popup', async (data) => {
                // trong trường hợp popup đang mở sẽ không làm gì, nếu không thì show popup và thực hiện cuộc gọi
                console.log(data)
                if (data.mainId) {
                    let self = this
                    this.$swal({
                        title: 'Số điện thoại đã được gộp vào contact ' + data.mainMobile + '.<br>Vui lòng thực hiện các thao tác tại contact chính.',
                        confirmButtonText: 'Xem contact chính',
                        showCancelButton: true,
                        cancelButtonText: 'Quay lại'
                    }).then((res) => {
                        if (res.value) {
                            let routeData = self.$router.resolve({
                                name: 'ContactDetail',
                                params: {
                                    id: data.mainId || 0
                                }
                            })
                            window.open(routeData.href, '_blank')
                        }
                    })
                } else if (!MOBILE_REGEX.test(data.mobile)) {
                  this.$swal({
                    title: 'Số điện thoại không đúng định dạng.',
                    width: 500
                  });
                } else {
                    if (!this.showModal) {
                        window.callStatusCareSoft = ''
                        this.setInterval
                        this.mobile = data.mobile
                        // this.mobile = '0367019868'
                        this.dataAppointment = data.dataAppointment
                        this.appointmentId = data.appointmentId
                        this.showCreateAppointment = data.showCreateAppointment
                        this.contactId = data.contactId
                        this.callcenter_id = data.callcenter_id ? data.callcenter_id : 1
                        this.store = data.store ? data.store : ''
                        this.executeCall({contactId: this.contactId});
                    }
                    this.initData()
                }
            })
            Bus.$on('close-popup-confirm', () => {
                this.closeConfirm()
            })
            Bus.$on('cs-voice-disabled', () => {
                if (!csVoice.enableVoice) {
                    this.$swal({
                        title: 'Cuộc gọi chỉ thực hiện tại 1 tab.<br>Nếu bấm kích hoạt cuộc gọi tab cũ sẽ bị ngắt.',
                        confirmButtonText: 'Kích hoạt',
                        showCancelButton: true,
                        cancelButtonText: 'Hủy',
                        width: '580px'
                    }).then(res => {
                        if (res.value) {
                            if (!csVoice.enableVoice) {
                                csEnableCall()
                            }
                        }
                    })
                }
            })
            window.addEventListener('scroll', this.scrollModalCall);
            window.addEventListener('beforeunload', this.endCall)
        },
        beforeDestroy () {
            this.endCall()
            Bus.$off('close-popup-confirm')
            window.removeEventListener('scroll', this.scrollModalCall);
        },

        methods: {
            scrollModalCall(event) {
                let defaulPopup = document.querySelector('.s-popup-call')
                if (defaulPopup) {
                    let distance = window.scrollY + 'px';
                    defaulPopup.style.top = `calc(81vh + ${distance})`
                }
            },
            initData() {
                this.callError = false,
                this.callAgain = false
            },
            closeConfirm() {
                this.showConfirm = false
            },
            closeModal () {
                // this.showPopupCall = false
                try {
                    this.showTimer = false
                    this.minutes = '00'
                    this.seconds = '00'
                    this.$store.dispatch('SET_CALL_OUT', 0)
                    this.endSession()
                }
                catch(e) {
                    console.log(e)
                }
                finally {
                    this.showModal = false;
                    this.mobile = '';
                    this.appointmentId = 0;
                    // if (this.callcenter_id == 1) {
                    //     this.incomingCall()
                    // }
                }
            },
            endSession() {
                this.isInCall = false
                if (this.session_call) {
                    this.session_call.terminate()
                    this.session_call = false
                }
                let user = TokenService.getUser()
                if (user.ext == '') {
                    return
                }
                if (!user.callcenter_id || user.callcenter_id == 1) {
                    if (this.session) {
                        this.session.terminate()
                        this.session = false
                    }
                } else {
                    endCall()
                }
            },
            endCall() {
                this.callAgain = true
                this.showEndCall = false
                this.endSession()
            },
            executeCallePacific() {
                const self = this
                this.callAgain = false
                this.showEndCall = false
                this.callSuccessEnd = false
                this.callWait = false
                console.log('start call, ', this.mobile)
                this.callStatus = 'Bắt đầu gọi ' + this.mobile + ', đợi trả lời!'
                const remoteAudio = new window.Audio()
                remoteAudio.autoplay = true;
                remoteAudio.volume = 1
                const eventHandlers = {
                    'registrationFailed': function (e) {
                        console.log('registration failed with cause: ', e)
                        self.callStatus = "Thiết lập cuộc gọi không thành công"
                        self.callAgain = true
                        self.showEndCall = false
                        self.callWait = false
                        self.callError = true
                    },
                    'progress': function (e) {
                        console.log('call is in progress', e)
                        self.callStatus = 'Bắt đầu gọi ' + self.mobile + ', đợi trả lời!'
                        self.callWait = true
                        self.$store.dispatch('SET_IN_CALL', true)
                    },
                    'failed': function (e) {
                        console.log('call failed with cause: ', e)
                        self.callStatus = "Cuộc gọi lỗi do " + _.get(e, 'cause')
                        if(_.get(e, 'message.status_code', 0) == 403) { self.callStatus = 'Lỗi xác thực (số máy lẻ và mật khẩu không đúng)'}
                        self.callAgain = true
                        self.showEndCall = false
                        self.callWait = false
                        self.callError = true
                        self.isInCall = false
                        self.$store.dispatch('SET_IN_CALL', false)
                        if (e.cause == 'Busy') {
                            ContactRepository.additionToNumberOfCall(self.contactId, self.store)
                            ContactRepository.createContactHistory({
                                action: 'APPOINTMENT',
                                description: 'Thực hiện cuộc gọi lỡ.',
                                contactId: self.contactId
                            }, self.contactId)
                        } else if (e.cause == 'Canceled') {
                            ContactRepository.additionToNumberOfCall(self.contactId, self.store)
                            ContactRepository.createContactHistory({
                                action: 'APPOINTMENT',
                                description: 'Thực hiện cuộc gọi lỡ.',
                                contactId: self.contactId
                            }, self.contactId)
                        }
                    },
                    'ended':(e) => {
                        clearInterval(self.timer)
                        console.log('Kết thúc cuộc gọi');
                        let closed = true
                        if (self.callWait) {
                            closed = false
                        }
                        self.callStatus = "Kết thúc cuộc gọi (" + _.get(e, 'cause') + ')'
                        self.callAgain = false
                        self.showEndCall = false
                        self.callWait = false
                        self.confirmAnswer = false
                        self.$store.dispatch('SET_IN_CALL', false)
                        if (closed) {
                            ContactRepository.additionToNumberOfCall(self.contactId, self.store)
                            ContactRepository.createContactHistory({
                                action: 'APPOINTMENT',
                                description: 'Thực hiện cuộc gọi thành công.',
                                contactId: self.contactId
                            }, self.contactId)
                            setTimeout(() => {self.closeModal()}, 3000)
                        } else {
                            self.callAgain = true
                        }
                    },
                    'confirmed': function (e) {
                        self.createTimer()
                        console.log('Bắt đầu cuộc gọi...');
                        self.callStatus = 'Đang bắt đầu cuộc gọi'
                        self.showEndCall = true
                        self.callSuccessEnd = true
                        self.callWait = false
                        self.$store.dispatch('SET_IN_CALL', true)
                    },
                    // 'addstream': function (e) {
                    //     remoteAudio.srcObject = event.stream;
                    // },
                };

                const options = {
                    'eventHandlers': eventHandlers,
                    'mediaConstraints': {'audio': true, 'video': false},
                    'pcConfig':{
                        'rtcpMuxPolicy': 'negotiate',
                        'iceServers':
                        [
                    //      { urls : [ 'stun:stun.l.google.com:19302' ] }
                        ]
                    },

                };
                this.session = this.$store.state.ua.call(this.mobile, options)
                // this.session = this.ua.call(this.mobile, options);
                this.session.connection.addEventListener('addstream', function (event) {
                    remoteAudio.srcObject = event.stream;
                });
            },
            acceptCall() {
                let sound = document.getElementById('incomingCallAudio')
                if (sound) {
                    sound.pause()
                    sound.remove()
                }
                if (!this.callcenter_id || this.callcenter_id == 1) {
                    this.confirmAnswer = false
                    this.session_call.answer()
                } else {
                    this.confirmAnswer = false
                    onAcceptCall()
                }
            },
            createTimer() {
                let min = 0
                let sec = 0
                let self = this
                self.showTimer = true
                self.timer = setInterval(function() {
                    sec++
                    if (sec < 10) {
                        self.seconds = '0' + sec
                    } else if (sec == 60) {
                        sec = 0
                        self.seconds = '00'
                        self.minutes = ++min
                    } else {
                        self.seconds = sec
                    }
                }, 1000)
            },
            async executeCallCareSoft() { 
                let self = this
                // let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
                window.callStatusCareSoft = ''
                this.callStatusCareSoft = ''
                this.statusChange =  setInterval(function() {
                    self.callStatusCareSoft = window.callStatusCareSoft
                }, 1000)
                this.callAgain = false
                this.showEndCall = false
                this.callSuccessEnd = false
                this.callWait = true
                this.confirmAnswer = false
                this.callStatus = 'Bắt đầu gọi ' + this.mobile + ', đợi trả lời!'
                this.errorMessage = ''
                if (isExpiredCSToken) {
                    let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
                    if (res.message == 'Success') {
                        let token = res.data.token
                        csSetCookie(token, _.get(TokenService.getUser(), 'ext', ''))
                        csInit(token, process.env.VUE_APP_CARESOFT_DOMAIN)
                    } else {
                        return
                    }
                    
                    setTimeout(() => {
                        if (csVoice.callStatus != 'Online') {
                            changeCallStatus()
                        }
                    }, 3000)
                    if (!csVoice.enableVoice) {
                        setTimeout(() => {
                            csEnableCall()
                        }, 3000)
                        setTimeout(() => {
                            if (csVoice.isCallout) {
                                csVoice.isCallout = false
                            }
                            csCallout(this.mobile)
                        }, 6000)
                    } else {
                        setTimeout(() => {
                            if (csVoice.isCallout) {
                                csVoice.isCallout = false
                            }
                            csCallout(this.mobile)
                        }, 3000)
                    }
                }
                if (!csVoice.enableVoice) {
                    csEnableCall()
                    setTimeout(() => {
                        if (csVoice.isCallout) {
                            csVoice.isCallout = false
                        }
                        csCallout(this.mobile)
                    }, 3000)
                } else {
                    if (csVoice.isCallout) {
                        csVoice.isCallout = false
                    }
                    csCallout(this.mobile)
                }
                    // let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcHBob25lIjoiMjgwMjkwMDIiLCJpYXQiOjE1OTExNzc3NDd9.1qQQg7abLUEc-vZGjsLPd-fng-nYTPTRgrEQrASwmIo'
                    // // csInit(token, process.env.VUE_APP_CARESOFT_DOMAIN)
                    // setTimeout(() => {
                    //     csEnableCall()
                    // }, 3000)
                    // setTimeout(() => {
                    //     changeDevice(1)
                    // }, 6000)
            },
            async incomingCallCareSoft() {
                let self = this
                // let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
                window.callStatusCareSoft = ''
                this.callStatusCareSoft = ''
                this.statusChange =  setInterval(function() {
                    self.callStatusCareSoft = window.callStatusCareSoft
                }, 1000)
                if (isExpiredCSToken) {
                    let res = await CallRepository.loginCareSoft({ipphone: '' + _.get(TokenService.getUser(), 'ext', '')})
                    if (res.message == 'Success') {
                        token = res.data.token
                        csSetCookie(token, _.get(TokenService.getUser(), 'ext', ''))
                    } else {
                        return
                    }
                    await csInit(token, process.env.VUE_APP_CARESOFT_DOMAIN)
                    if (!csVoice.enableVoice) {
                        setTimeout(() => {
                            csEnableCall()
                        }, 3000)
                    }
                }

                if (!csVoice.enableVoice) {
                    csEnableCall()
                }
                this.callAgain = false
                this.showEndCall = false
                this.callSuccessEnd = false
                this.callWait = false
                this.confirmAnswer = true
                this.callStatus = 'Có cuộc gọi tới từ ' + this.mobile
                this.showModal = true
            },
            executeCall(params) {
                if (this.$store.state.isInCall) {
                    return
                }
                let user = TokenService.getUser()
                if (user.ext == '') {
                    return
                }
                let sound = document.getElementById('incomingCallAudio')
                if (sound) {
                    sound.pause()
                    sound.remove()
                }
                if (user.callcenter_id == 1) {
                    this.showModal = true
                    this.$store.dispatch('SET_CALL_OUT', 1)
                    this.executeCallePacific()
                } else if (user.callcenter_id == 2) {
                    this.showModal = true
                    this.$store.dispatch('SET_CALL_OUT', 1)
                    this.executeCallCareSoft()
                } else if (user.callcenter_id == 3) {
                    let url = "https://cloudapi.mptelecom.com.vn/DialByTrunkOMS?trunkName=TrunkVHOC&agentExtension="+user.ext+"&phoneNum="+this.mobile+"&queue=Outbound_VH020&agentCode="+user.ext+"&omsId=123456789"
                    axios.get(url)
                    .then((res) => console.log(res))
                    .catch((err) => console.log(err))
                }
            },
            connectePacific() {
                this.socket = new JsSIP.WebSocketInterface(process.env.VUE_APP_CALL_VOICE_URL)
                this.socket.via_transport = "tcp";
                var remoteAudio = new window.Audio();
                remoteAudio.autoplay = true;
                this.configuration = {
                    sockets: [this.socket],
                    uri: `${_.get(TokenService.getUser(), 'ext', '')}@vuihochn155.ccall.vn`,
                    password: _.get(TokenService.getUser(), 'extPassword', ''),
                    session_timers: false,
                    // session_timers_refresh_method: 'invite',
                    register_expires: 600,
                    log: {
                        builtinEnabled: false,
                        level: 3,
                        connector: function (level, category, label, content) {
                            console.log('Log: ', content);
                        }
                    },
                    socket: {
                        uri           : process.env.VUE_APP_CALL_VOICE_URL,
                        via_transport : 'auto',
                    },
                    use_preloaded_route : false,
                    pcConfig: {
                        rtcpMuxPolicy : 'negotiate',
                        iceServers    :
                        [
                            { urls : [ ] }
                        ]
                    }
                };
                this.ua = new JsSIP.UA(this.configuration)
                let self = this
                this.ua.on('connected', function(e){ 
                    console.log('connected', e);
                });
                this.ua.on('disconnected', function(e){
                    console.log('disconnected', e);
                });
                this.ua.on('registrationFailed', function(ev){
                    alert('Registering on SIP server failed with error: ' + ev.cause);
                    configuration.uri = null;
                    configuration.password = null;
                    // updateUI();
                });
                // this.ua.on('newRTCSession',function(ev){
                //     var newSession = ev.session;
                //     console.log(newSession.direction)
                //     console.log(self.isInCall)
                //     if (self.isInCall) {
                //         newSession.terminate()
                //         return
                //     } else {
                //         self.isInCall = true
                //     }
                //     self.session_call = newSession;
        
                //     // var completeSession = function(){
                //     //     console.log('completeSession');
                //     //     session = null;
                //     //     incomingCallAudio.pause();
                //     //     // updateUI();
                //     // };
                //     if(self.session_call.direction === 'incoming') {
                //         console.log('stream incoming  -------->');               
                //         self.session_call.on('connecting', function() {
                //             console.log('CONNECT'); 
                //         });
                //         self.session_call.on('peerconnection', function(e) {
                //             console.log('1accepted');
                //             // add_stream();        
                //             self.session_call.connection.addEventListener('addstream',function(e) {
                //                 remoteAudio.srcObject = (e.stream);
                //             })    
                //         });
                //         self.session_call.on('ended', function(e) {
                //             clearInterval(self.timer)
                //             console.log('Kết thúc cuộc gọi');
                //             let sound = document.getElementById('incomingCallAudio')
                //             if (sound) {
                //                 sound.pause()
                //                 sound.remove()
                //             }
                //             self.callStatus = "Kết thúc cuộc gọi (" + _.get(e, 'cause') + ')'
                //             self.callAgain = false
                //             self.showEndCall = false
                //             self.callWait = false
                //             console.log(self.session_call)
                //             setTimeout(() => {
                //                 self.closeModal()
                //                 // self.showTimer = false
                //                 // self.minutes = '00'
                //                 // self.seconds = '00'
                //                 // self.showModal = false
                //                 // self.incomingCall()
                //             }, 3000)
                //         });
                //         self.session_call.on('failed', function (e) {
                //             let sound = document.getElementById('incomingCallAudio')
                //             if (sound) {
                //                 sound.pause()
                //                 sound.remove()
                //             }
                //             console.log('call failed with cause: ', e)
                //             self.callStatus = "Cuộc gọi lỗi do " + _.get(e, 'cause')
                //             if(_.get(e, 'message.status_code', 0) == 403) { self.callStatus = 'Lỗi xác thực (số máy lẻ và mật khẩu không đúng)'}
                //             self.callAgain = true
                //             self.showEndCall = false
                //             self.callWait = false
                //             self.callError = false
                //             self.confirmAnswer = false
                //             setTimeout(() => {
                //                 self.closeModal()
                //             }, 3000)
                //         });
                //         self.session_call.on('accepted',function(e) {
                //             console.log('accepted')
                //         });
                //         self.session_call.on('confirmed',function(e){
                //             self.createTimer()
                //             console.log('Bắt đầu cuộc gọi...');
                //             self.callStatus = 'Đang bắt đầu cuộc gọi'
                //             self.showEndCall = true
                //             self.callSuccessEnd = true
                //             self.callWait = false
                //         });
                //         // session.answer(options);
                //         // incomingCallAudio.pause();
                //         // remoteAudio.src = window.URL.createObjectURL(e.stream);
                //         var sound      = document.createElement('audio');
                //         sound.id       = 'incomingCallAudio';
                //         sound.controls = 'controls';
                //         sound.loop     = 'loop' 
                //         sound.autoplay = 'autoplay'
                //         sound.src      = 'https://freesound.org/data/previews/62/62872_634166-lq.mp3';
                //         sound.type     = 'audio/mpeg';
                //         sound.style    = 'display: none'
                //         document.getElementById('app-bar').appendChild(sound)
                //         self.showEndCall = false
                //         self.callAgain = false
                //         self.callWait = false
                //         self.mobile = self.session_call._remote_identity._uri.user
                //         self.callStatus = 'Đang gọi đến...'
                //         self.showModal = true
                //         self.confirmAnswer = true
                    // }
                    // updateUI();
                // });
                this.ua.start()
            }
        }
    }
</script>
<style lang="scss">
  .btn-callcenter {
    float: right;
    margin: 0 !important;
    position: relative;
    top: -6px;
  }
  .float-right {
    float: right;
  }
  .v-card__actions {
    display: block;
    padding-bottom: 20px;
    overflow: hidden;
  }
  .v-card {
    overflow: hidden;
  }
  .popup-call {
    border: 1px solid #A9A9A9 !important;
  }
</style>
