import Repository from './Repository'
import TokenService from '../helpers/token'

const resource = `${Repository.defaults.baseURL}/announcement`

export default {
    async list (params) {
        const response = await Repository.get(`${resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
        return response
    },
    async create (announcement) {
        const response = await Repository.post(`${resource}`, announcement, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async update (id, announcement) {
        const response = await Repository.patch(`${resource}/${id}`, announcement, {
            headers: TokenService.getHeader(),
        })
        return response
    },
    async delete (id) {
        const response = await Repository.delete(`${resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
        return response
    },
}