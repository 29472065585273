import DashboardLayout from '../layouts/DashboardLayout'
// import Profile from '../pages/profile/UserProfile'
// import Report from '../pages/profile/PersonalReport'

export const profileMenu = {
  path: '/profile',
  component: DashboardLayout,
  name: 'Profile',
  redirect: '/profile',
  children: [
    {
      path: '/profile',
      name: 'MyProfile',
      component: () => import (/* webpackChunkName: "about" */'../pages/profile/UserProfile.vue'),
      meta: {
        requiresAuth: true,
        roles: 'all',
        breadcrumb: [{
          title: 'Thông tin cá nhân'
        }]
      },
    }, {
      path: '/profile/report',
      name: 'Report',
      component: () => import (/* webpackChunkName: "about" */'../pages/profile/PersonalReport.vue'),
      meta: {
        requiresAuth: true,
        roles: 'all',
        breadcrumb: [{
          title: 'Báo cáo thành tích cá nhân'
        }]
      },
    },
    // {
    //   path: '/mobile/profile',
    //   name: 'MyProfile',
    //   component: () => import (/* webpackChunkName: "about" */'../pages/profile/UserProfile.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     roles: 'all',
    //     breadcrumb: [{
    //       title: 'Thông tin cá nhân'
    //     }]
    //   },
    // }
  ],
}