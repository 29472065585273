import DashboardLayout from '../layouts/DashboardLayout'

export const informationMenu = {
    path: '/information',
    component: DashboardLayout,
    name: 'Information',
    redirect: '/information',
    children: [
        {
            path: 'shipping-method',
            name: 'ShippingMethod',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/ShippingMethod.vue'),
            meta: {
                roles: ['shipping_method_read', 'shipping_method_create', 'shipping_method_update', 'shipping_method_delete', 'shipping_method_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Chính sách giao hàng',
                }]
            },
        },
        {
            path: 'payment',
            name: 'Payment',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/Payment.vue'),
            meta: {
                roles: ['payment_read', 'payment_create', 'payment_update', 'payment_delete', 'payment_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Phương thức thanh toán',
                }]
            },
        },
        {
            path: 'regular',
            name: 'Regular',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/Regular.vue'),
            meta: {
                roles: ['regular_read', 'regular_create', 'regular_update', 'regular_delete', 'regular_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Chính sách đổi trả',
                }]
            },
        },
        {
            path: 'privacy-policy',
            name: 'PrivacyPolicy',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/PrivacyPolicy.vue'),
            meta: {
                roles: ['privacy_policy_read', 'privacy_policy_create', 'privacy_policy_update', 'privacy_policy_delete', 'privacy_policy_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Chính sách bảo mật',
                }]
            },
        },
        {
            path: 'about-us',
            name: 'AboutUs',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/AboutUs.vue'),
            meta: {
                roles: ['about_us_read', 'about_us_create', 'about_us_update', 'about_us_delete', 'about_us_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Giới thiệu công ty',
                }]
            },
        },
        {
            path: 'question-answer',
            name: 'QuestionAnswerList',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/question/QuestionAnswerList.vue'),
            meta: {
                roles: ['question_answer_read', 'question_answer_create', 'question_answer_update', 'question_answer_delete', 'question_answer_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Câu hỏi thường gặp',
                }]
            },
        },
        {
            path: 'create-question-answer',
            name: 'CreateQuestionAnswer',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/question/components/QuestionAnswerForm.vue'),
            meta: {
                roles: ['question_answer_read', 'question_answer_create', 'question_answer_update', 'question_answer_delete', 'question_answer_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Thêm câu hỏi thường gặp',
                }]
            },
        },
        {
            path: 'update-question-answer/:id',
            name: 'QuestionAnswerDetail',
            component: () => import(/* webpackChunkName: "about" */'../pages/information/question/components/QuestionAnswerDetail.vue'),
            meta: {
                roles: ['question_answer_read', 'question_answer_create', 'question_answer_update', 'question_answer_delete', 'question_answer_view_all'],
                requiresAuth: true,
                breadcrumb: [{
                    title: 'Câu hỏi thường gặp',
                    to: '/information/question-answer'
                }, {
                    title: 'Cập nhật câu hỏi thường gặp',
                }]
            },
        },
    ],
}
