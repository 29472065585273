import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'sweetalert2/dist/sweetalert2.css'
import VueLodash from './helpers/VueLodash'
import store from './store'
import './mixins/index'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import 'vue2-datepicker/index.css'
import VueSweetalert2 from 'vue-sweetalert2'
import DatePicker from 'vue2-datepicker'
// Plugins
import './plugins/base'
import './plugins/common'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'

// router setup
import router from './router'
import App from './App.vue'

// plugin setup
Vue.use(VueLodash)
Vue.use(Loading)
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
	locale: 'en',
	decimalLength: 0,
	autoDecimalMode: true,
	min: null,
	max: null,
  defaultValue: 0,
})
window.Bus = new Vue({ name: 'Bus' })

Vue.config.productionTip = false
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#4caf50',
  cancelButtonColor: '#e91e63',
})
Vue.use(DatePicker)

// scrollto
const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
