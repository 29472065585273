import TokenService from '@/helpers/token'
import Repository from './Repository'

const baseUrl = `${Repository.defaults.baseURL}/order/extract/log`

export default {
  async list(params) {
    const response = await Repository.get(`${baseUrl}`, {
      params,
      headers: TokenService.getHeader(),
    })
    return response
  },
}
